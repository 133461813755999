//     
import { createAction } from 'redux-actions';


export const setNotificationPreferences = createAction 
                                 
                            
                          
 ('SET_PREFERENCES_NOTIFICATION', (notification_preferences                         ) => notification_preferences);

export default (
  state                   = { notification: null },
  action                                               ,
) => {
  if (action.type === setNotificationPreferences.toString()) {
    return { ...state, notification: action.payload };
  }
  return state;
};
