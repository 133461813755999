//     
import React from 'react';
import Box from '@mui/material/Box';
import { Text, ExtLink } from '@dt/material-components';
import Skeleton from '@mui/material/Skeleton';

              
                      
            
                  
       
    
  

export const AssetsAccessibleBanner = ({ asset, isLoading }       ) => {
  if (isLoading) {
    return <Skeleton variant="rectangular" height={20} width={300} />;
  }

  if (!asset?.url) {
    // Should not happen.
    // Asset should be available when not loading.
    return null;
  }

  return (
    <Box>
      <Text variant="body" component="div" style={{ display: 'inline' }}>
        Accessible at <ExtLink to={asset.url}>{asset.url}</ExtLink>
      </Text>
    </Box>
  );
};
