//      
import { createAction } from 'redux-actions';

export const sharedLinksReceived = createAction                                                             (
  'shared_links/received',
  shared_links => shared_links,
);

export const sharedLinksMounted = createAction                                                          (
  'shared_links/mounted',
  () => ({ key: 'shared_links' }),
);

export const sharedLinksDeleteSuccess = createAction                                                 (
  'shared_links/delete/success',
  id => id,
);
