//     
import React, { memo } from 'react';
import LanguageIcon from '@mui/icons-material/Language';

import Tag from './Tag';

              
               
  

function TagNetworkServiceUrl({ url }       ) {
  return <Tag label={url} icon={<LanguageIcon style={{ width: 14, height: 14 }} />} rounded />;
}
export default memo       (TagNetworkServiceUrl);
