import React from 'react';
import { Stack, styled } from '@mui/material';
import Text from '@dt/ahura/src/components/Text';
var LabelText = styled(Text)({
    margin: 0,
});
var TopStack = styled(Stack)(function (_a) {
    var theme = _a.theme;
    return ({
        maxWidth: '270px',
        padding: theme.spacing(1),
    });
});
export var MenuItemWithIcon = function (_a) {
    var icon = _a.icon, label = _a.label, description = _a.description;
    return (React.createElement(TopStack, { direction: "row", spacing: 2 },
        React.createElement("img", { src: icon, alt: "".concat(label, " Icon") }),
        React.createElement(Stack, null,
            React.createElement(LabelText, { component: "p" }, label),
            React.createElement(Text, { component: "span", variant: "label" }, description))));
};
