//     
import createSagaMiddleware, {                                } from 'redux-saga';
import { Raven } from '@dt/global';

import containerSaga from './containers/sagas';

export function getMiddleware()                       {
  return createSagaMiddleware({
    onError: (e, { sagaStack }) => {
      console.error(e, sagaStack);

      // Only log to raven live environments.
      if (process.env.NODE_ENV !== 'test') {
        Raven.captureException(e, { extra: { sagaStack } });
      }
    },
  });
}

export function runSagas(withMiddleware                      )             {
  return withMiddleware.run(containerSaga);
}
