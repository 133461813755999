//      
import { createAction } from 'redux-actions';
                                                                                     

export const setStatusesForKeyResourceFetch = createAction 
                                           
                         
              
 ('resource_fetching/set_statuses_for_key', (key, status              ) => ({
  key,
  ...status,
}));

export const setCacheStatus = createAction                                                                         (
  'resource_fetching/cache_manager',
  (key, { start, done, error }) => ({
    key,
    start,
    done,
    error,
  }),
);
