//     

const scanType = {
  STATIC: 'STATIC',
  DYNAMIC: 'DYNAMIC',
  APP_LOGIC: 'APP_LOGIC',
  P1_ALERTS: 'P1_ALERTS',
  NO_SUBSCRIPTION: 'NO_SUBSCRIPTION',
};

export default scanType;

                                                          
