//     
import React, { memo } from 'react';
import { NavLink, Route } from 'react-router-dom';
import ExtLink from './ExtLink';


              
                 
             
                  
                   
                    
                     
                     
                                                   
                       
                            
  

function MobileNavLink({
  children,
  activeStyle,
  to,
  exact = false,
  redirect = false,
  target = '_blank',
  strict,
  replace,
  ...rest
}       ) {
  return redirect ? (
    <ExtLink {...rest} to={to} target={redirect ? target : '_self'}>
      {children}
    </ExtLink>
  ) : (
    <Route path={to} exact={exact} strict={strict}>
      {() => {
        if (to) {
          return (
            <NavLink
              {...rest}
              to={to}
              replace={replace}
              style={{ display: 'block' }}
            >
              {children}
            </NavLink>
          );
        }

        return <>{children}</>;
      }}
    </Route>
  );
}

export default memo       (MobileNavLink);
