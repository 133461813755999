//      
import React from 'react';
                                                                                                          
import {
  getIconFromComplianceStandard,
  getDisplayTextFromComplianceStandard,
  getDisplayTextFromComplianceStandardCriteria,
} from './util';
import { palette } from '@dt/theme';
import { Tooltip, Typography } from '@mui/material';

                  
                                                     
                                                                      
                       
   

                               

const ComplianceTag = ({
  compliance_standard: standard,
  compliance_standard_criteria: criteria,
  description,
}       ) => {
  const tooltipText = (
    <>
      {getDisplayTextFromComplianceStandard(standard)} {getDisplayTextFromComplianceStandardCriteria(criteria)}
      <hr />
      {description}
    </>
  );

  return (
    <Tooltip title={tooltipText}>
      <div
        style={{
          padding: '4px 6px',
          backgroundColor: palette.gray45,
          borderRadius: 4,
          margin: '8px 4px 0',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          src={getIconFromComplianceStandard(standard)}
          style={{
            height: '14px',
            width: '14px',
            marginRight: 4,
          }}
        />
        <Typography variant="caption">
          {`${getDisplayTextFromComplianceStandard(standard)} ${getDisplayTextFromComplianceStandardCriteria(
            criteria,
          )}`}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default React.memo          (ComplianceTag);
