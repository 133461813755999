//      
import React, { memo, useMemo, useEffect, useState } from 'react';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import debounce from 'lodash/fp/debounce';
import { Waypoint } from 'react-waypoint';
import { Grid, List, ListItem, Checkbox, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Text } from '@dt/material-components';
import { ErrorState } from '@dt/components';
import asset_tags from '@dt/graphql-support/horizon/asset_tags';
                                                                                                         

const useStyles = makeStyles(theme => ({
  mainList: {
    maxHeight: 350,
    overflow: 'scroll',
    overflowX: 'auto',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  itemLogo: {
    justifyContent: 'center',
  },
}));

              
                          
                                                       
                                                   
  

const InventoryTableFilterAssetTagValuesComponent = function InventoryTableFilterAssetTagValues({
  selectedTagUuId,
  selectedAssetTags,
  onToggleAssetTagValue,
}       ) {
  const classes = useStyles();

  const [fetchAssetTagValuesQuery, { data, error, loading, called, fetchMore, pageNumber }] = useLazyQuery 
                           
                                    
   (asset_tags.values.list, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchAssetTagValuesQuery({
      variables: {
        tag_uuid: selectedTagUuId,
      },
    });
  }, [fetchAssetTagValuesQuery, called, selectedTagUuId]);

  const [filter, setFilter] = useState        ('');
  const handleChange = useMemo(
    () =>
      debounce(500)((filter        ) => {
        fetchAssetTagValuesQuery(
          filter
            ? {
                variables: {
                  tag_uuid: selectedTagUuId,
                  filter_by_text: filter,
                },
              }
            : {
                variables: {
                  tag_uuid: selectedTagUuId,
                },
              },
        );
      }),
    [fetchAssetTagValuesQuery, selectedTagUuId],
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={filter}
          onChange={e => {
            setFilter(e.target.value);
            handleChange(e.target.value);
          }}
          id="asset-values-filter"
          label={'Filter'}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <List className={classes.mainList} component="nav">
          {error ? (
            <ErrorState error={error} />
          ) : !pageNumber && loading ? (
            [0, 1, 2, 3, 4].map((_, key) => (
              <ListItem button dense key={key}>
                <Skeleton animation="wave" width={`100%`} height={40} />
              </ListItem>
            ))
          ) : (
            data?.asset_tag_value_list?.asset_tags.map(
              (assetTag, key) =>
                assetTag?.value && (
                  <ListItem button dense key={key} onClick={() => onToggleAssetTagValue(assetTag.tag, assetTag.value)}>
                    <Checkbox
                      checked={
                        Boolean(selectedAssetTags[assetTag.tag] === null) ||
                        Boolean(selectedAssetTags[assetTag.tag]?.includes(assetTag.value))
                      }
                    />
                    <Text noWrap>{assetTag.value}</Text>
                  </ListItem>
                ),
            )
          )}
          {fetchMore && (
            <Waypoint
              onEnter={() => {
                if (fetchMore) {
                  fetchMore();
                }
              }}
            >
              <Box component={'div'}>
                <ListItem button dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </ListItem>
                <ListItem button dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </ListItem>
                <ListItem button dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </ListItem>
              </Box>
            </Waypoint>
          )}
        </List>
      </Grid>
    </Grid>
  );
};

export default memo       (InventoryTableFilterAssetTagValuesComponent);
