//      
import React from 'react';
import { PolicyViolationStatusEnumValues } from '@dt/graphql-support/enums';
import { PolicyRuleTypeRelevanceValues } from '@dt/graphql-support/enums';
import { PolicyViolationsStatusesTagStripCounts } from './PolicyViolationsStatusesTagStripCounts';
                                                                                                    

               
                                           
                                       
                                        
       
     
   

/*
 * Policy violations tag strip for showing the following categories of policy violations.
 *
 * - URGENT
 * - IMPORTANT
 * - PROACTIVE
 */
const PolicyViolationsStatusesTagStripComponent = function PolicyViolationsStatusesTagStrip({
  policy_violations_data,
}       ) {
  if (policy_violations_data.length > 0) {
    const urgentViolations = policy_violations_data.filter(
      pv => pv.status === PolicyViolationStatusEnumValues.OPEN && pv.relevance === PolicyRuleTypeRelevanceValues.URGENT,
    );
    const importantViolations = policy_violations_data.filter(
      pv =>
        pv.status === PolicyViolationStatusEnumValues.OPEN && pv.relevance === PolicyRuleTypeRelevanceValues.IMPORTANT,
    );
    const proactiveViolations = policy_violations_data.filter(
      pv =>
        pv.status === PolicyViolationStatusEnumValues.OPEN && pv.relevance === PolicyRuleTypeRelevanceValues.PROACTIVE,
    );

    return (
      <PolicyViolationsStatusesTagStripCounts
        urgent={urgentViolations.length}
        important={importantViolations.length}
        proactive={proactiveViolations.length}
      />
    );
  }

  return <PolicyViolationsStatusesTagStripCounts urgent={0} important={0} proactive={0} />;
};

export const PolicyViolationsStatusesTagStrip = PolicyViolationsStatusesTagStripComponent;
