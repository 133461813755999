//      
import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Grid, CircularProgress } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from '@mui/styles';
import CloudLeaksTableHead from './ToolkitAssetTableHead';
import ToolkitTablePolicyViolationRow from './ToolkitAssetTablePolicyViolationRow';
import ToolkitTableAssetRow from './ToolkitAssetTableRow';
             
                                         
                          
                              
                    
                
                                   

const useStyles = makeStyles({
  tableContainer: {
    height: 400,
    paddingRight: 4,
  },
  fetchMoreLoading: {
    padding: 8,
  },
});

                                        
                
                                                                                       
                        
    
    
  

                                       
                                                                                                  
    
  

                                     
                                                                                                    
    
  

                 
                                    
                     
     
  

              
                                                               
                                                                                       
                                  
                              
                     
                           
                                                                                 
                                  
                     
                 
  

function ToolkitAssetTable({
  policyViolations,
  assets,
  assetType,
  scans,
  policyViolationPath,
  portalPath,
  onClick,
  openRowId,
  fetchMore,
  isV2 = false,
}       ) {
  const classes = useStyles({});

  return (
    <TableContainer classes={{ root: classes.tableContainer }}>
      <Table stickyHeader>
        <CloudLeaksTableHead />
        <TableBody>
          {policyViolations?.map((policyViolation, idx) => (
            <ToolkitTablePolicyViolationRow
              key={idx}
              policyViolationPath={policyViolationPath}
              portalPath={portalPath}
              asset={policyViolation}
              scans={scans}
              onClick={onClick}
              openRowId={openRowId}
            />
          ))}
          {assets?.map((asset, idx) => (
            <ToolkitTableAssetRow
              key={idx}
              isV2={isV2}
              portalPath={portalPath}
              asset={asset}
              assetType={assetType}
              openRowId={openRowId}
              onClick={onClick}
              scans={scans}
            />
          ))}
        </TableBody>
      </Table>
      {fetchMore && (
        <Waypoint onEnter={() => fetchMore()}>
          <Grid container classes={{ root: classes.fetchMoreLoading }}>
            <Grid container alignItems="center" justifyContent="center">
              <CircularProgress color="primary" size={24} />
            </Grid>
          </Grid>
        </Waypoint>
      )}
    </TableContainer>
  );
}

export default React.memo       (ToolkitAssetTable);
