var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
export var asset_tag_key_list = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query AssetTagKeyList($filter_by_text: String, $cursor: String, $page_size: Float) {\n    asset_tag_key_list(filter_by_text: $filter_by_text, cursor: $cursor, page_size: $page_size) {\n      pagination_information {\n        next_cursor\n        total_count\n      }\n      asset_tag_keys {\n        id\n        name\n        imported_external_id\n        imported_from\n      }\n    }\n  }\n"], ["\n  query AssetTagKeyList($filter_by_text: String, $cursor: String, $page_size: Float) {\n    asset_tag_key_list(filter_by_text: $filter_by_text, cursor: $cursor, page_size: $page_size) {\n      pagination_information {\n        next_cursor\n        total_count\n      }\n      asset_tag_keys {\n        id\n        name\n        imported_external_id\n        imported_from\n      }\n    }\n  }\n"])));
export var asset_tag_value_list = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query AssetTagValueList($tag_uuid: ID!, $filter_by_text: String, $cursor: String, $page_size: Float) {\n    asset_tag_value_list(tag_uuid: $tag_uuid, filter_by_text: $filter_by_text, cursor: $cursor, page_size: $page_size) {\n      pagination_information {\n        next_cursor\n        total_count\n      }\n      asset_tags {\n        id\n        tag_id\n        tag\n        value\n        imported_external_id\n        imported_from\n      }\n    }\n  }\n"], ["\n  query AssetTagValueList($tag_uuid: ID!, $filter_by_text: String, $cursor: String, $page_size: Float) {\n    asset_tag_value_list(tag_uuid: $tag_uuid, filter_by_text: $filter_by_text, cursor: $cursor, page_size: $page_size) {\n      pagination_information {\n        next_cursor\n        total_count\n      }\n      asset_tags {\n        id\n        tag_id\n        tag\n        value\n        imported_external_id\n        imported_from\n      }\n    }\n  }\n"])));
export var getById = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  query AssetTagsById($uuid: ID!) {\n    asset_tags(uuid: $uuid) {\n      asset_tags {\n        id\n        tag_id\n        tag\n        value\n        imported_from\n        imported_external_id\n        imported_from_icon_url\n      }\n    }\n  }\n"], ["\n  query AssetTagsById($uuid: ID!) {\n    asset_tags(uuid: $uuid) {\n      asset_tags {\n        id\n        tag_id\n        tag\n        value\n        imported_from\n        imported_external_id\n        imported_from_icon_url\n      }\n    }\n  }\n"])));
export var putById = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  mutation PutAssetTags($uuid: ID!, $body: AssetTagPutParams!) {\n    asset_tags_put(uuid: $uuid, body: $body) {\n      asset_tags {\n        id\n        tag_id\n        tag\n        value\n        imported_from\n        imported_external_id\n        imported_from_icon_url\n      }\n    }\n  }\n"], ["\n  mutation PutAssetTags($uuid: ID!, $body: AssetTagPutParams!) {\n    asset_tags_put(uuid: $uuid, body: $body) {\n      asset_tags {\n        id\n        tag_id\n        tag\n        value\n        imported_from\n        imported_external_id\n        imported_from_icon_url\n      }\n    }\n  }\n"])));
export default {
    list: asset_tag_key_list,
    values: {
        list: asset_tag_value_list,
    },
    getById: getById,
    putById: putById,
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
