//      
import React from 'react';
import Text from './Text';
import { Grid, Card, CardContent } from '@mui/material';

              
                  
                 
  

function CardWithHeading({ heading, children }       ) {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Text variant="titleXS" style={{ margin: 0, textTransform: 'uppercase' }}>
          {heading}
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default React.memo       (CardWithHeading);
