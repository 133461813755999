//      
import React from 'react';
                                                                                                          
import ComplianceTag from './ComplianceTag';
import { withStyles } from '@mui/styles';
import MultiComplianceTag from './MultiComplianceTag';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '-8px -4px 0',
    flexWrap: 'wrap',
  },
};

                  
                                        
                                                       
                                                                        
                         
                  
       
     
                     
   

                                              

/*
 * @deprecated - Should use the `ComplianceTagStrip` component in ahura instead.
 *
 * Display compliance standard criteria usually for a policy violation.
 *
 * @param compliance_references - Compliance references to display. Provided by the backend.
 * @param granular - Used to show individual tags for each `compliance_standard_criteria`.
 */
const ComplianceTagStrip = ({ complianceReferences, granular }       ) => {
  const groupBy = (objectArray, property) => {
    return objectArray.reduce(function(acc, obj) {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  const referencesByStandard = groupBy(complianceReferences, 'compliance_standard');

  return (
    <div style={styles.container}>
      {granular
        ? complianceReferences.map(r => (
            <ComplianceTag
              key={r.compliance_standard_criteria}
              compliance_standard={r.compliance_standard}
              compliance_standard_criteria={r.compliance_standard_criteria}
              description={r.description}
            />
          ))
        : Object.keys(referencesByStandard).map(standard => (
            <MultiComplianceTag
              key={standard}
              complianceStandard={referencesByStandard[standard][0].compliance_standard}
              complianceReferences={referencesByStandard[standard]}
            />
          ))}
    </div>
  );
};

export default React.memo          (withStyles(styles)(ComplianceTagStrip));
