//      
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                           
                                       
   

                              
                              

                                                                            
                               
                                  
                                  

                               
   

                                      
                                                  
                          
                                   
                                          
   

                                   
                          
                                                
                                      
   

                                          
                                                
                                                 
                                                
                                                       
   

                               
                          

                                               

                                               
                                               
                                                 
                                                  
                                                    

                              
                             

                                                    
                                                  
   

                                                                

export async function list(
  reference_date         ,
)                                            {
  return fetch(
    `${api}/${version}/inventory_stats${
      reference_date ? '/' + reference_date : ''
    } `,
  ).then(parse);
}
