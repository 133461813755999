//     
import config from '@dt/config';
import { clearGateSession } from './gate';

if (!config || typeof config.authHost !== 'string') {
  throw new Error('config.authHost must be a URL');
}

const authHost = config.authHost;

const clearAndRedirect = async becauseExpired => {
  await clearGateSession(authHost);

  // When running in JSDOM the window closes after a test completes.
  // However, JSDOM doesn't cleanup async context very well and will crash when accessing properties of the window
  // object after the test "finished". The following line first checks if the window was closed before accessing
  // window properties.
  if (
    process.env.NODE_ENV === 'test' &&
    // $FlowFixMe - JSDOM internal to indicate whether or not the window was closed.
    typeof window._document === 'undefined'
  )
    return;

  if (window && window.location) {
    window.location.assign(
      `${authHost}/auth/?${becauseExpired ? 'reason=expired&' : ''}forwardTo=${window.location.href}`,
    );
  }
};

export const clearSessionAndRedirectToExpired = async () => clearAndRedirect(true);

export const clearSessionAndRedirectToLogin = async () => {
  clearAndRedirect(false);
};

// You usually don't want to call this function. Instead you should probably
// use clearSessionAndRedirectToLogin. This function is only needed if you're
// sure you don't need to clear the existing session (presumably because you
// have checked that it doesn't exist already)
export const redirectToLogin = () => {
  window.location.assign(`${authHost}/auth/?forwardTo=${window.location.href}`);
};
