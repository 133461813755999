//     
import immer from 'immer';
                                                
                                                    
import { apiOperationsReceived } from './actions';

                                                        

                                   
                                              
                                                             
   

const initialState = {
  id: {},
  for_policy_violation_id: {},
};

export default immer                             ((draft, action         ) => {
  if (action.type === apiOperationsReceived.toString()) {
    const { api_operations } = action.payload;

    for (const operation of api_operations) {
      draft.id[operation.id] = operation;
      for (const policy_violation_id of operation.policy_violation_ids) {
        draft.for_policy_violation_id[policy_violation_id] = operation.id;
      }
    }
  }
}, initialState);
