//     
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
                                                               
import {
  updateApp,
  openscanMobileAppsRecieved,
  contactPublisherRequestReceived,
  contactPublisherRequestLoading,
  contactPublisherRequestError,
} from '../actions';

                                                                  

export default createReducerCreator                                            ({
  initialState: ([]                   ),
  equalityCheck: (a, b) => a.id === b.id,
})(
  updateInList([
    updateApp.toString(),
    openscanMobileAppsRecieved.toString(),
    contactPublisherRequestReceived.toString(),
    contactPublisherRequestLoading.toString(),
    contactPublisherRequestError.toString(),
  ]),
);
