//      
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                               
                                       
   

export async function get()                                                {
  return fetch(`${api}/${version}/certificate_transparency_domains`).then(
    parse,
  );
}
