//      
import React from 'react';
                                                                                                          
import { makeStyles } from '@mui/styles';
import { PolicyViolationsComplianceTag } from './PolicyViolationsComplianceTag';
import { PolicyViolationsComplianceMultiTag } from './PolicyViolationsComplianceMultiTag';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    margin: '-8px -4px 0',
    flexWrap: 'wrap',
  },
});

               
                                          
                                                       
                                                                        
                         
                  
       
     
                      
   

/*
 * Display compliance standard criteria usually for a policy violation.
 *
 * @param compliance_references - Compliance references to display. Provided by the backend.
 * @param granular - Used to show individual tags for each `compliance_standard_criteria`.
 */
const PolicyViolationsComplianceTagStripComponent = function PolicyViolationsComplianceTagStrip({
  compliance_references,
  granular,
}       ) {
  const classes = useStyles();

  const groupBy = (objectArray, property) => {
    return objectArray.reduce(function(acc, obj) {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  const referencesByStandard = groupBy(compliance_references, 'compliance_standard');

  return (
    <div className={classes.container}>
      {granular
        ? compliance_references.map(r => (
            <PolicyViolationsComplianceTag
              key={r.compliance_standard_criteria}
              compliance_standard={r.compliance_standard}
              compliance_standard_criteria={r.compliance_standard_criteria}
              description={r.description}
            />
          ))
        : Object.keys(referencesByStandard).map(standard => (
            <PolicyViolationsComplianceMultiTag
              key={standard}
              complianceStandard={referencesByStandard[standard][0].compliance_standard}
              complianceReferences={referencesByStandard[standard]}
            />
          ))}
    </div>
  );
};

export const PolicyViolationsComplianceTagStrip = React.memo       (PolicyViolationsComplianceTagStripComponent);
