//     
                                       

export default async (
  fromField        ,
  index_key        ,
  obj         ,
  args       ,
  context         ,
)                 => {
  if (!index_key) {
    throw new Error(`Expected to find an index for GraphQL type
            but didn't. This is probably because the type isn't
            provided at one of the top-level fields for the @restEndpoint
            that returned this object.`);
  }

  if (typeof obj[fromField] === 'string') {
    return context.rest.db[index_key].id[obj[fromField]];
  } else if (obj[fromField] === null || typeof obj[fromField] === 'undefined') {
    return obj[fromField]; // will be null || undefined
  } else if (Array.isArray(obj[fromField])) {
    return obj[fromField].map(item => {
      if (typeof item === 'string') {
        return context.rest.db[index_key].id[item];
      } else if (item === null || typeof item === 'undefined') {
        return item; // will be null || undefined
      }
      throw new Error(
        `A value in a fromField ARRAY must be string, null, or undefined. This link does not support other types.`,
      );
    });
  } else {
    throw new Error(
      `A value in a fromField must be an array, string, null, or undefined. This link does not support other types.`,
    );
  }
};
