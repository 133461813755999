//      
import gql from 'graphql-tag';

const list = gql`
  query AssetsSearchFilterValues($filter_by_search_ui: String) {
    assets_search_filter_values(filter_by_search_ui: $filter_by_search_ui) {
      assets_search_filter_values {
        belongs_to_filter
        icon_url
        name
        value
        value_category
      }
    }
  }
`;

export default {
  list,
};
