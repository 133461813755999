//      
import config from '@dt/config';
import qs from 'query-string';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                   
                     

                                            
                                            
                                                      
                                                  
                   

                                        
                               
                                
                                      

                                         
                               
                                        
                         
   

                                 
                                                                  
                                                   
                   
   

export async function list(
  params                   ,
)                                                       {
  return fetch(`${api}/${version}/events?${qs.stringify(params || {})}`).then(
    parse,
  );
}
