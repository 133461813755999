import React, { useEffect } from 'react';
// @ts-ignore: @reach/router types
import { useLocation } from '@reach/router';
var ScrollToTopOnNavigate = function () {
    var pathname = useLocation().pathname;
    useEffect(function () {
        window.scrollTo(0, 0);
    }, [pathname]);
    return React.createElement(React.Fragment, null);
};
export default ScrollToTopOnNavigate;
