//      

import { palette } from '@dt/theme';
import { Switch } from '@mui/material';
import { withStyles } from '@mui/styles';

export default withStyles({
  switchBase: {
    '&$checked': {
      color: palette.brand30,
    },
    '&$checked + $track': {
      backgroundColor: palette.brand40,
    },
  },
  checked: {},
  track: {},
})(Switch);
