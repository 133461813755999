import React, { memo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, IconButton, ListItem, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
// @ts-ignore JS component
import { Text } from '@dt/material-components';
import TableFiltersAssetTagKeyValues from './TableFiltersAssetTagKeyValues';
var useStyles = makeStyles(function () { return ({
    keyItem: {
        justifyContent: 'space-between',
    },
    customTooltip: {
        position: 'relative',
        zIndex: 9999,
    },
}); });
var InventoryTableFilterAssetTagsComponent = function InventoryTableFilterAssetTags(_a) {
    var _b, _c, _d;
    var assetTagKey = _a.assetTagKey, selectedAssetTags = _a.selectedAssetTags, onToggleAssetTagKey = _a.onToggleAssetTagKey, onToggleAssetTagValue = _a.onToggleAssetTagValue;
    var classes = useStyles();
    var _e = useState(), totalValues = _e[0], setTotalValues = _e[1];
    var _f = useState(false), showValue = _f[0], setShowValues = _f[1];
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { button: true, dense: true, className: classes.keyItem },
            React.createElement(Box, { display: 'flex', onClick: function () { return onToggleAssetTagKey(assetTagKey.name); }, flexGrow: 1, alignItems: 'center' },
                React.createElement(Checkbox, { checked: Boolean(selectedAssetTags[assetTagKey.name] === null) ||
                        Boolean((_b = selectedAssetTags[assetTagKey.name]) === null || _b === void 0 ? void 0 : _b.length), indeterminate: Boolean((_c = selectedAssetTags[assetTagKey.name]) === null || _c === void 0 ? void 0 : _c.length) &&
                        Number((_d = selectedAssetTags[assetTagKey.name]) === null || _d === void 0 ? void 0 : _d.length) < Number(totalValues) }),
                React.createElement(Text, { component: 'div', noWrap: true }, assetTagKey.name)),
            React.createElement(Box, { flex: '0 0 30px' },
                React.createElement(Tooltip, { disableInteractive: true, PopperProps: { style: { zIndex: 99999 } }, title: 'Click to see all values for this tag' },
                    React.createElement(IconButton, { "aria-label": "expand row", size: "small", onClick: function () {
                            setShowValues(function (prev) { return !prev; });
                        } }, showValue ? React.createElement(KeyboardArrowUpIcon, null) : React.createElement(KeyboardArrowDownIcon, null))))),
        showValue && (React.createElement(Box, { ml: 4 },
            React.createElement(TableFiltersAssetTagKeyValues, { setTotalValues: setTotalValues, assetTagKey: assetTagKey, selectedAssetTags: selectedAssetTags, onToggleAssetTagValue: onToggleAssetTagValue })))));
};
export default memo(InventoryTableFilterAssetTagsComponent);
