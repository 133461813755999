//     
import React from 'react';
import { PolicyViolationsStatusesIcon } from './PolicyViolationsStatusesIcon';

              
                  
                     
                     
  

/*
 * Takes policy violation urgent, important, proactive counts and renders an overview badge.
 */
export const PolicyViolationsStatusesBadge = ({ urgent, important, proactive }       ) => {
  if (urgent > 0) {
    return <PolicyViolationsStatusesIcon type="urgent" />;
  }

  if (important > 0) {
    return <PolicyViolationsStatusesIcon type="important" />;
  }

  if (proactive > 0) {
    return <PolicyViolationsStatusesIcon type="proactive" />;
  }

  // No badge should be rendered when there are no policy violation counts.
  return null;
};
