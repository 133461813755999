//      
import {
  CloudResourceTypeDefinition
} from '@dt/horizon-api';

                                                                 
                                                                         
                                                                       
                                                                         
                                                                 

                                            
             
               
              
                      
                                    
                       
                                                                        
                                                        
                                                           

                                                                                                                    
                                                                    
                                                   
   

export const AssetTypeDict = {
  RESTFUL_API: 'RESTFUL_API',
  SINGLE_PAGE_WEB_APP: 'SINGLE_PAGE_WEB_APP',
  API_DOMAIN: 'API_DOMAIN',
  CLOUD_RESOURCE: 'CLOUD_RESOURCE',
};

export const AssetOptionTypeDict = {
  ANY: 'ANY',
  [AssetTypeDict.RESTFUL_API]: AssetTypeDict.RESTFUL_API,
  [AssetTypeDict.SINGLE_PAGE_WEB_APP]: AssetTypeDict.SINGLE_PAGE_WEB_APP,
  [AssetTypeDict.API_DOMAIN]: AssetTypeDict.API_DOMAIN,
  ...CloudResourceTypeDefinition,
};
                                                                

                                       
                       
                           
                          
                           
                        

                                                                                  

                               
                                 
                                     
                                    
                                     
   

                                               
                                                                    
                                                                        
                                                                       
                                                                        
   
