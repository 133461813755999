//     
import React, { useState } from 'react';
import {
  Button,
  Popper,
  List,
  ListItem,
  ListItemText,
  Paper,
  ClickAwayListener,
  Snackbar,
  IconButton,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { useLazyQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Text } from '@dt/material-components';
import asset_groups from '@dt/graphql-support/horizon/asset_groups';
import bulk_actions from '@dt/graphql-support/horizon/bulk_actions';
import assets from '@dt/graphql-support/horizon/assets';
import { palette } from '@dt/theme';
             
                 
                          
                         
                                    
                                   

const useStyles = makeStyles({
  popper: {
    // zIndex set here to be above the LHS menu of 1200.
    zIndex: 1201,
  },
  paper: {
    marginTop: 8,
    minWidth: 400,
    maxHeight: 600,
    overflow: 'auto',
    backgroundColor: palette.white,
  },
});

                  
     
                              
     
     
                                      
      

              
                             
  

export const ShadowAssetsLayoutTriageButton = ({ checkedIds }       ) => {
  const classes = useStyles();

  const [getAssetGroupsList, { data, loading, called }] = useLazyQuery                           (asset_groups.v2_list);
  const [anchorEl, setAnchorEl] = useState                    (null);
  const [triage] = useMutation                                      (bulk_actions.assets.shadow_assets_triage_actions);
  const [toastMessage, setToastMessage] = useState         
                               
                     
    (null);
  // Track whether toast message is open or not.
  // NOTE: This is separate from 'toastMessage' to keep the animation smooth
  //       by keeping the text that was used to open the toast message.
  const [toastMessageOpen, setToastMessageOpen] = useState         (false);

  const handleClickTriage = (e                             ) => {
    const eventTarget = e.currentTarget;

    // Load asset groups list if we haven't already.
    if (!called) {
      getAssetGroupsList();
    }

    setAnchorEl(current => (current ? null : eventTarget));
  };
  const handleTriage = (e             ) => {
    (async () => {
      const { errors } = await triage({
        variables: {
          body: {
            perform_on_assets_with_ids: checkedIds,
            move_to_asset_group_id: e.removeFromAllAssetGroups ? null : e.asset_group_id,
          },
        },

        update: (cache, result) => {
          if (result?.errors) {
            return;
          }

          // Remove checked assets from cached asset list queries.
          const assetListQueries = Object.keys(cache.data.data.ROOT_QUERY).filter(key => key.includes('asset_list'));
          const assetListQueriesVariables = assetListQueries.map(key =>
            JSON.parse(key.match(/\((.*)\)/)?.pop() || '{}'),
          );
          assetListQueriesVariables.forEach(variables => {
            const q = cache.readQuery                                         ({
              query: assets.list,
              variables: {
                ...variables,
              },
            });
            cache.writeQuery({
              query: assets.list,
              variables: {
                ...variables,
              },
              data: {
                asset_list: {
                  ...q?.asset_list,
                  pagination_information: {
                    ...q?.asset_list.pagination_information,
                    total_count:
                      q?.asset_list.pagination_information.total_count -
                      q?.asset_list?.assets.filter(asset => checkedIds.includes(asset.id)).length,
                  },
                  assets: q?.asset_list?.assets.filter(asset => !checkedIds.includes(asset.id)),
                },
              },
            });
          });
        },
      });

      if (errors && errors.length >= 1) {
        setToastMessage({ type: 'error', message: errors[0].message });
        setToastMessageOpen(true);
      }
    })();
  };
  const handleToastMessageDismissed = () => {
    setToastMessageOpen(false);
  };

  return (
    <>
      <Box display="inline-flex" alignItems="center">
        <Box mr={1}>
          <Text variant="body" style={{ margin: 0 }}>
            {checkedIds.length} Selected
          </Text>
        </Box>
        <Button
          style={{ height: 30 }}
          variant="contained"
          color="primary"
          onClick={handleClickTriage}
          disabled={checkedIds.length <= 0}
        >
          Triage
        </Button>

        <Popper open={!!anchorEl} anchorEl={anchorEl} placement={'bottom-start'} className={classes.popper}>
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Paper elevation={3} className={classes.paper}>
              <List component="nav">
                {loading ? (
                  <>
                    <ListItem>
                      <Skeleton animation="wave" height={40} width={350} />
                    </ListItem>
                    <ListItem>
                      <Skeleton animation="wave" height={40} width={350} />
                    </ListItem>
                    <ListItem>
                      <Skeleton animation="wave" height={40} width={350} />
                    </ListItem>
                  </>
                ) : (
                  <>
                    <ListItem
                      button
                      onClick={() => {
                        setAnchorEl(null);
                        handleTriage({ removeFromAllAssetGroups: true });
                      }}
                    >
                      <ListItemText primary="Remove assets from all groups" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Move to Asset Group" />
                    </ListItem>
                    {data?.asset_group_list_v2.asset_groups.map(asset_group => (
                      <ListItem
                        button
                        onClick={() => {
                          setAnchorEl(null);
                          handleTriage({ asset_group_id: asset_group.id });
                        }}
                        className={classes.triageListItem}
                        key={asset_group.id}
                      >
                        <ListItemText style={{ maxWidth: 400, overflow: 'hidden' }} inset primary={asset_group.name} />
                      </ListItem>
                    ))}
                  </>
                )}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={toastMessageOpen}
        autoHideDuration={5000}
        onClose={handleToastMessageDismissed}
      >
        <Alert
          variant="filled"
          severity={toastMessage?.type}
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleToastMessageDismissed}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {toastMessage?.message}
        </Alert>
      </Snackbar>
    </>
  );
};
