//      
import { createAction } from 'redux-actions';

export const createSubscriptionAction = createAction 
                                     
                      
                                                    
 ('billing-info/subscription/create', (accountInfo, currentUser) => ({
  accountInfo,
  currentUser,
}));

export const createActiveSubscriptionAction = createAction 
                                            
                      
                                                    
 ('billing-info/subscription/Active/create', (accountInfo, currentUser) => ({
  accountInfo,
  currentUser,
}));

export const createPastDueSubscriptionAction = createAction 
                                              
                      
                                                    
 ('billing-info/subscription/past-due/create', (accountInfo, currentUser) => ({
  accountInfo,
  currentUser,
}));

export const createDemoSubscriptionAction = createAction 
                                          
                      
                                                    
 ('billing-info/subscription/demo/create', (accountInfo, currentUser) => ({
  accountInfo,
  currentUser,
}));

export const dismissBillingReminderAlertAction = createAction 
                                  
     
       
 ('billing-info/reminder/dismiss', () => null);

export const redirectToPayNowAction = createAction 
                                    
     
       
 ('billing-info/redirect_to_paynow', () => null);

export const openQouteUrlAction = createAction 
                                       
     
       
 ('billing-info/redirect_to_qoute_url', () => null);

export const openUploadPoComponentAction = createAction 
                              
     
       
 ('billing-info/po_file/open', () => null);

export const closeUploadPoComponentAction = createAction 
                               
     
       
 ('billing-info/po_file/close', () => null);

export const notifyBillingContactAction = createAction 
                                        
     
       
 ('billing-info/notify_billing_contact', () => null);

export const uploadPoFileAction = createAction 
                                
                         
                                               
 ('billing-info/po_file/upload', (file, name, desc) => ({ file, name, desc }));

/** purchase quote actions */

export const redirectToPurchaseQuoteAction = createAction 
                                         
     
       
 ('billing-info/purchase_quote/redirect', () => null);

export const loadPurchaseQuoteAction = createAction 
                                     
     
       
 ('billing-info/purchase_quote/load', () => null);

export const purchaseQuoteAppListFetchRequest = createAction 
                                                           
     
       
 ('billing-info/purchase_quote/load/app_secure/fetch_list', () => null);

export const purchaseQuoteAppListFetchSucceed = createAction 
                                                    
   
            
                   
                       
                 
                    
                       
        
    
    
                     
                   
                       
                 
                       
        
     
 ('billing-info/purchase_quote/app_secure/set_list', appList => ({ appList }));

export const purchaseQuoteApiStatsFetchRequest = createAction 
                                                            
     
       
 ('billing-info/purchase_quote/load/api_secure/fetch_stats', () => null);

export const purchaseQuoteApiStatsFetchSucceed = createAction 
                                                     
   
      
                           
                      
                               
                  
                         
       
    
    
             
                           
                      
                               
                  
                         
       
     
 ('billing-info/purchase_quote/api_secure/set_stats', stats => ({ stats }));

export const purchaseQuoteSelectSubscription = createAction 
                                                    
           
                               
 ('billing-info/purchase_quote/subscription/select', subscriptionId => ({
  subscriptionId,
}));

export const purchaseQuoteSelectDiscount = createAction 
                                                
                    
                                              
 ('billing-info/purchase_quote/select_discount', (discountId, selected) => ({
  discountId,
  selected,
}));

export const purchaseQuoteAppSecureSelectApp = createAction 
                                                      
                    
                                        
 ('billing-info/purchase_quote/app_secure/select_app', (appId, checked) => ({
  appId,
  checked,
}));

export const purchaseQuoteAppSecureSelectAllApps = createAction 
                                                           
     
       
 ('billing-info/purchase_quote/app_secure/select_all_apps', () => null);

export const purchaseQuoteAppSecureDeselectAllApps = createAction 
                                                             
     
       
 ('billing-info/purchase_quote/app_secure/deselect_all_apps', () => null);

export const purchaseQuoteApiSecureSelectPlan = createAction 
                                                       
           
                        
 ('billing-info/purchase_quote/api_secure/select_plan', plainId => ({
  plainId,
}));

export default {
  createSubscriptionAction,
  createActiveSubscriptionAction,
  createPastDueSubscriptionAction,
  createDemoSubscriptionAction,
  dismissBillingReminderAlertAction,
  redirectToPayNowAction,
  openQouteUrlAction,
  notifyBillingContactAction,
  openUploadPoComponentAction,
  closeUploadPoComponentAction,
  uploadPoFileAction,
  redirectToPurchaseQuoteAction,
  loadPurchaseQuoteAction,
  purchaseQuoteAppListFetchRequest,
  purchaseQuoteAppListFetchSucceed,
  purchaseQuoteApiStatsFetchRequest,
  purchaseQuoteApiStatsFetchSucceed,
  purchaseQuoteSelectSubscription,
  purchaseQuoteSelectDiscount,
  purchaseQuoteAppSecureSelectApp,
  purchaseQuoteAppSecureSelectAllApps,
  purchaseQuoteAppSecureDeselectAllApps,
  purchaseQuoteApiSecureSelectPlan,
};
