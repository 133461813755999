//     
import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { Text, ExtLink } from '@dt/material-components';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import { palette } from '@dt/theme';

const UnauthorizedErrorComponent = function UnauthorizedError() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%" height="50vh">
      <Avatar
        style={{
          backgroundColor: palette.blue50,
          color: palette.blue10,
          width: 80,
          height: 80,
        }}
      >
        <LockIcon style={{ width: 45, height: 50 }} />
      </Avatar>
      <Text variant="titleM">Unauthorized</Text>
      <Text variant="body">You aren&apos;t authorized to access this content.</Text>
      <Text variant="body">
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            history.back();
          }}
        >
          <Text variant="link">Go back </Text>
        </span>
        or head to the
        <ExtLink to="/" target="_self" aria-label="Go back">
          {' '}
          homepage{' '}
        </ExtLink>
        to find a new direction.
      </Text>
    </Box>
  );
};

export const UnauthorizedError = UnauthorizedErrorComponent;
