//     
import React from 'react';
import { makeStyles } from '@mui/styles';
import { PolicyRuleTypesCardSkeleton } from './PolicyRuleTypesCardSkeleton';
import { PolicyRuleTypesCardImplementation } from './PolicyRuleTypesCardImplementation';
                                                                                        

            
                   
      
                     
                      
      
                 
      
                       
                      
                           
               
                            
          
                        
           
        
       

const useStyles = makeStyles({
  title: {
    maxWidth: 600,
    cursor: 'pointer',
  },
  complianceStandardReferences: { marginTop: 16, marginBottom: 16 },
  relevance: { marginLeft: 16, textAlign: 'right' },
});

/*
 * User is shown a configurable policy rule type card that shows the current associated policy and/or allows the
 * user to create a policy rule for this policy rule type.
 *
 * @param loading - Whether or not the component needs to show a loading state.
 * @param policy_id - The policy identifier that this policy rule type corresponds too. Provided by the backend.
 * @param policy_rule - The existing policy rule for the given policy rule type. Provided by the backend.
 *                      Can be undefined if no backend policy rule exists corresponding with the provided policy rule type.
 * @param policy_rule_type - The policy rule type that the policy rule corresponds too if one exists.
 *                           Provided by the backend.
 */
const PolicyRuleTypesCardComponent = function PolicyRuleTypesCard(props       ) {
  const classes = useStyles();

  if (props.loading) {
    return <PolicyRuleTypesCardSkeleton isV2={props.isV2} />;
  } else {
    return (
      <PolicyRuleTypesCardImplementation
        classes={classes}
        isV2={props.isV2}
        policy_id={props.policy_id}
        policy_rule_type={props.policy_rule_type}
        currentTab={props.currentTab}
      />
    );
  }
};

export const PolicyRuleTypesCard = PolicyRuleTypesCardComponent;
