//      
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';

               
                                        
                       
                      
                  
                 
                 
   

export default function ConfirmDialog(props       ) {
  const [isRunning, setisRunning] = React.useState(false);

  async function onConfirm() {
    setisRunning(true);
    await props.onConfirm();
    setisRunning(false);
  }

  const isLoading = isRunning || props.isLoading;

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onCancel}
      aria-labelledby="alert-confirm-title"
      aria-describedby="alert-confirm-description"
    >
      <DialogTitle id="alert-confirm-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-confirm-description">{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => void onConfirm()}
          color="primary"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={24} />}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
