//      
import React, { memo } from 'react';


              
                 
                            
     
  

// Use case for navigation with nested items with the top-level item
// having no relevant link.
function NavWithoutLink({ children, activeStyle, ...rest }       ) {
  return <span {...rest}>{children}</span>;
}

export default memo       (NavWithoutLink);
