//      
import React, { memo, useState } from 'react';
import { Button, Popper, Paper, ClickAwayListener } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import FilterIcon from '@mui/icons-material/FilterList';
import TableFiltersChips from './components/chips/TableFiltersChips';
import TableFiltersPopper from './components/TableFiltersPopper';
             
                                       
          
                
           
                
               
                  
                                   

                       
                                                    
                                                           
                                                
                                                                    
                                                                
                                                    
                                                                                                         
                                                                                   
                                                                                
                                               
                                                          
                                                          
                                                                
                                                                                  
                                                                        
                                                                                    
                                                                
                                                                  
                           
                                                                              
                                                                          
                                                                             
                                                                         
                                                
                                                                                         
                                                                    
                                                                           
  

              
                                                                             
                             
                  
                          
                    
                            
                         
                          
                            
  

const useStyles = makeStyles(theme => ({
  popper: {
    zIndex: 9999,
    marginTop: theme.spacing(1),
    minWidth: 700,
  },

  // using the `gap` property prevents the layout shift that occurs due to the
  // filter chip having extra spacing around it. however, the `Box` component in
  // MUI v4 doesn't support `gap` as a style prop, so we need to create our own
  // styles to use it
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: `${theme.spacing(1)}px`,
  },
}));

const TableFilters = ({
  assetsSearchFilterValuesQueryParams,
  filterEnums,
  filters,
  readOnlyFilters,
  disabled,
  onApply,
  isMobilePhishing,
  isPolicyRules,
  filterButtonName,
}) => {
  const classes = useStyles();
  const [popperAnchor, setPopperAnchor] = useState                   (null);

  return (
    <>
      <Box className={classes.wrapper}>
        <Button
          variant="contained"
          size="small"
          startIcon={<FilterIcon />}
          aria-describedby={'filter-popover'}
          onClick={event => setPopperAnchor(event.currentTarget)}
          disabled={disabled}
        >
          {filterButtonName || 'Filter Assets'}
        </Button>
        <TableFiltersChips filters={filters} readOnlyFilters={readOnlyFilters} onResetFilter={onApply} />
      </Box>

      {/* Filter Box */}
      <Popper
        id={'filter-popover'}
        open={Boolean(popperAnchor)}
        anchorEl={popperAnchor}
        placement={'bottom-start'}
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={() => setPopperAnchor(null)}>
          <Paper>
            <TableFiltersPopper
              filterEnums={filterEnums}
              filters={filters}
              isMobilePhishing={isMobilePhishing}
              isPolicyRules={isPolicyRules}
              assetsSearchFilterValuesQueryParams={assetsSearchFilterValuesQueryParams}
              readOnlyFilters={readOnlyFilters}
              onClose={() => setPopperAnchor(null)}
              onApply={onApply}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default memo       (TableFilters);
