//     

const platform = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  WINDOWS_PHONE: 'WINDOWS_PHONE',
  ANDROID_AMAZON: 'ANDROID_AMAZON',
  ANDROID_OCULUS: 'ANDROID_OCULUS',
  ANDROID_FACEBOOK_PORTAL: 'ANDROID_FACEBOOK_PORTAL',
  MACOS: 'MACOS',
  IOS_ON_MAC: 'IOS_ON_MAC',
  TVOS: 'TVOS',
  MAC_CATALYST: 'MAC_CATALYST',
  ANDROID_SAMSUNG: 'ANDROID_SAMSUNG',
  ANDROID_OCULUS_RIFT: 'ANDROID_OCULUS_RIFT',
  ANDROID_OCULUS_QUEST: 'ANDROID_OCULUS_QUEST',
  WATCHOS: 'WATCHOS',
};

export default platform;

                                                           
