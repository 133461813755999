//      
/* Due to what I think is a bug, eslint is throwing no-unused-vars everywhere
   So I added ignores for all the lines that trigger it */
import { createAction } from 'redux-actions';
                                                                                //eslint-disable-line no-unused-vars
                                              //eslint-disable-line no-unused-vars
                                               
             
              
                    
                                                                
                                  
                                               
                                                                              
                                                                       
                                                                      
                                                                    

/**
 * How to annotate createAction methods: (verbatim from @ez in #javascript)
 * `const myAction = createAction<
 *     'category/action',
 *     [Arg1Type, Arg2Type],
 *     PayloadType
 * >('category/action', (arg1, arg2) => ({ arg1, arg2 }))`
 *
 * Where `PayloadType` can be something like `{ arg1: Arg1Type, arg2: Arg2Type }` in this case.
 * Basically the first generic parameter is the action type, the second is the array of argument types,
 * and the third is the type of the action payload returned by the creator.
 */

export const updateFindings = createAction 
                             
                                    
                                  
 ('SECURITY_FINDINGS_UPDATE', findings => findings);

export const exportButtonClicked = createAction                                             (
  'EXPORT_BUTTON_CLICKED',
  (appId         ) => appId,
);

export const sdksReceived = createAction                                                             (
  'SDKS_RECEIVED',
  sdks => sdks,
);

export const apiKeyPageMounted = createAction                        ('API_KEY_PAGE_MOUNTED');

export const apiKeysReceived = createAction                                                                       (
  'API_KEYS_RECEIVED',
  (apiKeys                        ) => apiKeys,
);

export const apiKeyClicked = createAction                                     (
  'API_KEY_CLICKED',
  (apiKey        ) => apiKey,
);

export const apiKeyEditFormSubmitted = createAction 
                                
                                            
                                          
 ('API_KEY_EDIT_FORM_SUBMITTED', (apiKey                                         ) => apiKey);

export const apiKeyDeleteButtonClicked = createAction 
                                  
                   
                                       
 ('API_KEY_DELETE_BUTTON_CLICKED', (apiKey        , navigate        ) => ({ apiKey, navigate }));

export const createAPIKeyButtonClicked = createAction                                 ('CREATE_API_KEY_BUTTON_CLICKED');

export const apiKeyCreateFormSubmitted = createAction 
                                  
                   
                                    
 ('API_KEY_CREATE_FORM_SUBMITTED', (apiKey        , navigate        ) => ({ apiKey, navigate }));

export const apiKeyEditResponseReceived = createAction                                                    (
  'API_KEY_EDIT_RESPONSE_RECEIVED',
  (apiKey        ) => apiKey,
);

export const apiKeyCreateResponseReceived = createAction                                                      (
  'API_KEY_CREATE_RESPONSE_RECEIVED',
  (apiKey        ) => apiKey,
);

export const apiKeyDeleteResponseReceived = createAction                                                      (
  'API_KEY_DELETE_RESPONSE_RECEIVED',
  (apiKey        ) => apiKey,
);

export const apiKeyErrorReceived = createAction                                          (
  'API_KEY_ERROR_RECEIVED',
  (error       ) => error,
);

export const userEditReceived = createAction                                             (
  'USER_EDIT_RESPONSE_RECEIVED',
  (user      ) => user,
);

                                             //eslint-disable-line no-unused-vars
export const userEditError = createAction                                                 (
  'USER_EDIT_ERROR',
  error => error,
);

export const userInviteFormSubmitted = createAction                                            (
  'USER_INVITE_FORM_SUBMITTED',
  (user      ) => user,
);

export const userInvitedReceived = createAction                                               (
  'USER_INVITE_RESPONSE_RECEIVED',
  (user      ) => user,
);

export const userInvitedError = createAction                                                   (
  'USER_INVITE_ERROR',
  error => error,
);

export const usersReceived = createAction                                                                (
  'USERS_RECEIVED',
  (users                      ) => users,
);

export const uploadButtonClicked = createAction                         ('UPLOAD_BUTTON_CLICKED');
export const appFileSubmitted = createAction 
                       
                                                         
   
                      
                     
                     
                     
                                  
                                 
       
    
 ('APP_FILE_SUBMITTED', (file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId) => ({
  file,
  username,
  password,
  comments,
  isEnterpriseInternal,
  linkedAppStoreAppId,
}));

export const appFileAccepted = createAction                                                             (
  'APP_FILE_ACCEPTED',
  response => response,
);

export const appFileRejected = createAction                                       ('APP_FILE_REJECTED', error => error);

export const appXCUITestFileSubmit = createAction 
                             
                                   
   
                 
                      
                         
                    
       
    
 ('APP_XCUITEST_FILE_SUBMIT', (file, bundle_id, release_type, version) => ({
  file,
  bundle_id,
  release_type,
  version,
}));

export const appXCUITestFileAccepted = createAction                                                    (
  'APP_XCUITEST_FILE_ACCEPTED',
  response => response,
);

export const appXCUITestFileRejected = createAction                              ('APP_XCUITEST_FILE_REJECTED');

export const sdlcInfoMounted = createAction                     ('SDLC_INFO_MOUNTED');

export const sdlcAPIKeysPanelClicked = createAction                               ('SDLC_API_KEYS_PANEL_CLICKED');

export const lastPageReceived = createAction 
                       
                                               
   
                 
                                               
       
    
 ('LAST_PAGE_RECEIVED', (type, params) => ({
  type,
  params,
}));

export const anErrorOccurred = createAction                                                           (
  'UNKNOWN_ERROR_OCCURRED',
  code => ({
    code,
  }),
);

export const dismissError = createAction                 ('DISMISS_ERROR');

export const startedLoadingApps = createAction                        ('STARTED_LOADING_APPS');

export const startedLoadingHorizonApps = createAction                                ('STARTED_LOADING_HORIZON_APPS');

export const finishedLoadingApps = createAction                         ('FINISHED_LOADING_APPS');

export const receivedAppsError = createAction                       ('RECEIVED_APPS_ERROR');

export const addCommentClicked = createAction 
                        
   
     
                   
                                           
                                   
                            
      
                    
    
   
              
                   
                                           
                                   
                            
      
                             
       
    
 ('ADD_COMMENT_CLICKED', (comment, finding) => ({
  comment,
  finding,
}));

export const addCommentRequestSucceeded = createAction 
                                  
                          
   
                  
                             
       
    
 ('ADD_COMMENT_REQUEST_SUCCEEDED', (comment, finding) => ({
  comment,
  finding,
}));

export const addCommentRequestFailed = createAction                              ('ADD_COMMENT_REQUEST_FAILED');

export const userDeleteSuccess = createAction                                              (
  'USER_DELETE_SUCCESS_RECEIVED',
  (user      ) => user,
);

export const whatsNewSeen = createAction                  ('WHATS_NEW_SEEN');

export const whatsNewUpdate = createAction                                      ('WHATS_NEW_UPDATE', date => date);

export const whatsNewResetClicked = createAction                           ('WHATS_NEW_RESET_CLICKED');

export const appProtectionMounted = createAction                                                      (
  'PROTECTION/APP_MOUNTED',
  app => app,
);

export const topAppsReceived = createAction                                                                  (
  'PROTECTION/TOP_APPS_RECEIVED',
  topApps => topApps,
);

export const topAppsByCategoryReceived = createAction                                                        (
  'PROTECTION/TOP_APPS_BY_CATEGORY_RECEIVED',
  topAppsByCategory => topAppsByCategory,
);

export const appProtectionTasksReceived = createAction 
                              
                                      
                                    
 ('PROTECTION/TASKS_RECEIVED', tasks => tasks);

export const appProtectionTasksMetadataReceived = createAction 
                                       
                               
                             
 ('PROTECTION/TASKS_METADATA_RECEIVED', tasksMetadata => tasksMetadata);

export const appProtectionInquireClicked = createAction 
                                   
                              
                            
 ('APP_PROTECTION_INQUIRE_CLICKED', (params                           ) => params);

export const appIntegrationInquireClicked = createAction 
                                    
   
     
                       
                    
         
      
    
   
                     
                  
       
    
 ('APP_INTEGRATION_INQUIRE_CLICKED', (params                                          ) => params);

export const appProtectionHideTaskClicked = createAction 
                                     
                      
                    
 ('APP_PROTECTION_HIDE_TASK_CLICKED', task => task);

export const appProtectionUnHideTaskClicked = createAction 
                                       
                      
                    
 ('APP_PROTECTION_UNHIDE_TASK_CLICKED', task => task);

export const getAppInsights = createAction                                      ('GET_APP_INSIGHTS', appId => appId);

export const getAppInsightsFindings = createAction                                               (
  'GET_APP_INSIGHTS_FINDINGS',
  appId => appId,
);

export const appInsightsReceived = createAction                                               (
  'APP_INSIGHTS_RECEIVED',
  insights => insights,
);

export const updateInsightFindings = createAction 
                            
                                   
                                 
 ('INSIGHT_FINDINGS_UPDATE', findings => findings);

export const mobileApplicationViewed = createAction                                                  (
  'mobile_apps/viewed',
  Application => Application,
);

export const UploadApiAccessPageClicked = createAction                                  (
  'UPLOAD_API_ACCESS_PAGE_CLICKED',
);

export const ResultsApiAccessPageClicked = createAction                                   (
  'RESULTS_API_ACCESS_PAGE_CLICKED',
);

export const updateFindingStarted = createAction                                                              (
  'UPDATE_FINDING_STARTED',
  finding => finding,
);

export const updateFindingSuccess = createAction                                                              (
  'UPDATE_FINDING_SUCCESS',
  finding => finding,
);

export const updateFindingError = createAction                                          (
  'UPDATE_FINDING_ERROR',
  error => error,
);

export const jiraIntegrationDisplayCardOptionsPageViewRequested = createAction 
                                                                            
 ('JIRA_INTEGRATION_CONFIGURATION_DISPLAY_CARD_OPTIONS_PAGE_VIEW_REQUESTED');

export const jiraIntegrationEditPageViewRequested = createAction 
                                              
           
         
 ('JIRA_INTEGRATION_EDIT_PAGE_VIEW_REQUESTED', appId => appId);

export const jiraIntegrationAppSpecificDashboardPageViewRequested = createAction 
                                                                
 ('JIRA_INTEGRATION_APP_SPECIFIC_DASHBOARD_PAGE_VIEW_REQUESTED');

export const jiraIntegrationFetchListConfigurations = createAction                                              (
  'JIRA_INTEGRATION_FETCH_LIST_CONFIGURATIONS',
);

                                          
                                                                        
             
     
  

export const jiraIntegrationConfigCreateFormSubmitted = createAction 
                                      
                                      
                                    
 (
  'JIRA_CONFIG_CREATE_FORM_SUBMITTED',
  (jiraIntegrationConfigAndID                                   ) => jiraIntegrationConfigAndID,
);

export const jiraIntegrationConfigPatchFormSubmitted = createAction 
                                     
                                      
                                    
 (
  'JIRA_CONFIG_PATCH_FORM_SUBMITTED',
  (jiraIntegrationConfigAndID                                   ) => jiraIntegrationConfigAndID,
);

                                           
                                    
                                       
     
  

export const jiraIntegrationReceivedListConfigurations = createAction 
                                            
                                       
                                     
 ('jira/integration/configuration-received', configurations => configurations);
