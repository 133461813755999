//      
import immer from 'immer';

                                                       
import { policyViolationsReceived } from './actions';

import CommentsReducer, { initialState as CommentsInitialState } from './comments/reducer';

                                    
                                                
                                                                  
                          
     
  

                                                           

const initialState                       = {
  patchSuccess: false,
  patchError: null,
  id: {},
  for_violated_policy_rule_id: {},
  comments: CommentsInitialState,
};

export default immer                               ((draft, action         ) => {
  const newCommentsState = CommentsReducer(draft.comments, action);

  if (newCommentsState !== draft.comments) {
    draft.comments = newCommentsState;
  }

  if (action.type === policyViolationsReceived.toString()) {
    const { payload = [] } = action;
    for (const violation of payload) {
      const { id, violated_policy_rule_id } = violation;
      draft.id[id] = violation;

      const for_policy_rule = (draft.for_violated_policy_rule_id[violated_policy_rule_id] =
        draft.for_violated_policy_rule_id[violated_policy_rule_id] || []);
      if (!for_policy_rule.includes(id)) {
        for_policy_rule.push(id);
      }
    }
  }
}, initialState);
