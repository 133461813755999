//     

const states = {
  INITIAL: 'INITIAL',
  LOADING: 'LOADING',
  RECEIVED: 'RECEIVED',
  ERROR: 'ERROR',
};

export default states;

                                                                    
