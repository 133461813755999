//     
                                                                

import { getAggregatedRelevance } from '../policy_violations/util';
import {
                                                
                                            
} from '../policy_violations/types';

                                                     

export function decorate(
  operation              ,
  restful_api            ,
  policy_violations_decorated                                          ,
)                        {
  const matching_policy_violations_decorated = (operation.policy_violation_ids || [])
    .map                                             (id => policy_violations_decorated.find(task => task.id === id))
    .filter(Boolean);

  return {
    ...operation,
    restful_api_id: restful_api.id,
    policy_violations_decorated: matching_policy_violations_decorated,
    aggregated_relevance: getAggregatedRelevance({
      policy_violations_decorated: matching_policy_violations_decorated,
    }),
  };
}
