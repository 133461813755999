//      
import { palette, fontFamilies } from './index';
import odometerCSS from './odometer-theme-default';

const globalStyle = `
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono&family=Rubik:wght@300;400;500&family=Roboto:wght@300;400;500&display=swap');

:root {
${Object.entries(palette)
  .map(([name, value]) => {
    if (typeof value !== 'string') return;
    return `--${name}: ${value};`;
  })
  .filter(Boolean)
  .join('\n')}
}
* {
  box-sizing: border-box;
}


body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: ${fontFamilies.primary};
  height: 100vh;
}

/* For @reach/router */
a {
  text-decoration: none;
}

.test {
  font-size: 15px;
}

${odometerCSS}
`;

if (typeof window !== 'undefined' && !window.__dt_globalCss) {
  window.__dt_globalCss = true;
  const tag = document.createElement('style');
  tag.setAttribute('data-global', 'true');
  tag.appendChild(document.createTextNode(globalStyle));
  if (document.head) document.head.appendChild(tag);
}
