//     
import EventTypes from './EventTypes';

const invalid = reason => ({ valid: false, reason });

export default function validate(data       ) {
  if (!data) {
    return invalid('No data');
  }

  if (!data.type) {
    return invalid('No event type');
  }

  if (
    data.type === EventTypes.SCREEN_VIEW &&
    (!data.screen_name || !data.app_name)
  ) {
    return invalid(
      `Type was ${EventTypes.SCREEN_VIEW} but this requires screen_name and app_name to be set and they were not`,
    );
  }

  if (data.type === EventTypes.LOGIN && !data.method) {
    return invalid(
      `Type was ${EventTypes.LOGIN} but this requires method to be set and it was not`,
    );
  }

  return { valid: true };
}
