//      
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                    
                   
                        
   

                                                                           

                                                 
                                                  
                   
   

                                                       
                       
     
  

export async function list()                                                  {
  return fetch(`${api}/${version}/apigee_authenticators`).then(parse);
}

                                                                     

export async function create(
  params                                         ,
)                                                    {
  return fetch(`${api}/${version}/apigee_authenticators`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
