//     
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import removeFromList from 'rezz/removeFromList';
import { apiKeysReceived, apiKeyDeleteResponseReceived } from '../actions';
                                                    

                                                 

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: ([]             ),
  equalityCheck: (a        , b        ) => a.id === b.id,
})(updateInList(apiKeysReceived.toString()), removeFromList(apiKeyDeleteResponseReceived.toString()));
