//      
export const decorate = (policy_rule            , policy_rule_types                     )                       => {
  const policy_rule_type = policy_rule_types.id[policy_rule.policy_rule_type_id];

  if (!policy_rule_type) return null;

  return {
    ...policy_rule,
    policy_rule_type: policy_rule_type,
  };
};
