//     
import React, { PureComponent } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { Paper, Divider, Button } from '@mui/material';
import { palette } from '@dt/theme';

function getStyles({ open, v2 })      {
  return {
    container: {
      marginTop: open ? 20 : 0,
      marginBottom: open ? 20 : 0,
      boxShadow: v2
        ? 'none'
        : '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: v2 ? 8 : 4,
    },
    wrapper: { display: 'flex', minHeight: 60, alignItems: 'center' },
    textContainer: { width: 180, marginLeft: 24, marginRight: 16 },
    primaryText: { color: palette.gray10 },
    secondaryText: { fontSize: 12, color: palette.gray20 },
    valueContainer: { flexGrow: 3, color: palette.gray20 },
    valueInnerContainer: { display: 'flex' },
    secondaryValue: { fontSize: 12, color: palette.gray20 },
    iconContainer: { marginRight: 24, marginLeft: 16 },
    bodyWrapper: {},
  };
}

              
                 
               
                    
                       
               
                        
                     
                  
                       
                             
                               
     
  

export default class ExpansionPanelItem extends PureComponent        {
  render() {
    const { primaryText, secondaryText, value, secondaryValue, open, v2 } = this.props;

    const styles = getStyles({ open, v2 });

    return (
      <Paper style={styles.container}>
        <div style={styles.wrapper} onClick={this.onClick}>
          <div style={styles.textContainer}>
            <div style={styles.primaryText}>{primaryText}</div>
            <div style={styles.secondaryText}>{secondaryText}</div>
          </div>
          <div style={styles.valueContainer}>
            <div style={styles.valueInnerContainer}>{value}</div>
            {secondaryValue && <div style={styles.secondaryValue}>{secondaryValue}</div>}
          </div>
          <div style={styles.iconContainer}>{this.renderIcon()}</div>
        </div>
        {this.renderBody(styles)}
        {!open && !v2 && <Divider />}
      </Paper>
    );
  }

  renderIcon = () => {
    const { open, disabled } = this.props;

    if (disabled) {
      return null;
    }

    if (open) {
      return <ExpandLess htmlColor={palette.gray20} />;
    } else {
      return <ExpandMore htmlColor={palette.gray20} />;
    }
  };

  renderBody = (styles                                ) => {
    const { children, open } = this.props;

    return (
      <div style={styles.bodyWrapper}>
        {children && <Divider />}
        <div>{children}</div>
        {open && (
          <div>
            <Divider />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              <Button color="primary" variant="text" onClick={this.onClickCancel}>
                Cancel
              </Button>
              <Button color="primary" variant="contained" onClick={this.onClickSave}>
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  onClick =    (...args                   ) => {
    this.callback(this.props.onClick, args);
  };

  onClickSave = () => {
    this.callback(this.props.onRequestSave);
  };

  onClickCancel = () => {
    this.callback(this.props.onRequestCancel);
  };

  callback =       (callback                                    , args                     = []) => {
    if (typeof callback === 'function') {
      callback.apply(this, args);
    }
  };
}
