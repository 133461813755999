//      
import { createReducerCreator, updateInList } from 'rezz';
import { certificateChainsReceived } from './actions';
                                                                              

                                                         

export default createReducerCreator                                            ({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList([certificateChainsReceived.toString()]));
