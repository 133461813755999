import React from 'react';
import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import Box from '@mui/material/Box';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-REACH-ROUTER
import { useNavigate } from '@reach/router';
import PolicyViolationExportToJiraDialogHeader from './PolicyViolationExportToJiraDialogHeader';
var PolicyViolationExportToJiraDialogStepNoIntegrations = function (_a) {
    var handleClose = _a.handleClose;
    var navigate = useNavigate();
    return (React.createElement(React.Fragment, null,
        React.createElement(PolicyViolationExportToJiraDialogHeader, { title: "No Jira integration has been configured yet.", handleClose: handleClose }),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mt: 2, mb: 2 },
                        React.createElement(Text, { variant: "body", component: "span" }, "To export a policy violation to Jira, a Jira instance must be configured first."))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: handleClose }, "Cancel"),
            React.createElement(Button, { variant: "contained", onClick: function () {
                    navigate && navigate("/devsecops/integrations/jira/configure");
                }, color: "primary" }, "Onboard Jira Instance"))));
};
export default PolicyViolationExportToJiraDialogStepNoIntegrations;
