//     
import React from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, AccordionActions } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    boxShadow: props =>
      props.isV2
        ? 'none'
        : '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '&:first-child': {
      borderTopRightRadius: props => (props.isV2 ? 8 : 4),
      borderTopLeftRadius: props => (props.isV2 ? 8 : 4),
    },
  },
});

/*
 * Do not use this directly.
 * Skeleton component of `PolicyRuleTypesCard`.
 */
const PolicyRuleTypesCardSkeletonComponent = function PolicyRuleTypesCardSkeleton({
  isV2 = false,
}   
                 
 ) {
  const css = useStyles({ isV2 });
  return (
    <Accordion className={css.root}>
      <AccordionSummary>
        <Grid container>
          {/* Left Title */}
          <Grid item xs={8}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" style={{ height: '100%' }}>
              <Skeleton variant="text" width={'100%'} height={32} style={{ marginRight: 10 }} />
            </Box>
          </Grid>

          {/* Right Stats */}
          <Grid item xs={4}>
            <Skeleton variant="text" width={'100%'} height={32} style={{ marginRight: 10 }} />
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container>
          {/* Description */}
          <Grid item xs={12}>
            <Skeleton variant="text" width={'100%'} height={40} />
          </Grid>

          {/* Compliance Standard References */}
          <Grid item xs={12}>
            <Skeleton variant="text" width={'100%'} height={40} />
          </Grid>
        </Grid>
      </AccordionDetails>

      <AccordionActions>
        <Skeleton variant="text" width={120} height={40} />
      </AccordionActions>
    </Accordion>
  );
};

export const PolicyRuleTypesCardSkeleton = PolicyRuleTypesCardSkeletonComponent;
