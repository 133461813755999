//     
                                  
import tracking from 'react-tracking';
import analytics from './analytics';
import validate from './validate';

               
                 
   

export default tracking          (
  {},
  {
    dispatch: analytics,
    process: data => {
      if (!data) {
        console.warn(
          `Not dispatching a tracking event because the passed data was falsey!`,
        );
        return null;
      }

      // Skip dispatches for components that are just setting context fields.
      // These components shouldn't dispatch on mount. An example is
      // tracking<Props, _>({ customer_name: whatever })
      // This isn't a real event, just setting context.
      const keys = Object.keys(data);
      if (keys.length === 1 && keys[0] === 'customer_name') {
        return null;
      }

      // Skip dispatches for empty tracking events...
      if (keys.length < 1) return null;

      const result = validate(data);
      if (!result.valid) {
        console.warn(
          `Not dispatching a tracking event because: ${result.reason}`,
        );
        return null;
      }

      return data;
    },
  },
)(({ children }) => children);
