//     
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { palette } from '@dt/theme';

const useStyles = makeStyles({
  CardSeeMoreSimple: {
    fontSize: 12,
    color: palette.faded,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  ExpandMore: {
    verticalAlign: 'middle !important',
  },
  text: {
    verticalAlign: 'middle',
  },
});

               
                  
                  
                 
   

export default memo       (function CardSeeMoreSimple(props) {
  const { color, children } = props;
  const classes = useStyles();

  return (
    <div className={classes.CardSeeMoreSimple}>
      <span style={{ color }}>
        <ExpandMore className={classes.ExpandMore} color="inherit" />
      </span>
      <span className={classes.text}>{children}</span>
      <span style={{ color }}>
        <ExpandMore className={classes.ExpandMore} color="inherit" />
      </span>
    </div>
  );
});
