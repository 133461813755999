//      
import {
  PolicyRuleTypeRelevance as Relevance,
} from '@dt/horizon-api';

import { policyViolationStatus } from '@dt/horizon-api';

import affectedComponentEnum from '../policy_violations/affectedComponentEnum';
                                                                     
                                                                                 
                                                               
                                                                       
                                                               
                                                                                                         

export const policy_violations = ({ policy_violations }       ) => policy_violations;

const RelevanceRankings                                                  = {
  [Relevance.URGENT]: 3,
  [Relevance.IMPORTANT]: 2,
  [Relevance.PROACTIVE]: 1,
  [Relevance.INFORMATIONAL]: 0,
};

                                     
                                                                                                                
     
  

/**
  This is the worst relevance of any UNRESOLVED violation on this asset.
 */
export function getAggregatedRelevance({
  policy_violations_decorated,
}                              )                                     {
  return policy_violations_decorated
    .filter(violation => violation.status === policyViolationStatus.OPEN)
    .map                             (({ policy_rule_decorated }) => policy_rule_decorated.relevance)
    .sort((a, b) => RelevanceRankings[a] - RelevanceRankings[b])
    .pop();
}

/**
  This is the worst relevance any violation ever had on this asset, regardless
  if it's resolved or not. This can be interesting for users to see to get a
  sense of how much progress they've made.
 */
export function getHistoricAggregatedRelevance({
  policy_violations_decorated,
}                              )                                     {
  return policy_violations_decorated
    .map                             (({ policy_rule_decorated }) => policy_rule_decorated.relevance)
    .sort((a, b) => RelevanceRankings[a] - RelevanceRankings[b])
    .pop();
}

/**
 * In order to sort all assets by their policy violation status, we need a way to
 * convert the open violations to a numeric value.
 *
 * Assets should be sorted by:
 * # of unresolved Urgent violations
 * THEN # of unresolved Important violations
 * THEN # of unresolved Proactive violations
 *
 */
const ViolationPointScores                                                  = {
  [Relevance.URGENT]: 10000, // heavily weighted to float to top
  [Relevance.IMPORTANT]: 50, // need 201 to outweigh 1 Urgent
  [Relevance.PROACTIVE]: 1, // need 51 to outweigh 1 Important
  [Relevance.INFORMATIONAL]: 0, // no weight
};
/*
 *
 *
 */
export function getUnresolvedPolicyViolationPointValue({
  policy_violations_decorated,
}                              )         {
  const unresolved = policy_violations_decorated.filter(v => !v.date_resolved);
  const score = unresolved.reduce(
    (acc, currVal) => acc + ViolationPointScores[currVal.policy_rule_decorated.relevance],
    0,
  );
  return score;
}

export function decorate(
  policy_violation                 ,
  policy_rules_decorated                         ,
)                            {
  const policy_rule_decorated = policy_rules_decorated.find(
    ({ id }) => policy_violation.violated_policy_rule_id === id,
  );

  if (!policy_rule_decorated) {
    return null;
  }

  return {
    ...policy_violation,
    policy_rule_decorated,
  };
}

/*
 * Gets the affected component description for a policy violation.
 * Used in conjunction with GQL.
 *
 * @param policy_violation - Policy violation with affected component fields. Types are used to reduce propogation of
 *                           decorator types.
 */
export const getAffectedComponentDescription = (policy_violation   
              
                               
                   
                    
         
      
       
    
                           
                      
       
    
                             
                
                         
                  
                            
       
    
                              
                  
                             
       
    
                               
                      
       
    
     
 )         => {
  // API Operation
  if (policy_violation.affected_api_operation) {
    if (!policy_violation.affected_restful_api) {
      throw new Error(
        `Error in 'getAffectedComponentDescription' for policy violation '${policy_violation.id}'. ` +
          `No affected restful api when affected api operation '${policy_violation.affected_api_operation.id}' exists.`,
      );
    }
    const { base_url } = policy_violation.affected_restful_api;
    const { http_method, path } = policy_violation.affected_api_operation;
    return `API operation at ${http_method} ${base_url}${path}`;
  }

  // Cloud Resource
  if (policy_violation.affected_cloud_resource) {
    const { url, asset_type_name } = policy_violation.affected_cloud_resource;
    return `Asset ${asset_type_name} ${url ? `at ${url}` : ''}`;
  }

  // Web Application
  if (policy_violation.affected_web_application) {
    const { base_url } = policy_violation.affected_web_application;
    return `Web Application at ${base_url}`;
  }

  // Network Service
  if (policy_violation.affected_network_service) {
    const { domain_name } = policy_violation.affected_network_service;
    return `API Domain at ${domain_name.name}`;
  }

  throw new Error(
    `Error in 'getAffectedComponentDescription' for policy violation '${policy_violation.id}'. ` +
      `No affected component?`,
  );
};

//
// Returns PolicyViolationWithAffectedComponent or null if there is no matching
// task type
// NOTE: Do not decorate this with types that are themselves decorated with tasks
export function decorateWithAffectedComponent(
  policy_violation                          ,
  restful_apis                  ,
  api_operations                    ,
  network_services                     ,
  cloud_resources                     ,
  web_applications                     ,
  graphql_apis                 ,
  domain_names                 ,
  certificate_chains                               ,
)                                        {
  let affected_component                       , affected_component_description        ;
  let affected_component_id        ;

  if (policy_violation.affected_restful_api_id) {
    const restful_api_id = policy_violation.affected_restful_api_id;

    if (!restful_api_id || !restful_apis.id[restful_api_id]) {
      return null; // Corresponding restful api was not in state?! (Bad backend response || reducer bug)
    }
    const { base_url } = restful_apis.id[restful_api_id];

    affected_component = affectedComponentEnum.RESTFUL_API;
    affected_component_description = `API at ${base_url}`;
    affected_component_id = restful_api_id;
  }

  if (policy_violation.affected_api_operation_id) {
    const api_operation_id = api_operations.for_policy_violation_id[policy_violation.id];
    if (!api_operation_id) {
      return null; // Corresponding restful api operation was not in state?! (Bad backend response || reducer bug)
    }
    const api_operation = api_operations.id[api_operation_id];
    if (!api_operation) {
      return null; // Corresponding restful api operation was not in state?! (Bad backend response || reducer bug)
    }

    const restful_api_id = api_operation.restful_api_id;

    if (!restful_api_id || !restful_apis.id[restful_api_id]) {
      return null; // Corresponding restful api was not in state?! (Bad backend response || reducer bug)
    }
    const { base_url } = restful_apis.id[restful_api_id];

    affected_component = affectedComponentEnum.RESTFUL_API;
    affected_component_description = `API operation at ${api_operation.http_method} ${base_url}${api_operation.path}`;
    affected_component_id = restful_api_id;
  }

  if (policy_violation.affected_network_service_id) {
    const network_service_id = network_services.for_policy_violation_id[policy_violation.id];
    if (!network_service_id || !network_services.id[network_service_id]) {
      return null; // Corresponding network service was not in state?! (Bad backend response || reducer bug)
    }
    const { id, domain_name_id } = network_services.id[network_service_id];
    const domain_name = domain_names.id[domain_name_id];
    if (!domain_name) {
      return null; // Corresponding domain name was not in state?! (Bad backend response || reducer bug)
    }
    affected_component = affectedComponentEnum.NETWORK_SERVICE;
    affected_component_description = `API Domain at ${domain_name.name}`;
    affected_component_id = id;
  }

  if (policy_violation.affected_cloud_resource_id) {
    const cloud_resource_id = cloud_resources.for_policy_violation_id[policy_violation.id];
    if (!cloud_resource_id || !cloud_resources.id[cloud_resource_id]) {
      return null; // Corresponding cloud resource was not in state?! (Bad backend response || reducer bug)
    }
    const { id, url, asset_type_name } = cloud_resources.id[cloud_resource_id];
    affected_component = affectedComponentEnum.CLOUD_RESOURCE;
    affected_component_description = `Asset ${asset_type_name} ${url ? `at ${url}` : ''}`;
    affected_component_id = id;
  }

  if (policy_violation.affected_web_application_id) {
    const web_application_id = web_applications.for_policy_violation_id[policy_violation.id];
    if (!web_application_id || !web_applications.id[web_application_id]) {
      return null; // Corresponding web application was not in state?! (Bad backend response || reducer bug)
    }
    const { id, base_url } = web_applications.id[web_application_id];
    affected_component = affectedComponentEnum.WEB_APPLICATION;
    affected_component_description = `Web Application at ${base_url}`;
    affected_component_id = id;
  }

  if (policy_violation.affected_graphql_api_id) {
    const graphql_api_id = graphql_apis.for_policy_violation_id[policy_violation.id];
    if (!graphql_api_id || !graphql_apis.id[graphql_api_id]) {
      return null; // Corresponding graphql api was not in state?! (Bad backend response || reducer bug)
    }
    const { id, url } = graphql_apis.id[graphql_api_id];
    affected_component = affectedComponentEnum.GRAPHQL_API;
    affected_component_description = `GraphQL API at ${url}`;
    affected_component_id = id;
  }

  // No affected_* field?!
  if (!affected_component || !affected_component_description || !affected_component_id) {
    return null;
  }

  let opened_by_certificate_chain_decorated;
  if (policy_violation.opened_by_certificate_chain_id) {
    opened_by_certificate_chain_decorated = certificate_chains.find(
      cert => cert.id === policy_violation.opened_by_certificate_chain_id,
    );
  }

  return {
    ...policy_violation,
    affected_component,
    affected_component_id,
    affected_component_description,
    opened_by_certificate_chain_decorated,
  };
}

export function decorateForEvent(
  policy_violation                          ,
  users           ,
)                           {
  let exception_added_by_user;
  if (policy_violation.exception_added_by_user_id) {
    exception_added_by_user = users.find(u => u.id === policy_violation.exception_added_by_user_id);
  }

  return {
    ...policy_violation,
    exception_added_by_user,
  };
}

const RelevanceSortOrder = {
  URGENT: 100,
  IMPORTANT: 50,
  PROACTIVE: 25,
  INFORMATIONAL: 10,
};

const StatusSortOrder = {
  [policyViolationStatus.DELETED]: 5,
  [policyViolationStatus.RESOLVED]: 10,
  [policyViolationStatus.WONT_FIX]: 25,
  [policyViolationStatus.OPEN]: 50,
};

                           
                                 
                                                                           
     
  

export function unresolvedRelevanceOrder(a                    , b                    ) {
  if (a.status === b.status) {
    return (
      RelevanceSortOrder[b.policy_rule_decorated.relevance] - RelevanceSortOrder[a.policy_rule_decorated.relevance]
    );
  }

  return StatusSortOrder[b.status] - StatusSortOrder[a.status];
}

export function affectedComponentDescription({
  affected_api_operation,
  affected_cloud_resource,
  affected_network_service,
  affected_web_application,
}   
                                 
     
 )         {
  if (affected_api_operation) {
    return `API operation at ${affected_api_operation.http_method} ${affected_api_operation.restful_api.base_url}${affected_api_operation.path}`;
  } else if (affected_cloud_resource) {
    return `Asset ${affected_cloud_resource.asset_type_name} ${
      affected_cloud_resource.url ? `at ${affected_cloud_resource.url}` : ''
    }`;
  } else if (affected_network_service) {
    return `API Domain at ${affected_network_service.domain_name.name}`;
  } else if (affected_web_application) {
    return `Web Application at ${affected_web_application.base_url}`;
  }
  // TODO: Should we throw here? Sentry?
  return `Unknown Asset Affected`;
}
