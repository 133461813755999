//     
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { byProperty, result } from './util';

                                

                                
                   
                  
               
                 
     
  

export const finance = {
  create: async (params                  )                      => {
    if (
      !params ||
      !params.filename ||
      !params.purpose ||
      !params.size ||
      !params.origin
    ) {
      throw new Error('Invalid params supplied to create upload request');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/uploads/finance`, {
      method: 'POST',
      body: JSON.stringify(params),
    })
      .then(parse)
      .then(result)
      .then(byProperty('upload_url'));
  },
};
