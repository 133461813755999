//     
import immer from 'immer';

                                                
                                               

import { ipRangesReceived } from './actions';

                              
                                         
   

                                                   

const initialState = {
  id: {},
};

export default immer                        ((draft, action         ) => {
  if (action.type === ipRangesReceived.toString()) {
    const { ip_ranges } = action.payload;

    for (const ip_range of ip_ranges) {
      draft.id[ip_range.id] = ip_range;
    }
  }
}, initialState);
