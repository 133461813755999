//      
import React from 'react';
import { Grid, Card, CardContent, Avatar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SecurityIcon from '@mui/icons-material/Security';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { palette } from '@dt/theme';

              
                                  
                 
                
                                  
  

const useStyles = makeStyles({
  card: {
    height: '100%',
  },
  cardContent: {
    padding: 8,
    '&:last-child': {
      paddingBottom: 8,
    },
  },
  color: props => ({
    color: props.color === 'green' ? palette.green30 : props.color === 'red' ? palette.red30 : palette.gray30,
  }),
  background: props => ({
    background: props.color === 'green' ? palette.green50 : props.color === 'red' ? palette.red50 : palette.gray50,
  }),
});

function CloudLeaksStats({ variant, label, number, color }       ) {
  const classes = useStyles({ color });

  return (
    <Card classes={{ root: classes.card }}>
      <CardContent classes={{ root: classes.cardContent }}>
        <Grid container spacing={1}>
          <Grid item>
            <Avatar variant="circular" classes={{ root: classes.background }}>
              {variant === 'security' ? <SecurityIcon fontSize="small" classes={{ root: classes.color }} /> : null}
              {variant === 'warning' ? <ReportProblemIcon fontSize="small" classes={{ root: classes.color }} /> : null}
            </Avatar>
          </Grid>
          <Grid item xs={9}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" color="textPrimary">
                  {label}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="baseline">
                  <Typography
                    display="inline"
                    variant="body1"
                    classes={{ root: classes.color }}
                    aria-label={`${number}-${label}`}
                  >
                    {number}
                  </Typography>
                  <Typography display="inline" variant="body2">
                    &nbsp;from last scan
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default React.memo       (CloudLeaksStats);
