//      
import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

              
                 
                                      
                
     
  
const FilterToggle = ({ value, onChange, label }       ) => (
  <FormControlLabel
    control={
      <Switch
        checked={value}
        color="primary"
        onChange={e => {
          const value = Boolean(e.target.checked);
          onChange(value);
        }}
      />
    }
    label={label}
  />
);

export default FilterToggle;
