//     
import React, { useCallback, useState } from 'react';
import {
  Chip,
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
} from '@mui/material';
import Box from '@mui/material/Box';
import gql from 'graphql-tag';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import ComplianceTagStrip from '@dt/material-components/compliance-tag/ComplianceTagStrip';
import { RelevanceTag, Markdown } from '@dt/material-components';
import { ExtLink } from '@dt/material-components';
import { Message } from '@components';
import policy_rules from '@dt/graphql-support/horizon/policy_rules';
import { useTogglePolicyRule } from './use_toggle_policy_rule';
import { PolicyRuleTypesCardEditRelevanceButton } from './PolicyRuleTypesCardEditRelevanceButton';
             
                          
                          
                            
             
                    
                                  
                    
                                   
import PolicyRuleImportanceTag from './PolicyRuleImportanceTag';
import PolicyRuleTypesCardRelativeComponents from './PolicyRuleTypesCardRelativeComponents';
import { LEAKY_APIS_POLICY_RULE_TYPE_ID } from '../../redux/policy_rule_types/util';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

// Some policy rules can only be configured through another page.
// prettier-ignore
const POLICY_RULE_TYPE_EXTERNAL_CONFIGURATION_LOOKUP = {
  '92eb7e9e-9daa-5922-8167-6a219e0f7287': '/api/inspect/detect-and-inject', // SQL Injection
  [LEAKY_APIS_POLICY_RULE_TYPE_ID]: '/api/inspect/leaky-apis',        // API Publicly Accessible
  '007ceb60-b98c-5a19-97f7-31f3f6526816': '/api/inspect/hack-and-extract'   // API PII Exposed
};

                      
                
                 
                      
                     
                      
               
                  
                        
                
                                                                   
                                                          
                                                
                                                
                                                           
                    
                 
                                              
                                           
                                         
         
      
       
    
   

const useStyles = makeStyles({
  root: {
    boxShadow: props =>
      props.isV2
        ? 'none'
        : '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    '&:first-child': {
      borderTopRightRadius: props => (props.isV2 ? 8 : 4),
      borderTopLeftRadius: props => (props.isV2 ? 8 : 4),
    },
  },
});

/*
 * Do not use this directly.
 * Implemntation component of `PolicyRuleTypesCard`.
 */
const PolicyRuleTypesCardImplementationComponent = function PolicyRuleTypesCardImplementation({
  classes,
  policy_id,
  policy_rule_type,
  isV2 = false,
  currentTab,
}       ) {
  const css = useStyles({ isV2 });
  const [updatePolicyRule, { error: policyRulesUpdateError, loading: policyRulesUpdateLoading }] = useMutation 
               
                                    
   (policy_rules.update, {
    update: (cache, result) => {
      // Skip if there are errors.
      if (!result || result.errors) {
        return;
      }

      const new_policy_rule = result.data.policy_rules_update.policy_rules[0];
      // Should never happen since the backend always provides this.
      if (!new_policy_rule) {
        return;
      }

      // Should never happen since we're modifying an existing policy rule here.
      if (!policy_rule_type.policy_rule) {
        return;
      }

      // Update corresponding policy rule relevance.
      cache.modify({
        fields: {
          policies_policy_rule_types_list(existing_policy_rule_types_list, { readField }) {
            for (const existing_policy_rule_type of existing_policy_rule_types_list.policy_rule_types) {
              const existing_policy_rule_type_id = readField('id', existing_policy_rule_type);
              if (policy_rule_type.id === existing_policy_rule_type_id) {
                cache.modify({
                  id: cache.identify(existing_policy_rule_type),
                  fields: {
                    policy_rule(existing_policy_rule_type_policy_rule) {
                      // Create updated cache "reference".
                      return cache.writeFragment({
                        id: cache.identify(existing_policy_rule_type_policy_rule),
                        fragment: gql`
                          fragment PolicyRuleTypeV2PolicyRuleUpdate on PolicyRuleTypeV2PolicyRule {
                            relevance
                          }
                        `,
                        data: { relevance: new_policy_rule.relevance },
                      });
                    },
                  },
                });
              }
            }

            return existing_policy_rule_types_list;
          },
        },
      });

      // Cleanup cache.
      cache.gc();
    },
  });

  const [
    togglePolicyRule,
    { policyRulesCreateError, policyRulesRemoveError, policyRulesCreateLoading, policyRulesRemoveLoading },
  ] = useTogglePolicyRule();

  // Control the accordian manually.
  // NOTE: This is a *huge* performance improvement. Please do not switch to uncontrolled.
  //       It does make the animation a bit funny when closing the accordian but this
  //       can be fixed by measuring and maintaining the height of the accordian content.
  const [isExpanded, setIsExpanded] = useState(false);
  // Keep track of accordian content & details to manually control the height for expansion.
  // This helps keep the accordian animation smoother when collapsing.
  // The need for this stems from the performance improvement of using `isExpanded` manually.
  const [accordianDetailsHeight, setAccordianDetailsHeight] = useState               (null);
  const [accordianActionsHeight, setAccordianActionsHeight] = useState               (null);

  const error = policyRulesCreateError || policyRulesRemoveError || policyRulesUpdateError;

  const handleTogglePolicyRuleButton = useCallback(
    event => {
      event.preventDefault();

      // Toggle policy rule on/off for policy rule type.
      togglePolicyRule(policy_id, policy_rule_type);
    },
    [policy_id, policy_rule_type, togglePolicyRule],
  );

  // Event handlers.
  const handleEditRelevanceItemClicked = useCallback(
    relevance => {
      // Should never happen but protect against undefined errors anyways.
      if (!policy_rule_type.policy_rule) return;

      // Update the policy rule's relevance to the relevance selected.
      updatePolicyRule({
        variables: {
          id: policy_id,
          policy_rule_id: policy_rule_type.policy_rule.id,
          body: { relevance },
        },
      });
    },
    [policy_id, policy_rule_type, updatePolicyRule],
  );

  return (
    <Accordion className={css.root} expanded={isExpanded} aria-label={`${policy_rule_type.title} Card`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setIsExpanded(isExpanded => !isExpanded)}>
        <Grid container>
          {/* Left Title */}
          <Grid item xs={8}>
            <Grid container spacing={1}>
              <Grid item>
                <Typography variant="subtitle1" className={classes.title}>
                  {policy_rule_type.title}
                </Typography>
              </Grid>
              <Grid item>
                <PolicyRuleImportanceTag items={policy_rule_type.importance_tags} />
              </Grid>
            </Grid>
          </Grid>

          {/* Right Stats */}
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" justifyContent="flex-end" style={{ height: '100%', gap: '6px' }}>
              {policy_rule_type.included_in_sast_rule_packs && policy_rule_type.group !== 'MOBILE' && (
                <>
                  {currentTab === 'sast' ? (
                    <>
                      {policy_rule_type.included_in_sast_rule_packs.map((rulepack, index) => {
                        let backgroundColor = palette.gray45;
                        switch (rulepack.color) {
                          case 'GREY': {
                            backgroundColor = palette.gray45;
                            break;
                          }
                          case 'ORANGE': {
                            backgroundColor = palette.orange50;
                            break;
                          }
                        }
                        return (
                          <Chip
                            label={rulepack.title}
                            size="small"
                            aria-label={rulepack.title}
                            style={{ backgroundColor, borderRadius: '4px' }}
                            key={index}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {policy_rule_type.policy_rule && (
                        <Chip
                          label={`${policy_rule_type.policy_rule.open_policy_violations_count} Open Violations`}
                          size="small"
                          aria-label={`${policy_rule_type.policy_rule.open_policy_violations_count} Open Violations`}
                        />
                      )}
                    </>
                  )}
                </>
              )}

              <span className={classes.relevance}>
                {policy_rule_type.policy_rule ? (
                  <RelevanceTag relevance={policy_rule_type.policy_rule.relevance} />
                ) : (
                  <Typography variant="caption">Rule Deactivated</Typography>
                )}
              </span>
            </Box>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        {isExpanded ? (
          <Grid
            container
            ref={ref => {
              if (!ref) return;
              // $FlowFixMe - Grid forwards ref.
              setAccordianDetailsHeight(ref.getBoundingClientRect().height);
            }}
          >
            {/* Description */}
            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom component="div">
                <Markdown inline text={policy_rule_type.description} />
              </Typography>
            </Grid>

            {/* Compliance Standard References */}
            <Grid item xs={12}>
              {policy_rule_type.compliance_policy_references.length > 0 && (
                <div className={classes.complianceStandardReferences}>
                  <Typography variant="body2" gutterBottom>
                    Compliance Standard References
                  </Typography>

                  <ComplianceTagStrip granular complianceReferences={policy_rule_type.compliance_policy_references} />
                </div>
              )}
            </Grid>

            {/* Policy rule type card relative components */}
            <PolicyRuleTypesCardRelativeComponents policy_rule_type_id={policy_rule_type.id} />

            {/* Error Message */}
            {error && <Message variant="error" message={error.message} />}
          </Grid>
        ) : (
          <div style={{ display: 'block', height: accordianDetailsHeight }} />
        )}
      </AccordionDetails>

      <AccordionActions>
        {isExpanded ? (
          <Grid
            container
            ref={ref => {
              if (!ref) return;
              // $FlowFixMe - Grid forwards ref.
              setAccordianActionsHeight(ref.getBoundingClientRect().height);
            }}
          >
            <Grid item xs={12} container justifyContent="flex-end">
              {/* Edit Configuration */}
              {/* Is this policy rule configurable? */}
              {POLICY_RULE_TYPE_EXTERNAL_CONFIGURATION_LOOKUP[policy_rule_type.id] && (
                <ExtLink to={POLICY_RULE_TYPE_EXTERNAL_CONFIGURATION_LOOKUP[policy_rule_type.id]} target="_self">
                  <Button size="small" color="primary">
                    Edit Configuration
                  </Button>
                </ExtLink>
              )}

              {/* Activate | Deactivate */}
              <Button
                size="small"
                color="primary"
                aria-label={
                  policy_rule_type.policy_rule
                    ? `Deactivate ${policy_rule_type.title}`
                    : `Activate ${policy_rule_type.title}`
                }
                variant={policy_rule_type.policy_rule ? 'text' : 'contained'}
                onClick={handleTogglePolicyRuleButton}
                disabled={policyRulesCreateLoading || policyRulesRemoveLoading || policyRulesUpdateLoading}
                endIcon={
                  (policyRulesCreateLoading || policyRulesRemoveLoading) && (
                    <CircularProgress size={15} aria-label={`Activating ${policy_rule_type.title}`} />
                  )
                }
              >
                {policy_rule_type.policy_rule ? 'Deactivate Rule' : 'Activate rule'}
              </Button>

              {/* Edit Relevance/Severity */}
              {/* Is this policy rule relevance editable? */}
              {policy_rule_type.policy_rule && (
                <PolicyRuleTypesCardEditRelevanceButton
                  loading={policyRulesUpdateLoading}
                  disabled={policyRulesRemoveLoading}
                  policy_rule_relevance={policy_rule_type.policy_rule.relevance}
                  policy_rule_type_title={policy_rule_type.title}
                  policy_rule_type_default_relevance={policy_rule_type.default_relevance}
                  onEditRelevanceClick={handleEditRelevanceItemClicked}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <div style={{ display: 'block', height: accordianActionsHeight }} />
        )}
      </AccordionActions>
    </Accordion>
  );
};
export const PolicyRuleTypesCardImplementation = PolicyRuleTypesCardImplementationComponent;
