//      
import React, { memo } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { PolicyViolationStatusEnumValues } from '@dt/graphql-support/enums';
import Label from './Label';

const useStyle = makeStyles({
  label: props => ({
    backgroundColor: props?.backgroundColor || palette.gray45,
    color: props?.color || palette.gray10,
    padding: '4px 8px',
    fontSize: '13px',
    fontWeight: 'bold',
  }),
});

              
                                    
  

const variants = {
  [PolicyViolationStatusEnumValues.WONT_FIX]: {
    color: palette.gray10,
    backgroundColor: palette.gray45,
    label: 'CLOSED',
  },
  [PolicyViolationStatusEnumValues.OPEN]: {
    color: palette.red10,
    backgroundColor: palette.red50,
    label: 'OPEN',
  },

  [PolicyViolationStatusEnumValues.RESOLVED]: {
    color: palette.green10,
    backgroundColor: palette.green50,
    label: 'RESOLVED',
  },
  [PolicyViolationStatusEnumValues.DELETED]: {
    color: palette.gray10,
    backgroundColor: palette.gray45,
    label: 'DELETED',
  },
};

function PolicyViolationLabel({ status }       ) {
  const classes = useStyle({
    backgroundColor: variants[status].backgroundColor,
    color: variants[status].color,
  });

  return (
    <Label classes={classes.label}>
      <Grid container alignItems={'center'} alignContent={'center'} spacing={1}>
        <Grid item>{variants[status].label}</Grid>
      </Grid>
    </Label>
  );
}

export default memo       (PolicyViolationLabel);
