import React, { createContext, useState } from 'react';
import { Grid } from '@mui/material';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { CenteredCircularProgress } from '@dt/material-components';
// @ts-ignore Ignore JS Files
import { UnauthorizedError } from '../../../components/errors';
// @ts-ignore Ignore JS Files
import { useAuthorization, useSession } from '../../../session';
import ApiShadowAssetsPageChartContainer from './ApiShadowAssetsPageChartContainer';
import ApiShadowAssetsPageTable from './ApiShadowAssetsPageTable';
export var ShadowAssetsContext = createContext({
    bulkId: '',
    filter_by_named_filter: '',
    isPolling: false,
    latestRefetchDate: new Date(),
    setBulkId: function (a) { },
    setLatestRefetchDate: function (a) { },
    setIsPolling: function (a) { }, // eslint-disable-line
});
var ApiShadowAssetsPage = function (_a) {
    var filter_by_named_filter = _a.filter_by_named_filter;
    var _b = useState(''), bulkId = _b[0], setBulkId = _b[1];
    var _c = useState(false), isPolling = _c[0], setIsPolling = _c[1];
    var _d = useState(new Date()), latestRefetchDate = _d[0], setLatestRefetchDate = _d[1];
    var _e = useSession(), sessionLoading = _e.loading, sessionData = _e.data;
    var isAuthorized = useAuthorization(sessionData, ['can_access_api_inspect']).isAuthorized;
    // Loading State.
    if (sessionLoading) {
        return React.createElement(CenteredCircularProgress, null);
    }
    // Unauthorized State.
    if (!isAuthorized) {
        return React.createElement(UnauthorizedError, null);
    }
    return (React.createElement(ShadowAssetsContext.Provider, { value: {
            bulkId: bulkId,
            isPolling: isPolling,
            latestRefetchDate: latestRefetchDate,
            filter_by_named_filter: filter_by_named_filter,
            setBulkId: setBulkId,
            setIsPolling: setIsPolling,
            setLatestRefetchDate: setLatestRefetchDate,
        } },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(ApiShadowAssetsPageChartContainer, null)),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(ApiShadowAssetsPageTable, { filter_by_named_filter: filter_by_named_filter })))));
};
export default ApiShadowAssetsPage;
