//      

                                                                  
import { createAction } from 'redux-actions';

export const hackExtractMounted = createAction                                                     (
  'hack_extract/mounted',
  () => ({ key: 'hack_extract' }),
);

export const hackExtractUnmounted = createAction                          ('unmounted/hack_extract');

export const hackExtractStartClicked = createAction 
                               
                               
                                                  
 ('hack_extract/start/clicked', configuration => ({ configuration }));

export const hackExtractShowResultsClicked = createAction                                     (
  'hack_extract/show-results/clicked',
);

export const hackExtractHideResultsClicked = createAction                                     (
  'hack_extract/hide-results/clicked',
);

export const hackExtractForbidden = createAction                                       (
  'hack_extract/show-results/forbidden',
);
