import React, { useContext, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@apollo/client';
import { Message } from '@components';
import Text from '@dt/ahura/src/components/Text';
import { get_status } from '@dt/graphql-support/horizon/ts/configurations_shadow';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
import { ShadowAssetsContext } from './ApiSecureShadowAssetsPage';
var useStyles = makeStyles({
    container: {
        borderRadius: 4,
        padding: 4,
        backgroundColor: palette.blue50,
        color: palette.blue10,
    },
});
var ApiShadowAssetsPageChartLoadingContainer = function () {
    var css = useStyles();
    var _a = useContext(ShadowAssetsContext), bulkId = _a.bulkId, setBulkId = _a.setBulkId, setIsPolling = _a.setIsPolling, setLatestRefetchDate = _a.setLatestRefetchDate;
    var _b = useQuery(get_status, {
        skip: !bulkId,
        notifyOnNetworkStatusChange: true,
        pollInterval: 5000,
        variables: {
            id: bulkId,
        },
    }), data = _b.data, loading = _b.loading, error = _b.error;
    useEffect(function () {
        if (loading) {
            setIsPolling(loading);
        }
    }, [loading, setIsPolling]);
    useEffect(function () {
        var results = data === null || data === void 0 ? void 0 : data.shadow_assets_check_status;
        var isCompleted = (results === null || results === void 0 ? void 0 : results.status) === 'COMPLETED';
        if (isCompleted) {
            setIsPolling(false);
            setBulkId('');
            setLatestRefetchDate(new Date());
        }
    }, [data, setBulkId, setLatestRefetchDate, setIsPolling]);
    if (loading) {
        return React.createElement(Skeleton, { height: 50, width: '100%' });
    }
    if (error) {
        return React.createElement(Message, { variant: 'error', message: error.message, m: 0 });
    }
    if (!bulkId) {
        return null;
    }
    var results = data === null || data === void 0 ? void 0 : data.shadow_assets_check_status;
    return (React.createElement(Grid, { item: true, xs: 12 },
        React.createElement("div", { className: css.container, style: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: 8,
            } },
            React.createElement(CircularProgress, { size: 16, style: { marginRight: 8 } }),
            React.createElement(Text, { variant: "body", component: "div" }, "Scan in progress")),
        React.createElement("div", { className: css.container },
            React.createElement(Text, { variant: "body", component: "div" }, results === null || results === void 0 ? void 0 :
                results.completed_tasks_count,
                " out of ", results === null || results === void 0 ? void 0 :
                results.total_tasks_count,
                " completed..."))));
};
export default ApiShadowAssetsPageChartLoadingContainer;
