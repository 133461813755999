import React, { useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from '@reach/router';
var LAUNCHED_AREAS = ['api', 'mobile-secure', 'web', 'cloud'];
var useStyles = makeStyles({
    actions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        columnGap: '4px',
        marginBlockStart: '8px',
        marginInline: '-8px',
    },
});
var V2Banner = function () {
    var _a;
    var _b = useState(true), open = _b[0], setOpen = _b[1];
    var pathname = useLocation().pathname;
    var classes = useStyles();
    var start = (_a = pathname.split('/').filter(Boolean).shift()) !== null && _a !== void 0 ? _a : '';
    if (pathname.includes('v2') || !LAUNCHED_AREAS.includes(start) || (pathname === null || pathname === void 0 ? void 0 : pathname.includes('/share')))
        return null;
    var redirectLink;
    switch (start) {
        case 'api':
            redirectLink = '/api/v2';
            break;
        case 'web':
            redirectLink = '/web/v2';
            break;
        case 'cloud':
            redirectLink = '/cloud/v2';
            break;
        case 'mobile':
        case 'mobile-secure':
            redirectLink = '/mobile-secure/v2';
    }
    var handleClose = function () {
        setOpen(false);
    };
    return (React.createElement(Snackbar, { open: open, onClose: handleClose, anchorOrigin: { vertical: 'top', horizontal: 'center' } },
        React.createElement(Alert, { onClose: handleClose, severity: "info", action: React.createElement("div", { style: { display: 'none' } }) },
            React.createElement(AlertTitle, null, "The Data Theorem AppSec portal has been updated"),
            "Select \u201CAwesome\u201D to be redirected now, or select \u201CWait\u201D to stay on the previous version.",
            React.createElement("div", { className: classes.actions },
                React.createElement(Button, { color: "primary", onClick: handleClose }, "Wait"),
                React.createElement(Button, { color: "primary", component: Link, to: redirectLink }, "Awesome")))));
};
export default V2Banner;
