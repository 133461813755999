//      
import React from 'react';
import { Link } from '@reach/router';
import format from 'date-fns/format';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Text, PolicyViolationLabel, RelevanceTag } from '@dt/material-components';
import ComplianceTagStrip from '@dt/material-components/compliance-tag/ComplianceTagStrip';
             
                            
                          
                            
                                   

            
     
                      
     
     
                        
                                                                     
                  
                     
                                         
                                          
                             
                                     
                          
                                    
                                         
                                                                               
                     
      

function PolicyViolationCardV2(props       ) {
  if (props.isLoading) {
    return (
      <Card>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={'80%'} height={22} />
              </Grid>
              <Grid item xs={6} container justifyContent={'flex-end'}>
                <Skeleton variant="rectangular" width={'20%'} height={22} />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={6}>
                <Skeleton variant="rectangular" width={'60%'} height={22} />
              </Grid>
              <Grid item xs={6} container justifyContent={'flex-end'}>
                <Skeleton variant="rectangular" width={'30%'} height={22} />
              </Grid>
            </Grid>
            <Grid item xs={12} spacing={1}>
              <Skeleton variant="rectangular" width={'20%'} height={22} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  const {
    productBasePath,
    id,
    relevance,
    title,
    status,
    date_resolved,
    exception_date_created,
    date_created,
    affected_asset_name,
    affected_asset_type_name,
    compliance_policy_references,
    isV2,
  } = props;

  return (
    <Card>
      <CardContent>
        <Link
          to={isV2 ? `${productBasePath}/v2/policy-violations/${id}` : `${productBasePath}/policy-violations/${id}`}
          style={{ display: 'block' }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <RelevanceTag relevance={relevance} size="medium" />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" component="div">
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} alignItems="center" justifyContent={'flex-end'}>
                    <Grid item>
                      <Text variant="code" component="div">
                        {`ID: ${id.substr(0, 8)}`}
                      </Text>
                    </Grid>
                    <Grid item>
                      <PolicyViolationLabel status={status} />
                    </Grid>
                    <Box component={Grid} item xs={12} display={{ xs: 'block', md: 'none', lg: 'none' }}>
                      <Typography variant="body2" component="div">
                        {date_resolved
                          ? `Resolved ${format(date_resolved)}`
                          : exception_date_created
                          ? `Closed ${format(date_created)}`
                          : `Caught ${format(date_created)}`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="body2" component="div">
                        {affected_asset_type_name && affected_asset_name
                          ? `${affected_asset_type_name} at ${affected_asset_name}`
                          : 'Unknown Asset Affected'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} alignItems="center" justifyContent={'flex-end'}>
                    <Grid item>
                      <Typography variant="body2" component="div">
                        {date_resolved
                          ? `Resolved ${format(date_resolved)}`
                          : exception_date_created
                          ? `Closed ${format(date_created)}`
                          : `Caught ${format(date_created)}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  {compliance_policy_references && (
                    <ComplianceTagStrip granular complianceReferences={compliance_policy_references} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </CardContent>
    </Card>
  );
}

export default React.memo       (PolicyViolationCardV2);
