//     
function gate(type                                       , authOrigin        ) {
  let iframe;
  const cleanup = () => iframe && iframe.remove();

  return new Promise               ((resolve, reject) => {
    try {
      // We're gonna make an iframe that will send us a message.
      // First, let's listen for that message.
      window.addEventListener(
        'message',
        function onMessage(e              ) {
          if (
            e.origin === authOrigin &&
            e.data &&
            typeof e.data === 'object' &&
            e.data.t === 'SESSION' &&
            (typeof e.data.session === 'string' ||
              typeof e.data.session === 'undefined')
          ) {
            resolve(e.data.session);
            cleanup();
            window.removeEventListener('message', onMessage, false);
          }
        },
        false,
      );

      // Make the iframe. It must:
      // 1. Be hidden from view
      // 2. Be pointing at the gate URL
      // 3. Be sent a specially-crafted message on load
      // 4. If type is REQUEST_SIGN_OUT then resolve the promise
      // If type if REQUEST_COOKIE then the resolution will happen when
      // the message is received.
      iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.addEventListener('load', () => {
        iframe.contentWindow.postMessage({ t: type }, authOrigin);
        if (type === 'REQUEST_SIGN_OUT') {
          // This setTimeout exists to make sure the postMessage finishes
          //processing before we cleanup the iframe
          setTimeout(() => {
            resolve();
            cleanup();
          }, 0);
        }
      });
      iframe.setAttribute('src', `${authOrigin}/auth/gate`);

      // Finally, kick things off by inserting it into the document.
      if (!document.body) {
        reject(new Error('No document body found'));
        cleanup();
        return;
      }
      document.body.appendChild(iframe);
    } catch (e) {
      reject(e);
      cleanup();
    }
  });
}

export function getGateSession(authHost        ) {
  return gate('REQUEST_COOKIE', authHost);
}

export function clearGateSession(authHost        ) {
  return gate('REQUEST_SIGN_OUT', authHost);
}
