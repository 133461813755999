//     
const findingsBadgeType = {
  P1: 'P1',
  COMPLIANCE: 'COMPLIANCE',
  INSIGHT: 'INSIGHT',
  BLOCKER: 'BLOCKER',
};

export default findingsBadgeType;

                                                                    
