//     
import React, { memo } from 'react';
import DownArrow from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { Fab } from '@mui/material';

const useStyles = makeStyles({
  ViewMoreButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 1,
    padding: 5,
    backgroundColor: palette.accent,
  },

  Fab: {
    marginLeft: '10px !important',
    color: palette.white,
  },
});

               
                                 
                
                
   

export default memo       (function ViewMoreButton(props) {
  const { onRequestViewMore, label, count } = props;
  const classes = useStyles();

  return (
    <div className={classes.ViewMoreButton} onClick={onRequestViewMore}>
      <span>
        View {count} more {label}
      </span>
      <Fab size="small" color="primary" className={classes.Fab}>
        <DownArrow />
      </Fab>
    </div>
  );
});
