//      
// import { put, takeEvery } from 'redux-saga/effects';
// import { Raven } from '@dt/global';
// import { callPromise } from '@dt/redux-saga-wrapped-effects';
// import { appFileSubmitted, appFileRejected, appFileAccepted } from './actions';
// import app_uploads from '@dt/user-api/app_uploads';

// import { MobileAppReleaseTypeValues } from '@dt/graphql-support/enums';
                                                                      

export default function* appsFlow()             {
  // yield spawn(watchForFileSubmitted);
}

// function* watchForFileSubmitted(): Saga<void> {
//   yield takeEvery(appFileSubmitted.toString(), function*(action: {
//     payload: {
//       file: File,
//       username: string,
//       password: string,
//       comments: string,
//       isEnterpriseInternal: boolean,
//       linkedAppStoreAppId: ?string,
//       ...
//     },
//     ...
//   }): Saga<void> {
//     const { file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId } = action.payload;
//     yield* uploadFile(file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId);
//   });
// }

                                  
                    
                      
               
                   
                     
                 
                                     
   

// function* uploadFile(
//   file: File,
//   username: ?string,
//   password: ?string,
//   comments: ?string,
//   isEnterpriseInternal: ?boolean,
//   linkedAppStoreAppId: ?string,
// ): Saga<void> {
//   try {
//     const upload_url = yield* callPromise(app_uploads.create);

//     if (typeof upload_url !== 'string') {
//       throw new Error('POST file upload did not return a response');
//     }

//     const form = new FormData();
//     form.append('file', file);
//     form.append('source', 'DT_UPLOAD_FORM');

//     if (username) {
//       form.append('username', username);
//     }
//     if (password) {
//       form.append('password', password);
//     }
//     if (comments) {
//       form.append('comments', comments);
//     }
//     if (typeof isEnterpriseInternal === 'boolean' && isEnterpriseInternal) {
//       form.append('release_type', MobileAppReleaseTypeValues.ENTERPRISE);
//     } else {
//       form.append('release_type', MobileAppReleaseTypeValues.PRE_PROD);
//     }
//     if (linkedAppStoreAppId) {
//       form.append('linked_app_store_mobile_app_id', linkedAppStoreAppId);
//     }

//     const response = yield* callPromise(fetch, upload_url, {
//       method: 'POST',
//       body: form,
//     });
//     const body = yield* callPromise(async () => response.json());

//     if (body.error) {
//       yield put(appFileRejected(body.error));
//       return;
//     }

//     yield put(appFileAccepted((body: UploadAppResponse)));
//   } catch (err) {
//     if (err instanceof Error) {
//       yield put(appFileRejected(err.message));
//       Raven.captureException(err);
//       return;
//     }
//     throw err;
//   }
// }
