//      
import React, { memo } from 'react';
import { Grid, Typography, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import ToolkitIcon from './ToolkitIcon';

const useStyles = makeStyles({
  iconContainer: {
    background: palette.brand,
  },
});

              
                
  

function ToolkitTitle({ title }       ) {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Avatar variant="circular" className={classes.iconContainer}>
          <ToolkitIcon
            fontSize="small"
            style={{
              color: palette.white,
            }}
          />
        </Avatar>
      </Grid>
      <Grid item>
        <Typography gutterBottom color="textSecondary" variant="subtitle1">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default memo       (ToolkitTitle);
