import { useState } from 'react';
var useTableSort = function (defaultSortField, defaultSortDirection) {
    if (defaultSortField === void 0) { defaultSortField = ''; }
    if (defaultSortDirection === void 0) { defaultSortDirection = 'asc'; }
    var _a = useState(defaultSortField), sortBy = _a[0], setSortBy = _a[1];
    var _b = useState(defaultSortDirection), sortDirection = _b[0], setSortDirection = _b[1];
    var handleSortChange = function (field, direction) {
        if (field === sortBy) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            return;
        }
        setSortBy(field);
        setSortDirection(direction || defaultSortDirection);
    };
    return {
        sortBy: sortBy,
        sortDirection: sortDirection,
        order_by: sortBy ? "".concat(sortBy, "_").concat(sortDirection) : null,
        handleSortChange: handleSortChange,
    };
};
export default useTableSort;
