//     
import gql from 'graphql-tag';

const list = gql`
  query PortalNotificationsList($cursor: String) {
    portal_notifications_list(cursor: $cursor) {
      pagination_information {
        next_cursor
      }
      portal_notifications {
        id
        title
        event_type
        icon_url
        detail
        detail_icon_url
        date_created
        portal_url
        subtitle
      }
    }
  }
`;

export default {
  list,
};
