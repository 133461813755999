//      
import gql from 'graphql-tag';

const list = gql`
  query SupplyChainSecureAppsList(
    $filter_by_text: String
    $filter_by_asset_type: [SupplyChainSecureAppTypeEnum!]
    $filter_by_uses_third_party_vendor_id: [String!]
    $order_by: SupplyChainSecureAppOrderByEnum
    $page_size: Int
    $cursor: String
  ) {
    supply_chain_secure_app_list(
      filter_by_text: $filter_by_text
      filter_by_asset_type: $filter_by_asset_type
      filter_by_uses_third_party_vendor_id: $filter_by_uses_third_party_vendor_id
      order_by: $order_by
      page_size: $page_size
      cursor: $cursor
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      supply_chain_secure_apps {
        id
        name
        asset_type
        icon_url
        open_urgent_policy_violations
        open_important_policy_violations
        open_proactive_policy_violations
        related_third_party_cloud_resources_count
        related_third_party_graphql_apis_count
        related_third_party_mobile_sdks_count
        related_third_party_restful_apis_count
        bundle_id
        extra_vendors_count
        url
        version_id
        top_vendors {
          id
          name
          icon_url
        }
      }
    }
  }
`;

export default {
  list,
};
