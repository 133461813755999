//     
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
                                                                                       

import { shadowscanFindingsReceived } from './../shadow-scan/actions';

                                                                        

export default createReducerCreator 
                  
                            
    
 ({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(shadowscanFindingsReceived.toString()));
