//      
import React from 'react';

// Logos and icons
import OperationsIcon from '@mui/icons-material/Build';

import Tag from './Tag';

               
                 
                            
   

function TagApiOperationsCount({ count, size }       ) {
  const postfix = size !== 'small' ? 'Operation' : 'API operation';
  const text = `${count} ${postfix}${count !== 1 ? 's' : ''}`;

  return <Tag icon={<OperationsIcon style={{ height: 14, width: 14 }} />} label={text} />;
}

export default React.memo       (TagApiOperationsCount);
