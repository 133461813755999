//      
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export const AlertsIntegrationsServiceEnum = {
  SLACK: 'SLACK',
  MICROSOFT_TEAMS: 'MICROSOFT_TEAMS',
};

                                                      
                                       
  

                                   
             
                             
               
                       
                                                  
                      
   

                                                                        

                                    
                                              
   

export async function list()                
                                                
  {
  return fetch(`${api}/${version}/alerts_integrations`).then(parse);
}

                                              
               
                                                  
                      
     
  

export async function create(
  params                                ,
)                                            {
  return fetch(`${api}/${version}/alerts_integrations`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export async function details(
  id        ,
)                                            {
  return fetch(`${api}/${version}/alerts_integrations/${id}`).then(parse);
}

                                             
                
                       
  

export async function patch(
  id        ,
  params                               ,
)                                            {
  return fetch(`${api}/${version}/alerts_integrations/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  }).then(parse);
}

export async function remove(id        )                   {
  return fetch(`${api}/${version}/alerts_integrations/${id}`, {
    method: 'DELETE',
  }).then(res => res.ok);
}
