//      
import { createAction } from 'redux-actions';

export const openAPIDefinitionsListMounted = createAction 
                            
     
                                      
 ('openapi-definition/list', () => ({
  key: 'openapi_definitions',
}));

export const openAPIDefinitionsReceived = createAction 
                                
                           
                         
 ('openapi-definition/received', definitions => definitions);

export const openAPIDefinitionUploadReset = createAction                                      (
  'openapi-definition/reset',
);

export const openAPIDefinitionUpload = createAction 
                              
                                                                            
                                                                          
 ('openapi-definition/upload', params => params);

export const openAPIDefinitionUploadSuccess = createAction                                               (
  'openapi-definition/upload/success',
  () => {},
);

export const openAPIDefinitionUploadError = createAction                                                     (
  'openapi-definition/upload/error',
  error => error,
);
