//     
import { Raven } from '@dt/global';
import { Actions as NotificationsActions } from '@dt/notifications';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { create, remove } from '@dt/user-api/search_queries';
import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionEnum, addedFilter, removedFilter } from '../actions/filterActions';
                                                                                    

// Types
                                       

const onError = function*(err, title, description) {
  const errorMessage = title && description ? `${title} - ${description}` : err && err.message;
  Raven.captureException(err, {
    extra: {
      msg: errorMessage,
    },
  });
  yield put(
    NotificationsActions.requestNotifyUser({
      text: errorMessage,
    }),
  );
  console.log(errorMessage); // eslint-disable-line no-console
  console.error(err);
};

const addFilterSaga = function*({ payload }                 )             {
  try {
    const response = yield* callPromise(create, {
      title: payload.name,
      description: payload.name,
      is_private: true,
      filter_query: {
        keywords: payload.value.selectedKeywords,
        mobileAppIds: payload.value.selectedMobileAppIds,
        compliance_policies: payload.value.selectedCompliancePolicies,
        current_statuses: payload.value.selectedCurrentStatuses,
        priorities: payload.value.selectedPriorities,
        release_types: payload.value.selectedReleaseTypes,
        store_blockers: payload.value.selectedStoreBlockers,
        severities: payload.value.selectedSeverities,
        statuses: payload.value.selectedStatuses.dateRange.type
          ? {
              date_range: {
                from_date: payload.value.selectedStatuses.dateRange.from
                  ? payload.value.selectedStatuses.dateRange.from.toISOString()
                  : null,
                to_date: payload.value.selectedStatuses.dateRange.to
                  ? payload.value.selectedStatuses.dateRange.to.toISOString()
                  : null,
                type: payload.value.selectedStatuses.dateRange.type,
              },
              statuses: payload.value.selectedStatuses.statuses,
            }
          : null,
        min_age: payload.value.minAge ? parseInt(payload.value.minAge, 10) : null,
        sort_by: payload.value.sortBy ? payload.value.sortBy.toUpperCase() : 'NONE',
      },
    });

    if (!response) throw new Error('Unable to create search query.');

    // Create a DateRange FLow.
    const dateRange =
      response.filter_query.statuses.date_range.type === 'CUSTOM'
        ? {
            type: response.filter_query.statuses.date_range.type,
            from: response.filter_query.statuses.date_range.from_date
              ? new Date(response.filter_query.statuses.date_range.from_date)
              : new Date(),
            to: response.filter_query.statuses.date_range.to_date
              ? new Date(response.filter_query.statuses.date_range.to_date)
              : new Date(),
          }
        : {
            type: response.filter_query.statuses.date_range.type,
          };

    yield put(
      addedFilter(response.description, {
        id: response.id,
        description: response.description,
        isOwner: response.is_owner,
        isPrivate: response.is_private,
        isDisabledLoading: false,
        selectedKeywords: response.filter_query.keywords,
        selectedMobileAppIds: response.filter_query.mobileAppIds,
        selectedCompliancePolicies: response.filter_query.compliance_policies,
        selectedStoreBlockers: response.filter_query.store_blockers,
        selectedCurrentStatuses: response.filter_query.current_statuses,
        selectedPriorities: response.filter_query.priorities,
        selectedReleaseTypes: response.filter_query.release_types,
        selectedSeverities: response.filter_query.severities,
        selectedStatuses: {
          statuses: response.filter_query.statuses.statuses,
          dateRange: dateRange,
        },
        minAge: response.filter_query.min_age ? response.filter_query.min_age : '',
        // $FlowFixMe FlowUpgrade
        sortBy: response.filter_query.sort_by,
      }),
    );
  } catch (err) {
    yield call(onError, err, 'Save Filters', 'There was an error saving search queries.');
  }
};

const removeFilterSaga = function*({ payload }                    )             {
  try {
    yield* callPromise(remove, payload);
    yield put(removedFilter(payload));
  } catch (err) {
    yield call(onError, err, 'Save Filters', 'There was an error saving search queries.');
  }
};

/** Main saga for Filters */
export const filtersSaga = function*()             {
  try {
    yield takeEvery(ActionEnum.ADD_FILTER, addFilterSaga);
    yield takeEvery(ActionEnum.REMOVE_FILTER, removeFilterSaga);
  } catch (err) {
    yield call(onError, err);
  }
};
