import React, { memo, useEffect, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, List, ListItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// @ts-ignore JS component
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
// @ts-ignore JS component
import { ErrorState } from '@dt/components';
import asset_tags from '@dt/graphql-support/horizon/ts/asset_tags';
import debounce from 'lodash/fp/debounce';
import TableFiltersAssetTagKeyItem from './TableFiltersAssetTagKeyItem';
var InventoryTableFilterAssetTagsComponent = function InventoryTableFilterAssetTags(_a) {
    var selectedAssetTags = _a.selectedAssetTags, onToggleAssetTagKey = _a.onToggleAssetTagKey, onToggleAssetTagValue = _a.onToggleAssetTagValue;
    var _b = useLazyQuery(asset_tags.list, {
        fetchPolicy: 'network-only',
    }), fetchAssetTagKeysQuery = _b[0], _c = _b[1], data = _c.data, error = _c.error, loading = _c.loading, called = _c.called, fetchMore = _c.fetchMore, pageNumber = _c.pageNumber;
    useEffect(function () {
        if (!called) {
            fetchAssetTagKeysQuery({});
        }
    }, [fetchAssetTagKeysQuery, called]);
    var _d = useState(''), filter = _d[0], setFilter = _d[1];
    var handleChange = useMemo(function () {
        return debounce(500)(function (filter) {
            return fetchAssetTagKeysQuery(filter
                ? {
                    variables: {
                        filter_by_text: filter,
                    },
                }
                : {});
        });
    }, [fetchAssetTagKeysQuery]);
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(TextField, { fullWidth: true, value: filter, onChange: function (e) {
                    setFilter(e.target.value);
                    handleChange(e.target.value);
                }, id: "asset-keys-filter", label: 'Filter', InputProps: {
                    endAdornment: React.createElement(SearchIcon, null),
                } })),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(List, { component: "nav" },
                error ? (React.createElement(ErrorState, { error: error })) : !pageNumber && loading ? ([0, 1, 2, 3, 4].map(function (_, key) { return (React.createElement(ListItem, { button: true, dense: true, key: key },
                    React.createElement(Skeleton, { animation: "wave", width: "100%", height: 40 }))); })) : (data === null || data === void 0 ? void 0 : data.asset_tag_key_list.asset_tag_keys.map(function (assetTagKey, key) {
                    return assetTagKey && (React.createElement(TableFiltersAssetTagKeyItem, { assetTagKey: assetTagKey, selectedAssetTags: selectedAssetTags, onToggleAssetTagKey: onToggleAssetTagKey, onToggleAssetTagValue: onToggleAssetTagValue, key: key }));
                })),
                fetchMore && (React.createElement(React.Fragment, null,
                    React.createElement(Box, { component: 'div' },
                        React.createElement(ListItem, { button: true, dense: true },
                            React.createElement(Skeleton, { animation: "wave", width: "100%", height: 40 })),
                        React.createElement(ListItem, { button: true, dense: true },
                            React.createElement(Skeleton, { animation: "wave", width: "100%", height: 40 })),
                        React.createElement(ListItem, { button: true, dense: true },
                            React.createElement(Skeleton, { animation: "wave", width: "100%", height: 40 }))),
                    React.createElement(Waypoint, { onEnter: function () {
                            if (fetchMore) {
                                fetchMore();
                            }
                        } })))))));
};
export default memo(InventoryTableFilterAssetTagsComponent);
