//     
import { createSelector } from 'reselect';
import { sumBy } from 'lodash/fp';
import { findingFromParam } from './securityFindings';
import { getListOfHigherSubscriptions } from '../util/appUtil';
                                                 
import { getMetadataCount, getTotalMetadataCount } from '@dt/user-api/util/metadataCount';
             
              
               
                               
                                
                        
                    
                                  
import MobileAppReleaseTypeEnum from '@dt/enums/MobileAppReleaseTypeEnum';

export const apps = (state       ) => state.apps;

export const sortedApps = createSelector                                 (
  apps,

  apps => apps.slice().sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())),
);

const storeApps = createSelector                                 (sortedApps, apps =>
  apps.filter(app => app.release_type === MobileAppReleaseTypeEnum.APP_STORE),
);

const storeAppsIncludingThirdPartyApps = createSelector                                 (sortedApps, apps =>
  apps.filter(
    app =>
      app.release_type === MobileAppReleaseTypeEnum.APP_STORE ||
      app.release_type === MobileAppReleaseTypeEnum.APP_STORE_THIRD_PARTY,
  ),
);

const enterpriseApps = createSelector                                 (sortedApps, apps =>
  apps.filter(app => app.release_type === MobileAppReleaseTypeEnum.ENTERPRISE),
);

export const preProdAppFromParam = createSelector                                       (
  apps,
  (state, props) => (typeof props.appId === 'string' ? props.appId : null),

  (apps, appId) =>
    appId
      ? apps
          .filter(app => app.release_type === MobileAppReleaseTypeEnum.PRE_PROD)
          .find(app => app.id === appId && typeof app.app_store_customer_mobile_app_id !== 'undefined')
      : null,
);

export const linkedAppStoreAppFromParam = createSelector                                       (
  apps,
  preProdAppFromParam,

  (apps, PreProdApp) => (PreProdApp ? apps.find(app => app.id === PreProdApp.app_store_customer_mobile_app_id) : null),
);

// region MetadataCount
// MetadataCount should be transformed from the API or pushed back into the server.
const appsWithMetadataCount = createSelector                                                  (sortedApps, apps =>
  apps.map(app => ({
    metadataCount: getMetadataCount(app.metadata),
    ...app,
  })),
);

export const totalMetadataCount = createSelector                                          (
  appsWithMetadataCount,
  apps =>
    getTotalMetadataCount(
      apps
        .map                              (app => ({
          metadataCount: getMetadataCount(app.metadata),
          ...app,
        }))
        .map(app => app.metadataCount),
    ),
);

export const storeAppsWithMetadataCount = createSelector                                                  (
  storeAppsIncludingThirdPartyApps,
  storeAppsIncludingThirdPartyApps =>
    storeAppsIncludingThirdPartyApps.map(storeApp => ({
      metadataCount: getMetadataCount(storeApp.metadata),
      ...storeApp,
    })),
);

export const enterpriseAppsWithMetadataCount = createSelector                                                  (
  enterpriseApps,
  enterpriseApps =>
    enterpriseApps.map(enterpriseApp => ({
      metadataCount: getMetadataCount(enterpriseApp.metadata),
      ...enterpriseApp,
    })),
);

export const totalStoreAndEnterpriseAppsMetadataCount = createSelector 
        
          
                        
                                
                                
    
 (storeAppsWithMetadataCount, enterpriseAppsWithMetadataCount, (storeApps, enterpriseApps) =>
  getTotalMetadataCount(
    storeApps
      .concat(enterpriseApps)
      .map(storeOrEnterpriseApp => ({
        metadataCount: getMetadataCount(storeOrEnterpriseApp.metadata),
        ...storeOrEnterpriseApp,
      }))
      .map(app => app.metadataCount),
  ),
);

// endregion MetadataCount
const getPercentageOfCompletedIntegrationOfCiCd = ciCdIntegrations => {
  return (sumBy(ci_cd => (ci_cd ? 1 : 0))(ciCdIntegrations) / ciCdIntegrations.length) * 100;
};

// APP_STORE_THIRD_PARTY apps cannot be linked and therefore use of storeApps.
export const percentageOfStoreAndEnterpriseAppsIntegratedWithCiCd = createSelector 
        
          
         
               
    
 (storeApps, enterpriseApps, (storeApps, enterpriseApps) =>
  getPercentageOfCompletedIntegrationOfCiCd(
    storeApps.concat(enterpriseApps).map(storeOrEnterpriseApp =>
      // integrations should always be defined here.
      storeOrEnterpriseApp.integrations ? storeOrEnterpriseApp.integrations.ci_cd : false,
    ),
  ),
);

                              
                                     
                                         
                                               
                                                
                                                
     
  

                          
                        
     
  

export const appFromParam = createSelector                                       (
  apps,
  (state, props) => (typeof props.appId === 'string' ? props.appId : null),

  (apps, appId) =>
    appId ? apps.find(app => app && app.id && app.id.toString() === (appId && appId.toString())) : null,
);

export const metadataCountForAppFromParam = createSelector                                                    (
  appFromParam,

  app => {
    if (!app) {
      return null;
    }

    return getMetadataCount(app.metadata);
  },
);

export const appFromFindingParam = createSelector                                                     (
  apps,
  findingFromParam,

  (apps, finding) =>
    apps && finding && typeof finding.mobile_app_id === 'string'
      ? apps.find(app => finding && app.id === finding.mobile_app_id)
      : null,
);

export const subscriptionsBeyondAppFromParam = createSelector                                                     (
  appFromParam,

  app => {
    if (!app) {
      return null;
    }

    const subs = getListOfHigherSubscriptions(app.subscription);

    if (!subs) {
      return null;
    }

    return subs.filter(sub => sub !== 'APP_LOGIC');
  },
);

const tasks = (state       ) => state.tasks;

export const tasksForAppFromParam = createSelector                                                            (
  tasks,
  appFromParam,

  (tasks, app) => tasks.filter(task => app && task.mobile_app_id === app.id),
);
