// @ts-ignore
import BrandIconSupplyChainSecurity from '@dt/brand/product-icon-supply-chain-security';
// @ts-ignore
import { getInvariantUserAccount } from '@dt/session';
var useScsMenuItem = function (_a) {
    var loading = _a.loading;
    if (loading)
        return;
    var _b = getInvariantUserAccount(), accountInfo = _b.accountInfo, currentUser = _b.currentUser;
    var scsMenuItem;
    if (currentUser === null || currentUser === void 0 ? void 0 : currentUser.can_access_vendor_supply_chain_security) {
        // if the current user can access Vendor SCS (regardless of whether they are
        // in their home account or a vendor-customer), then always send them to
        // the Supply Chain Secure main page
        scsMenuItem = {
            to: '/supply-chain',
            name: (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.supply_chain_security_product_top_level_whitelabel_name) || 'Supply Chain Secure',
            icon: BrandIconSupplyChainSecurity,
        };
    }
    else if (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.current_vendor) {
        // if the current user can't access Vendor SCS, but they are inside of a
        // vendor-customer, then send them to their own vendor/Supplier page.
        scsMenuItem = {
            to: "/supply-chain/supplier-trust/suppliers/".concat(accountInfo.vendor_id || '', "/"),
            name: (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.vendor_supply_chain_security_whitelabeling_name) || 'Supplier Trust',
            icon: BrandIconSupplyChainSecurity,
        };
    }
    else {
        // otherwise, send the user to either normal SCS or OpenScan
        scsMenuItem =
            accountInfo.supply_chain_security_product_enabled || accountInfo.openscan_subscription == 'NO_SUBSCRIPTION'
                ? {
                    to: '/supply-chain',
                    name: (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.supply_chain_security_product_top_level_whitelabel_name) || 'Supply Chain Secure',
                    icon: BrandIconSupplyChainSecurity,
                }
                : {
                    to: '/openscan/v2',
                    name: (accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.supply_chain_security_product_top_level_whitelabel_name) || 'Supply Chain Security',
                    icon: BrandIconSupplyChainSecurity,
                };
    }
    return scsMenuItem;
};
export default useScsMenuItem;
