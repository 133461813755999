//      
import { createSelector } from 'reselect';

export const config = (state       ) => state.openscanConfig;

const apps = (state       )                     => state.openscanApps;

export const appFromId = (appId        ) => {
  return createSelector                                             (apps, apps => apps.find(app => app.id === appId));
};

export const watchlistApps = createSelector                                              (
  apps,
  apps => apps && apps.filter(app => app.favorite),
);

                          
                        
     
  

export const appFromParam = createSelector                                             (
  (state, props) => props.appId,
  apps,

  (id, apps) => {
    return apps && apps.find(app => app.id === id);
  },
);

                     
                          
     
  

export const searchCriteria = (state       , props              ) => props.appName;

const searchResults = (state       ) => state.searchResults;

export const activeSearchResults = createSelector                                          (
  searchCriteria,
  searchResults,
  (searchCriteria, searchResults) => {
    if (typeof searchCriteria !== 'string') {
      return [];
    }

    const searchCriteriaDecoded = decodeURIComponent(searchCriteria).replace(/\+/g, ' ');

    return searchResults[searchCriteriaDecoded] || [];
  },
);
