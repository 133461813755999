var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
//@flow
import gql from 'graphql-tag';
export var get_status = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query ShadowAssetsGetStatus($id: String!) {\n    shadow_assets_check_status(id: $id) {\n      action_type\n      status\n      id\n      completed_tasks_count\n      total_tasks_count\n      date_created\n      date_completed\n    }\n  }\n"], ["\n  query ShadowAssetsGetStatus($id: String!) {\n    shadow_assets_check_status(id: $id) {\n      action_type\n      status\n      id\n      completed_tasks_count\n      total_tasks_count\n      date_created\n      date_completed\n    }\n  }\n"])));
export var get = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query ConfigurationsShadowGet {\n    configurations_shadow_get {\n      bulk_action_id\n      configuration {\n        approved_asset_types\n        approved_cloud_providers\n        approved_app_stores\n        public_asm_enabled\n        should_mark_new_apis_not_going_through_gateway\n        should_mark_new_assets_except_on_approved_asset_types\n        should_mark_new_cloud_resources_except_on_approved_cloud_providers\n        should_mark_new_mobile_apps_on_3rd_party_stores\n        should_mark_new_mobile_apps_on_unofficial_app_stores\n        should_mark_new_mobile_apps_except_on_approved_app_stores\n        should_mark_new_mobile_apps_on_official_stores\n        should_mark_new_network_services_with_dev_in_url\n        should_mark_new_network_services_with_preprod_in_url\n        should_mark_new_network_services_with_uat_in_url\n      }\n    }\n  }\n"], ["\n  query ConfigurationsShadowGet {\n    configurations_shadow_get {\n      bulk_action_id\n      configuration {\n        approved_asset_types\n        approved_cloud_providers\n        approved_app_stores\n        public_asm_enabled\n        should_mark_new_apis_not_going_through_gateway\n        should_mark_new_assets_except_on_approved_asset_types\n        should_mark_new_cloud_resources_except_on_approved_cloud_providers\n        should_mark_new_mobile_apps_on_3rd_party_stores\n        should_mark_new_mobile_apps_on_unofficial_app_stores\n        should_mark_new_mobile_apps_except_on_approved_app_stores\n        should_mark_new_mobile_apps_on_official_stores\n        should_mark_new_network_services_with_dev_in_url\n        should_mark_new_network_services_with_preprod_in_url\n        should_mark_new_network_services_with_uat_in_url\n      }\n    }\n  }\n"])));
export var put = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  mutation ConfigurationsShadowPut($body: ConfigurationsShadowPutParams!) {\n    configurations_shadow_put(body: $body) {\n      bulk_action_id\n    }\n  }\n"], ["\n  mutation ConfigurationsShadowPut($body: ConfigurationsShadowPutParams!) {\n    configurations_shadow_put(body: $body) {\n      bulk_action_id\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
