//      
import immer from 'immer';

import { policyRuleTypesReceived } from './actions';
                                                      

                                    
                                               
                                              
   

const initialState                      = {
  id: {},
  for_group: {},
};

                                                          

// $FlowFixMe
export default immer                     ((draft, action         ) => {
  if (action.type === policyRuleTypesReceived.toString()) {
    for (const policy_rule_type of action.payload) {
      const { id, group } = policy_rule_type;
      draft.id[id] = policy_rule_type;
      draft.for_group[group] = (draft.for_group[group] || []).filter(i => i !== id);
      draft.for_group[group].push(id);
    }
  }
}, initialState);
