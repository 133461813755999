//     
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { createAction } from 'redux-actions';

                                                    

// $FlowFixMe FlowUpgrade
export const updateApp = createAction                     (
  'APPS_UPDATE',
  (app                             )                              => app,
);

export default createReducerCreator                           ({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(updateApp.toString()));
