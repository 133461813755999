//      
import { createReducerCreator, updateInList } from 'rezz';
import { certificateReceived } from './actions';
                                                                    

                                               

export default createReducerCreator                                  ({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList([certificateReceived.toString()]));
