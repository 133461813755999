//      
import React from 'react';
import { useSelector } from 'react-redux';
import UploadPoContainer from './PoFileUpload';

export default () => {
  const billingInfo = useSelector((state       ) => state.BillingInfo);

  if (billingInfo.status === 'unsubscribed') return <></>;
  const { poUpload } = billingInfo;

  return (
    <>{poUpload.shouldShow && poUpload.isOpen ? <UploadPoContainer /> : null}</>
  );
};
