//     
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { noResult } from './util';

export const create = ()                => {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/renewal_reminders`, {
    method: 'POST',
  })
    .then(parse)
    .then(noResult);
};

export default {
  create,
};
