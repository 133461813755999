//      

export const StatusesTypeEnum   
                     
               
                 
     
  = {
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
};

                                  
                          
                      
                  
   

                          
                                                 
                                              
                                                                

                            
               
                  
   

                                 
                  
                 
                  
   

                         
                      
              
   

                                                           
