//     
import React from 'react';
import { Grid, Avatar } from '@mui/material';
import ApiIcon from '@mui/icons-material/DeviceHub';
import { palette } from '@dt/theme';
import { PageTitle } from '@dt/components';
import Skeleton from '@mui/material/Skeleton';
import { ExtLink } from '@dt/material-components';
import { AssetTypeEnumValues } from '@dt/graphql-support/enums';

              
            
                  
                        
                  
                    
                             
                                      
                                 
       
    
                      
  

export const AssetsPageTitle = ({ asset, isLoading }       ) => {
  const isNotRestApi = asset?.asset_type !== AssetTypeEnumValues.RESTFUL_API;

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        {isLoading ? (
          <Skeleton variant="circular" height={40} width={40} />
        ) : (
          <>
            {isNotRestApi ? (
              <Avatar
                variant="circular"
                src={asset?.asset_type_icon_url}
                style={{
                  padding: 4,
                  backgroundColor: palette.gray45,
                }}
              />
            ) : (
              <Avatar
                variant="circular"
                style={{
                  background: palette.orange,
                }}
              >
                <ApiIcon fontSize="large" style={{ padding: 2 }} />
              </Avatar>
            )}
          </>
        )}
      </Grid>

      <Grid item>
        {asset ? (
          <>
            {asset.url ? (
              <ExtLink to={asset.url}>
                <PageTitle title={asset.name} />
              </ExtLink>
            ) : (
              <PageTitle title={asset.name} />
            )}
          </>
        ) : (
          <PageTitle isLoading={isLoading} title="Asset" />
        )}
      </Grid>
    </Grid>
  );
};
