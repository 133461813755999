//      
import React, { memo } from 'react';
import { ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';

              
                
                     
                    
                    
                          
  

const AssetFilterSelectLabel = ({ title, filterType, selected, disabled, onClick }) => {
  return (
    <ListItem selected={selected} onClick={() => onClick(filterType)} disabled={disabled}>
      <ListItemText primary={title} />
      <ListItemSecondaryAction>
        <ArrowRight />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default memo       (AssetFilterSelectLabel);
