//      

import { all, call } from 'redux-saga/effects';

import { getSharedLinks } from './resource_fetch.sagas';
import { takePatternAndFetch, watchForLoadMoreAndFetchNextPage, paginateToEnd } from '../resource_fetch/sagas';
import { sharedLinksMounted } from './actions';
import resourceType from './resourceType';

export default function* watchForResourceFetching()             {
  yield all([
    call(takePatternAndFetch, sharedLinksMounted.toString(), function*()             {
      yield call(getAllSharedLinks);
    }),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({ cursor })             {
      yield call(getSharedLinks, { cursor });
    }),
  ]);
}

export function* getAllSharedLinks()             {
  yield call(paginateToEnd, getSharedLinks, resourceType, {});
}
