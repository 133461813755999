//     
import React from 'react';
import { makeStyles } from '@mui/styles';

import { palette } from '@dt/theme';
import ProgressIcon from '@mui/icons-material/Timelapse';
import ExclamationPointIcon from '@mui/icons-material/Error';
import CheckMarkIcon from '@mui/icons-material/CheckCircle';
                                                                      
import { AppScanBlockedReasonValues } from '@dt/graphql-support/enums';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  label: {
    color: palette.navColor,
    paddingLeft: ({ includeImage }       ) => (includeImage ? 5 : 'unset'),
  },
});

                      
           
     
                           
                         
         
             
                         
              
                        
                        
   

               
                         
                              
                         
                  
   

function ScanBadge(props) {
  const { scan, includeImage, includeLabel, overrideLabel, format, type } = props;
  const classes = useStyles();

  const scanDetails = !overrideLabel && type ? format(scan, type) : overrideLabel;

  return (
    <div className={classes.container}>
      {includeImage ? <BadgeImage scanDetails={scanDetails} scan={scan} type={type} /> : null}
      {includeLabel ? <span className={classes.label}>{scanDetails}</span> : null}
    </div>
  );
}

ScanBadge.defaultProps = {
  format: (
    scan          
                           
                         
         
     ,
    reason                      ,
  ) => reason,
  includeLabel: true,
  includeImage: true,
};

const BadgeImage = ({ scanDetails, scan, type }) => {
  const style = {
    width: 16,
    height: 16,
    margin: 0,
  };

  const exclamationPoint = (
    <span style={{ color: palette.red }}>
      <ExclamationPointIcon style={style} titleAccess={scanDetails} />
    </span>
  );

  // No scan.
  if (!scan) {
    return exclamationPoint;
  }

  // No scan started.
  if (!scan.start_date) {
    return exclamationPoint;
  }

  // Scan blocked.
  if (type === AppScanBlockedReasonValues.CORRUPTED_APP_PACKAGE || type === AppScanBlockedReasonValues.NO_CREDENTIALS) {
    return exclamationPoint;
  }

  // Scan ongoing.
  if (!scan.end_date) {
    return (
      <span style={{ color: palette.yellow }}>
        <ProgressIcon style={style} titleAccess={scanDetails} />
      </span>
    );
  }

  // Scan completed.
  return (
    <span style={{ color: palette.green }}>
      <CheckMarkIcon style={style} titleAccess={scanDetails} />
    </span>
  );
};

export default React.memo       (ScanBadge);
