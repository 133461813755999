//      

import { DiscoveredVia } from '@dt/horizon-api';

import React from 'react';
import Tag from './Tag';
// Logos and icons
import TagDiscoveredViaIcon from './TagDiscoveredViaIcon';

const DiscoveredViaStringMap                                                 = {
  [DiscoveredVia.AMAZON_WEB_SERVICES]: 'AWS Analyzer',
  [DiscoveredVia.APIGEE_ON_PREM]: 'Apigee Analyzer',
  [DiscoveredVia.APIGEE]: 'Apigee Analyzer',
  [DiscoveredVia.APPLICATION_FINGERPRINTING]: 'DT Blackbox Analyzer',
  [DiscoveredVia.AXWAY]: 'Axway Analyzer',
  [DiscoveredVia.AZURE]: 'Azure Analyzer',
  [DiscoveredVia.BITBUCKET_INTEGRATION]: 'Bitbucket Integration',
  [DiscoveredVia.CERTIFICATE_TRANSPARENCY]: 'DT Blackbox Analyzer',
  [DiscoveredVia.CLOUD_PROTECT]: 'Cloud Protect',
  [DiscoveredVia.CLOUDFLARE]: 'Cloudflare',
  [DiscoveredVia.GITHUB_ENTERPRISE_SERVER_INTEGRATION]: 'GitHub Enterprise Server Integration',
  [DiscoveredVia.GITHUB_INTEGRATION]: 'Github Integration',
  [DiscoveredVia.GITLAB_INTEGRATION]: 'GitLab Integration',
  [DiscoveredVia.GOOGLE_CLOUD_PLATFORM]: 'GCP Analyzer',
  [DiscoveredVia.KONG]: 'Kong Analyzer',
  [DiscoveredVia.KUBERNETES_CLUSTER_ANALYSIS]: 'Kubernetes Cluster Analysis',
  [DiscoveredVia.MANUAL_IMPORT]: 'Manual Import',
  [DiscoveredVia.MOBILE_APP_SCANS]: 'Reverse Engineering (Mobile)',
  [DiscoveredVia.MOBILE_APP_STORE]: 'Mobile App Store',
  [DiscoveredVia.MOBILE_PROTECT_USAGE]: 'Mobile Protect Usage',
  [DiscoveredVia.MULESOFT]: 'Mulesoft Analyzer',
  [DiscoveredVia.POSTMAN_CLOUD]: 'Postman Cloud',
  [DiscoveredVia.RESTFUL_API_DISCOVERER]: 'DT Blackbox Analyzer',
  [DiscoveredVia.REVERSE_IP_LOOKUP]: 'Reverse IP Lookup',
  [DiscoveredVia.SAN_EXTRACTION]: 'DT Blackbox Analyzer',
  [DiscoveredVia.SWAGGER_HUB]: 'Swagger Hub',
  [DiscoveredVia.WEB_APPLICATION_SCANS]: 'Reverse Engineering (WebApps)',
  [DiscoveredVia.WEBSITE_CRAWLER]: 'Website Crawler',
  [DiscoveredVia.AKAMAI]: 'Akamai',
  [DiscoveredVia.AZURE_DEVOPS_INTEGRATION]: 'Azure DevOps Integration',
  [DiscoveredVia.SHADOW_RESTFUL_API_DISCOVERER]: 'Shadow RESTful API Discoverer'
};

export const getStringFromDiscoveredVia = (discovered_via                            ) => {
  const discovered_via_string = DiscoveredViaStringMap[discovered_via];
  if (!discovered_via_string) {
    throw new Error(`Missing discovered via string for ${discovered_via}, DiscoveredViaType is likely out of date`);
  }
  return discovered_via_string;
};

               
                                              
                            
                     
                               
   

const TagDiscoveredVia = ({ discovered_via, maxWidth, discovered_date, variant = 'small' }       ) => {
  const dateString = discovered_date ? ` on ${new Date(discovered_date).toLocaleDateString()}` : '';

  return (
    <Tag
      maxWidth={maxWidth}
      label={`${variant === 'large' ? 'Discovered via ' : ''}${getStringFromDiscoveredVia(
        discovered_via,
      )}${dateString}`}
      icon={<TagDiscoveredViaIcon discovered_via={discovered_via} size={14} />}
    />
  );
};

export default React.memo       (TagDiscoveredVia);
