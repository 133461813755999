//     
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { appProtectionTasksReceived } from '../actions';
                                                                  
import { updateStatusSuccess } from '../actions/securityFindings';
import flatMap from 'lodash/fp/flatMap';

                                                           

export default createReducerCreator                                  ({
  initialState: ([]            ),
  equalityCheck: (a, b) => a.mobile_app_id === b.mobile_app_id && a.title === b.title,
})(
  updateInList(appProtectionTasksReceived.toString()),
  (state                                    = [], action)                                    => {
    if (!action || !action.payload || typeof action.payload !== 'object') {
      return state;
    }

    const { status, targetId } = action.payload;
    if (action.type === updateStatusSuccess.toString() && status && typeof status === 'object' && targetId) {
      const target = flatMap(task => (task.targets ? task.targets : []))(state).find(target => target.id === targetId);

      if (!target) {
        return state;
      }

      const task = state.find(task => task.security_finding_id === target.security_finding_id);

      if (!task) {
        return state;
      }

      const newTask = {
        ...task,
        targets: (task.targets || [])
          .filter(currentTarget => currentTarget.id !== target.id)
          .concat({
            ...target,
            statuses: target.statuses.slice().concat(status),
          }),
      };

      return state
        .filter(task => task.title !== newTask.title && task.mobile_app_id !== newTask.mobile_app_id)
        .concat(newTask);
    }

    return state;
  },
);
