//      
import React from 'react';
import { PolicyRuleTypeRelevanceValues } from '@dt/graphql-support/enums';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import WarningIcon from '@mui/icons-material/Warning';
import ImportantWarningIcon from '@mui/icons-material/Flag';
import InformationalProactiveIcon from '@mui/icons-material/Info';
import { palette } from '@dt/theme';
import Label from './Label';

               
                                     
                      
                            
   

const variants = {
  [PolicyRuleTypeRelevanceValues.URGENT]: {
    backgroundColor: palette.red50,
    color: palette.red30,
    icon: WarningIcon,
  },
  [PolicyRuleTypeRelevanceValues.IMPORTANT]: {
    backgroundColor: palette.yellow50,
    color: palette.yellow10,
    icon: ImportantWarningIcon,
  },
  [PolicyRuleTypeRelevanceValues.PROACTIVE]: {
    backgroundColor: palette.blue50,
    color: palette.blue30,
    icon: InformationalProactiveIcon,
  },
  /* No dedicated style yet */
  [PolicyRuleTypeRelevanceValues.INFORMATIONAL]: {
    backgroundColor: palette.blue50,
    color: palette.blue30,
    icon: InformationalProactiveIcon,
  },
};

const relevanceMapping = {
  URGENT: 'HIGH',
  PROACTIVE: 'LOW',
  IMPORTANT: 'MEDIUM',
  INFORMATIONAL: 'INFORMATIONAL',
};

const useStyle = makeStyles({
  relevanceTag: props => ({
    '&&': {
      backgroundColor: variants[props.relevance].backgroundColor,
      color: variants[props.relevance].color,
      fontSize: props.size === 'small' ? 10 : 13,
      padding: props.size === 'small' ? 4 : '4px 8px',
    },
  }),
  icon: props => ({
    '&&': {
      backgroundColor: variants[props.relevance].backgroundColor,
      color: variants[props.relevance].color,
      fontSize: props.size === 'small' ? 12 : 24,
    },
  }),
});

const RelevanceTag = ({ relevance, showLabel = true, size = 'medium' }       ) => {
  const classes = useStyle({ relevance, size });
  const Icon = variants[relevance].icon;

  return (
    <Label classes={classes.relevanceTag}>
      <Grid container alignItems={'center'} alignContent={'center'} spacing={1} wrap="nowrap">
        <Grid item>
          <Grid container>
            <Icon classes={{ root: classes.icon }} />
          </Grid>
        </Grid>
        {showLabel ? <Grid item>{relevanceMapping[relevance]}</Grid> : null}
      </Grid>
    </Label>
  );
};

export default React.memo       (RelevanceTag);
