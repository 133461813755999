//      

import { all, putResolve, put, takeEvery } from 'redux-saga/effects';
import { select, callPromise } from '@dt/redux-saga-wrapped-effects';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';
import { getUserAccount } from '@dt/session';
import { Raven } from '@dt/global';
import { user_sessions } from '@dt/horizon-api';
import { Actions as NotificationsActions } from '@dt/notifications';
import {
  createSubscriptionAction,
  createActiveSubscriptionAction,
  createPastDueSubscriptionAction,
  createDemoSubscriptionAction,
  dismissBillingReminderAlertAction,
  redirectToPayNowAction,
  openQouteUrlAction,
  notifyBillingContactAction,
  uploadPoFileAction,
  loadPurchaseQuoteAction,
  purchaseQuoteAppListFetchRequest,
  purchaseQuoteAppListFetchSucceed,
  purchaseQuoteApiStatsFetchRequest,
  purchaseQuoteApiStatsFetchSucceed,
} from './actions';
import { getSubscriptionEndDateInDays } from '../utls';
import {
  upload_po_file_to_google_storage,
  renewal_reminder_dismissal,
  notify_billing_contact,
  mobile_apps_list,
  inventory_stats,
} from '../billing_info_api';
                                      
import { notificationContents } from '../contents';
import {
  MobileAppPlatformValues,
  MobileAppReleaseTypeValues,
} from '@dt/graphql-support/enums';

const getSubcsriptionType = accountInfo => {
  const { subscription_period, account_status } = accountInfo;

  if (subscription_period && subscription_period.end_date) {
    const daysToExpire = getSubscriptionEndDateInDays(
      subscription_period.end_date,
    );
    if (daysToExpire < 0) {
      return 'past-due';
    }
    return 'active';
  }

  if (
    account_status &&
    (account_status === 'DEMO' || account_status === 'DEMO_LOGINS_DISABLED')
  ) {
    return 'demo';
  }

  return 'unsubscribed';
};

function* createSubscriptionActionSaga(action   
               
            
                             
                      
       
    
     
 )             {
  const { accountInfo, currentUser } = action.payload;
  const subscriptionType = getSubcsriptionType(accountInfo);

  if (subscriptionType === 'active')
    yield put(createActiveSubscriptionAction(accountInfo, currentUser));
  else if (subscriptionType === 'past-due')
    yield put(createPastDueSubscriptionAction(accountInfo, currentUser));
  else if (subscriptionType === 'demo')
    yield put(createDemoSubscriptionAction(accountInfo, currentUser));
}

function* dismissBillingReminderAlertSaga()             {
  try {
    yield* callPromise(renewal_reminder_dismissal.create, {
      renewal_reminder_dismissal: true,
    });
  } catch (e) {
    Raven.captureException(e);
  }
}

function* redirectToPayNowSaga()             {
  const { payNow } = yield* select((state       ) => state.BillingInfo);
  if (payNow.shouldShow) {
    yield put(dismissBillingReminderAlertAction());
    yield put(
      NotificationsActions.requestNotifyUser({
        text: notificationContents['billingUser'],
      }),
    );
    window.open(payNow.credit_card_url);
  } else {
    yield put(
      NotificationsActions.requestNotifyUser({
        text: 'no url found for payment.',
      }),
    );
  }
}

function* openQouteUrlSaga()             {
  const { quoteUrl } = yield* select((state       ) => state.BillingInfo);
  if (quoteUrl.shouldShow) {
    yield put(dismissBillingReminderAlertAction());
    yield put(
      NotificationsActions.requestNotifyUser({
        text: notificationContents['billingUser'],
      }),
    );
    window.open(quoteUrl.quote_url);
  } else {
    yield put(
      NotificationsActions.requestNotifyUser({
        text: 'no url found for create Quote.',
      }),
    );
  }
}

function* notifyBillingContactSaga()             {
  const { notifyBilling } = yield* select((state       ) => state.BillingInfo);
  if (notifyBilling.shouldShow) {
    try {
      yield* callPromise(notify_billing_contact.create, {});
      yield put(dismissBillingReminderAlertAction());
      yield put(
        NotificationsActions.requestNotifyUser({
          text: notificationContents['nonBillingUser'],
        }),
      );
    } catch (e) {
      yield put(dismissBillingReminderAlertAction());
      if (e.result && e.result.error && e.result.error.message) {
        yield put(
          NotificationsActions.requestNotifyUser({
            text: e.result.error.message,
          }),
        );
      } else {
        yield put(
          NotificationsActions.requestNotifyUser({
            text: 'There was a problem sending your notification.',
          }),
        );
      }
      if (!e.status || Number(e.status) !== 409) {
        Raven.captureException(e);
      }
    }
  } else {
    try {
      throw new Error(
        'notifyBillingContact() functions should not call for non billing user.',
      );
    } catch (e) {
      Raven.captureException(e);
    }
  }
}

function* uploadPoFileSaga(action   
            
               
                 
                 
       
    
     
 )             {
  const {
    poUpload,
    purchaseQuote,
    isCurrentUserBillingAdmin,
    name: accountName,
  } = yield* select((state       ) => state.BillingInfo);
  const { file, name, desc } = action.payload;
  if (
    (poUpload.shouldShow || purchaseQuote.shouldShow) &&
    isCurrentUserBillingAdmin
  ) {
    try {
      yield* callPromise(upload_po_file_to_google_storage, {
        file: file,
        name: name,
        purpose: `desc: ${desc}, account: ${accountName}`,
        size: file.size,
        origin: window.location.origin,
      });
      yield put(dismissBillingReminderAlertAction());
      yield put(
        NotificationsActions.requestNotifyUser({
          text: notificationContents['billingUser'],
        }),
      );
    } catch (e) {
      yield put(
        NotificationsActions.requestNotifyUser({
          text: 'Upload Failed. Unable to upload po file.',
        }),
      );
      Raven.captureException(e);
    }
  }
}

function* loadPurchaseQuoteSaga()             {
  yield all([
    put(purchaseQuoteAppListFetchRequest()),
    put(purchaseQuoteApiStatsFetchRequest()),
  ]);
}

function* purchaseQuoteAppListFetchRequestSaga()             {
  yield putResolve(paginationBeginRequest('quote_app_list', {}));

  const response = yield* callPromise(mobile_apps_list);
  if (response.mobile_apps) {
    // This assumes ENTERPRISE apps are counted as prod apps as they can have linked pre-prod apps.
    const newAppList = response.mobile_apps
      .filter(item => item.release_type !== MobileAppReleaseTypeValues.PRE_PROD)
      .map(item => {
        const { name, platform, id } = item;
        return {
          name,
          platform:
            platform === MobileAppPlatformValues.ANDROID ? 'Android' : platform,
          id: Number(id),
          checked: true,
        };
      });
    yield put(purchaseQuoteAppListFetchSucceed(newAppList));
  }

  if (response.pagination_information) {
    yield putResolve(
      paginationEndRequest(
        'quote_app_list',
        {},
        response.pagination_information,
      ),
    );
  }
}

function* purchaseQuoteApiStatsFetchRequestSaga()             {
  const userAccount = yield* callPromise(getUserAccount);
  if (userAccount.no_session_reason) {
    throw new Error('cannot fetch purchase quote stats if logged out');
  }
  const userSession = yield* callPromise(user_sessions.create, {
    session_token: userAccount.sessionId,
  });
  if (userSession) {
    const response = yield* callPromise(inventory_stats.list);
    if (response._type === 'error') {
      throw new Error(response.title);
    }
    if (response.body.inventory_stats && response.body.inventory_stats[0]) {
      const stats = response.body.inventory_stats[0].all_assets_stats;
      yield put(
        purchaseQuoteApiStatsFetchSucceed({
          restful_apis: stats.restful_apis_stats.assets_total_count,
          api_ops: stats.restful_apis_stats.api_operations_total_count,
          web_applications: stats.web_applications_stats.assets_total_count,
          dbs: stats.cloud_resources_stats.assets_total_count,
          serverless: stats.cloud_resources_stats.serverless_assets_count,
        }),
      );
    }
  }
}

export default function* watchForResourceFetching()             {
  yield all([
    takeEvery(
      createSubscriptionAction.toString(),
      createSubscriptionActionSaga,
    ),
    takeEvery(
      dismissBillingReminderAlertAction.toString(),
      dismissBillingReminderAlertSaga,
    ),
    takeEvery(redirectToPayNowAction.toString(), redirectToPayNowSaga),
    takeEvery(openQouteUrlAction.toString(), openQouteUrlSaga),
    takeEvery(notifyBillingContactAction.toString(), notifyBillingContactSaga),
    takeEvery(uploadPoFileAction.toString(), uploadPoFileSaga),
    takeEvery(loadPurchaseQuoteAction.toString(), loadPurchaseQuoteSaga),
    takeEvery(
      purchaseQuoteAppListFetchRequest.toString(),
      purchaseQuoteAppListFetchRequestSaga,
    ),
    takeEvery(
      purchaseQuoteApiStatsFetchRequest.toString(),
      purchaseQuoteApiStatsFetchRequestSaga,
    ),
  ]);
}
