//     

import { select as originalSelect } from 'redux-saga/effects';

export default function* select                 (
  fn                     ,
  props       ,
)          {
  // We want errors to propogate
  return yield originalSelect(fn, props); //eslint-disable-line redux-saga/no-unhandled-errors
}
