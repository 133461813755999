//      

import AssetHostedOnIcon from './AssetHostedOnIcon';
import React from 'react';
import { Tag } from '@dt/material-components';

              
                              
               
                  
                     
  

const TagHostedOn = ({ maxWidth, name, iconUrl, variant }       ) => (
  <Tag
    maxWidth={maxWidth}
    icon={<AssetHostedOnIcon iconUrl={iconUrl} name={name} />}
    label={`${variant === 'large' ? 'Hosted on ' : ''}${name}`}
  />
);

export default React.memo       (TagHostedOn);
