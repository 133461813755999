import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Button, DialogActions, DialogContent, Grid, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
import PolicyViolationExportToJiraDialogHeader from './PolicyViolationExportToJiraDialogHeader';
var PolicyViolationExportToJiraDialogStep1 = function (_a) {
    var handleClose = _a.handleClose, handleNext = _a.handleNext, jiraIntegration = _a.jiraIntegration, setJiraIntegration = _a.setJiraIntegration, integrations = _a.integrations, fetchMore = _a.fetchMore;
    return (React.createElement(React.Fragment, null,
        React.createElement(PolicyViolationExportToJiraDialogHeader, { title: "Export Policy Violation to Jira", handleClose: handleClose }),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mt: 2, mb: 2 },
                        React.createElement(Text, { variant: "body", component: "span" }, "Choose which Jira integration the policy violation should be exported to:"))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Select, { variant: "outlined", displayEmpty: true, value: jiraIntegration === null || jiraIntegration === void 0 ? void 0 : jiraIntegration.id, onChange: function (e) {
                            var integrationId = e.target.value;
                            var integration = integrations.find(function (_a) {
                                var id = _a.id;
                                return id === integrationId;
                            });
                            if (integration) {
                                setJiraIntegration(integration);
                            }
                        }, fullWidth: true },
                        [
                            React.createElement(MenuItem, { key: "select-header", value: "", disabled: true },
                                React.createElement(Text, { variant: "body", component: "span", mb: 1 }, "Choose Jira integration")),
                            integrations === null || integrations === void 0 ? void 0 : integrations.map(function (row) { return (React.createElement(MenuItem, { key: row.id, value: row.id },
                                React.createElement(Text, { variant: "body", component: "span", mb: 1 }, row.name))); }),
                        ],
                        fetchMore && (React.createElement(React.Fragment, null,
                            React.createElement(MenuItem, { dense: true },
                                React.createElement(Skeleton, { animation: "wave", width: "100%", height: 40 })),
                            React.createElement(Waypoint, { onEnter: function () {
                                    if (fetchMore) {
                                        fetchMore();
                                    }
                                } }))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: handleClose }, "Cancel"),
            React.createElement(Button, { onClick: handleNext, color: "primary", disabled: !jiraIntegration }, "Next"))));
};
export default PolicyViolationExportToJiraDialogStep1;
