import React from 'react';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Message } from '@components';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { scaleOrdinal } from '@visx/scale';
import PieChart from '../../../components/charts/PieChart';
var colorPalette = [
    '#00E190',
    '#7000FF',
    '#C89EFF',
    '#FA7E3D',
    '#2D8A63',
    '#38B000',
    '#76FFF7',
    '#A5206F',
    '#D1FF4C',
    '#FF7A00',
    '#FF9BE9',
];
var ordinalColorScale = scaleOrdinal({
    range: colorPalette,
});
var legendGlyphSize = 15;
var ApiShadowAssetsPageChartDonut = function (_a) {
    var data = _a.data, loading = _a.loading, error = _a.error;
    if (loading) {
        return React.createElement(Skeleton, null);
    }
    if (error) {
        React.createElement(Message, { variant: 'error', message: error.message, m: 0 });
    }
    var items = (data === null || data === void 0 ? void 0 : data.shadow_assets_stats.shadow_assets_stats) || [];
    var formatted = items.map(function (a) { return ({
        name: a.shadow_reason_name,
        value: a.shadow_assets_count,
        color: ordinalColorScale(a.shadow_reason_name),
    }); });
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { item: true, xs: 6 },
            React.createElement(ParentSize, null, function (_a) {
                var width = _a.width;
                return React.createElement(PieChart, { height: 175, width: width, text: '', subText: 'Shadow Assets', data: formatted });
            })),
        React.createElement(Grid, { item: true, xs: 6 },
            React.createElement(LegendOrdinal, { scale: ordinalColorScale, labelFormat: function (label) { return "".concat(label); } }, function (labels) { return (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } }, labels.map(function (label, i) { return (React.createElement(LegendItem, { key: "legend-quantile-".concat(i), margin: "0 5px" },
                React.createElement("svg", { width: legendGlyphSize, height: legendGlyphSize },
                    React.createElement("rect", { fill: label.value, width: legendGlyphSize, height: legendGlyphSize })),
                React.createElement(LegendLabel, { align: "left", margin: "0 0 0 4px" }, label.text))); }))); }))));
};
export default ApiShadowAssetsPageChartDonut;
