//      
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
             
            
                    
                               
                             
                 

const api = config.horizonApiBaseUrl;
const version = 'v1';

export const SpecialScanRequestTypeEnum = {
  HACK_AND_EXTRACT: 'HACK_AND_EXTRACT',
  LEAKY_APIS: 'LEAKY_APIS',
  DETECT_AND_INJECT: 'DETECT_AND_INJECT',
  SPA_DETECT_AND_INJECT: 'SPA_DETECT_AND_INJECT',
  XSS_PROTECTION: 'XSS_PROTECTION',
};

                                                                              

export const SpecialScanRequestStatusEnum = {
  NOT_STARTED: 'NOT_STARTED',
  ONGOING: 'ONGOING',
  FINISHED: 'FINISHED',
};

                                             
                                      
  

                                  
             
                       
                          
                             
                                           
                                    
                       
                                                        
                                                          
       
    
  

                                                                        

                                               
                                                
                   
   

export async function get(
  type                        ,
)                                                {
  return fetch(`${api}/${version}/special_scan_requests/${type}`).then(
    response => {
      if (response.status === 404) {
        // This endpoint is special as when this type hasn't been created yet,
        // it returns 404
        return {
          _type: 'response',
          status: response.status,
          ok: true,
          body: {
            special_scan_requests: [],
            users: [],
          },
        };
      }

      return parse(response);
    },
  );
}

export async function list(
  type                        ,
)                                                {
  return fetch(
    `${api}/${version}/special_scan_requests?scan_type=${type}`,
  ).then(response => {
    if (response.status === 404) {
      // This endpoint is special as when this type hasn't been created yet,
      // it returns 404
      return {
        _type: 'response',
        status: response.status,
        ok: true,
        body: {
          special_scan_requests: [],
          users: [],
        },
      };
    }

    return parse(response);
  });
}

                              
                                                          
   

                                
                                                                
                                
   

                                           
                                                                 
                        
                                                       
     
   

                                              
                                                                     
                        
                                           
                                                         
     
   

                               
                        
                          
                              
                                  

export async function create(
  params                          ,
)                                                {
  return fetch(`${api}/${version}/special_scan_requests`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

                                                     
                                                             
   

export async function list_pii_reports_in_api_responses()                
                                                          
  {
  return fetch(
    `${api}/${version}/special_scan_requests/hack_and_extract/pii_reports_in_responses`,
  ).then(parse);
}
