//     
import React, { memo } from 'react';
import isAfter from 'date-fns/is_after';
import { Grid, Card, CardContent } from '@mui/material';
import { PolicyViolationsCommentsSectionHeader } from './PolicyViolationsCommentsSectionHeader';
import { PolicyViolationsCommentsSectionBody } from './PolicyViolationsCommentsSectionBody';
                                  

                
               
                         
                 
                                
                 
     
  

const sortComments = function sortComments(a         , b         ) {
  if (!a.date) {
    return 1;
  }

  if (!b.date) {
    return -1;
  }

  if (isAfter(a.date, b.date)) {
    return 1;
  } else {
    return -1;
  }
};

              
                                
                                      
                                   
                              
                  
                     
  

// Render content as:
// - card
// - section
const Content = ({ variant, children }                                                  ) => {
  if (variant === 'card') {
    return (
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    );
  }
  return children;
};

/*
 * Policy violation "section" used to render the latest comment and provide the user the ability to
 * open the policy violation comments workflow.
 *
 * @param variant - Render content variant.
 * @param comments - Comments of a policy violation, used to show how many comments exist
 * @param isCommentsWorkflowOpen - Whether or not the comments column is open or not
 * @param onOpenWorkflow - Triggered event when the user clicks "Appsec Advice/Internal Notes"
 */
const PolicyViolationsCommentsSectionComponent = function PolicyViolationsCommentsSection({
  variant,
  comments,
  isCommentsWorkflowOpen,
  onOpenWorkflow,
  isV2,
  hideTag = false,
}       ) {
  const sortedComments = comments ? comments.slice().sort(sortComments) : [];
  const latestComment                 = sortedComments[sortedComments.length - 1];

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PolicyViolationsCommentsSectionHeader
          sortedComments={sortedComments}
          onOpenWorkflow={onOpenWorkflow}
          isV2={isV2}
        />
      </Grid>

      <Grid item xs={12}>
        <Content variant={variant}>
          <PolicyViolationsCommentsSectionBody
            hideTag={hideTag}
            sortedComments={sortedComments}
            latestComment={latestComment}
            isCommentsWorkflowOpen={isCommentsWorkflowOpen}
            onOpenWorkflow={onOpenWorkflow}
            isV2={isV2}
          />
        </Content>
      </Grid>
    </Grid>
  );
};

export const PolicyViolationsCommentsSection = memo       (PolicyViolationsCommentsSectionComponent);
