//     
import { sum, sumBy, flow, map, reduce } from 'lodash/fp';

import {
                           
                             
} from './../mobile_apps';

const metadataNameLookup   
                
                                                               
        
    
      
  = {
  OPEN: {
    P1: ['open_security_finding_security_p1_count'],
    HIGH: ['open_security_finding_high_count'],
    MEDIUM: ['open_security_finding_medium_count'],
    LOW: ['open_security_finding_low_count'],
    APPLE_BLOCKER: ['open_security_finding_apple_app_store_blocker_count'],
    GOOGLE_BLOCKER: ['open_security_finding_google_play_blocker_count'],
    COMPLIANCE: ['open_security_finding_regulatory_compliance_count'],
    SDK: [
      'open_sdk_finding_with_open_issues_count',
      'open_sdk_finding_with_open_issues_beyond_subscription_level_count',
    ],
    INSIGHT: ['open_sdk_finding_count', 'open_insight_finding_count'],
    ALL: ['open_security_finding_count'],
  },
  CLOSED: {
    P1: ['closed_security_finding_security_p1_count'],
    HIGH: ['closed_security_finding_high_count'],
    MEDIUM: ['closed_security_finding_medium_count'],
    LOW: ['closed_security_finding_low_count'],
    APPLE_BLOCKER: ['closed_security_finding_apple_app_store_blocker_count'],
    GOOGLE_BLOCKER: ['closed_security_finding_google_play_blocker_count'],
    COMPLIANCE: ['closed_security_finding_regulatory_compliance_count'],
    SDK: [],
    INSIGHT: [],
    ALL: ['closed_security_finding_count'],
  },
  SHADOW: {
    P1: ['open_security_finding_security_p1_beyond_subscription_level_count'],
    HIGH: ['open_security_finding_high_beyond_subscription_level_count'],
    MEDIUM: ['open_security_finding_medium_beyond_subscription_level_count'],
    LOW: ['open_security_finding_low_beyond_subscription_level_count'],
    APPLE_BLOCKER: [
      'open_security_finding_apple_app_store_blocker_beyond_subscription_level_count',
    ],
    GOOGLE_BLOCKER: [
      'open_security_finding_google_play_blocker_beyond_subscription_level_count',
    ],
    COMPLIANCE: [
      'open_security_finding_regulatory_compliance_beyond_subscription_level_count',
    ],
    SDK: ['open_sdk_finding_with_open_issues_beyond_subscription_level_count'],
    INSIGHT: [
      'open_sdk_finding_beyond_subscription_level_with_open_issues_beyond_subscription_level_count',
      'open_sdk_finding_with_open_issues_beyond_subscription_level_count',
    ],
    ALL: ['open_security_finding_beyond_subscription_level_count'],
  },
};

const parseCount = function(metadata, name) {
  const value = parseInt(metadata[name], 10);
  if (Number.isNaN(value)) throw new Error(`${name} for metadata is NaN.`);
  return value;
};

                   
        
          
            
         
                   
                    
                
         
             
          

                                              

const count = (
  metadata                                                             ,
  issueType           ,
  categoryType              ,
) => {
  if (metadata instanceof Array) {
    return sumBy(`${issueType}.${categoryType}`)(metadata);
  }

  const categoryNameLookup = metadataNameLookup[issueType];
  if (!categoryNameLookup) {
    throw new Error(
      `No category metadata lookup found for issue type: ${issueType}`,
    );
  }

  const metadataNames = categoryNameLookup[categoryType];
  if (!(metadataNames instanceof Array)) {
    throw new Error(
      `No category metadata names for category type: ${categoryType}`,
    );
  }

  return flow(
    map(name => parseCount(metadata, name)),
    sum,
  )(metadataNames);
};

const counter = (
  metadata                                                             ,
  issueType                              ,
) => {
  return reduce((counts, categoryType) => {
    const current = count(metadata, issueType, categoryType);
    counts[categoryType] = current;
    return counts;
  }, {});
};

const createMetadataCount = (
  metadata                                                             ,
) => {
  return reduce((obj, issueType) => {
    try {
      obj[issueType] = counter(
        metadata,
        issueType,
      )([
        'P1',
        'HIGH',
        'MEDIUM',
        'LOW',
        'APPLE_BLOCKER',
        'GOOGLE_BLOCKER',
        'COMPLIANCE',
        'SDK',
        'INSIGHT',
        'ALL',
      ]);
    } catch (e) {
      if (!obj['ERRORS']) obj['ERRORS'] = [];
      obj['ERRORS'].push(e);
      obj[issueType] = null;
    }

    return obj;
  }, {})(['OPEN', 'CLOSED', 'SHADOW']);
};

export const getMetadataCount = (
  metadata                      ,
)                        => {
  if (!(metadata instanceof Array) && !metadata) {
    return {
      OPEN: null,
      CLOSED: null,
      SHADOW: null,
      ERRORS: [new Error('No metadata provided.')],
    };
  }

  return createMetadataCount(metadata);
};

export const getTotalMetadataCount = (
  metadataCounts                                       ,
)                        => {
  return createMetadataCount(metadataCounts);
};
