//      


import { select } from '@dt/redux-saga-wrapped-effects';

                                                 
export function* isUserViewingVisualization()                {
  const { isViewingVisualization } = yield* select((state       ) => state.dashboard);
  return isViewingVisualization;
}
