//      
import config from '@dt/config';
import qs from 'query-string';
import fetch, { parse } from '@dt/fetch';
import {
                                    
                           
                      
                          
                  
                 
                    
                            
                       
                           
                          
                         
                       
                         
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                            
                                          
                                
                                         
                    
                                         
                                       
                                 
                                     
   

                                               
                                 
                      
                          
                                            
                                 
                                         
                                       
                                 
                                     
   

                                           
                                    
                                                 
                   
   

export async function list(
  params                            ,
)                                                                {
  return fetch(
    `${api}/${version}/policy_violations?${qs.stringify(params || {})}`,
  ).then(parse);
}

export async function details(
  id        ,
)                                                {
  return fetch(`${api}/${version}/policy_violations/${id}`).then(parse);
}

                                          
                                               
                                 
     
  

export async function patch(
  id        ,
  payload                            ,
)                                                {
  return fetch(`${api}/${version}/policy_violations/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(payload),
  }).then(parse);
}

                                                         

                                     
                          
                    
   

export const comments = {
  list: async function(
    PolicyViolationId        ,
  )                                      {
    return fetch(
      `${api}/${version}/policy_violations/${PolicyViolationId}/comments`,
    ).then(parse);
  },

  insert: async function(
    policyViolationId        ,
    payload                   ,
  ) {
    return fetch(
      `${api}/${version}/policy_violations/${policyViolationId}/comments`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      },
    ).then(parse);
  },

  delete: async function(PolicyViolationId        , commentId        ) {
    return fetch(
      `${api}/${version}/policy_violations/${PolicyViolationId}/comments/${commentId}`,
      {
        method: 'DELETE',
      },
    ).catch(response => response.json());
  },
};
