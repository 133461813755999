//      
import React, { memo, forwardRef } from 'react';
                                  

              
                  
                                                   
             
                 
     
  

function ExtLink(
  { to, children, target = '_blank', style, ...rest }       ,
  ref,
) {
  return (
    <a
      {...rest}
      ref={ref}
      href={to}
      target={target}
      rel="noopener noreferrer"
      style={{ outline: 0, ...style }}
    >
      {children}
    </a>
  );
}

export default memo       (forwardRef          (ExtLink));
