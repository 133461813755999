//      
                                                    

                                                                 
                                                           

export const DETECT_INJECT_POLICY_RULE_TYPE_ID = '92eb7e9e-9daa-5922-8167-6a219e0f7287';

                         
      
                     
                             
      
      
                    
                       
                              
                             
       

                        
      
                     
      
      
                    
                       
       

                        
      
                     
      
      
                    
                       
       

                             
                   
   

                                        
               
                
               
                  
                 
                   
                
                   
                 
                                           
     
  

                                         
                  
                    
                     
                 
   

                                                                                     

                                         
                              
                       
   

                                                                                    

                     
      
                       
                              
      
      
                        
       

                                                   
                  
                          
                
                   
                    
                             
   

                                                                                                 

                                                                                      
