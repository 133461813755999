//      

import React, { memo } from 'react';
import { TableCell } from '@mui/material';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import WebAppIcon from '@mui/icons-material/Web';
import Box from '@mui/material/Box';
import { Tag } from '@dt/material-components';
                                                                                  

              
                                                                          
  

const InventoryTableCellRelatedAppsCount = ({ included_supply_chain_secure_details }) => {
  return (
    <TableCell>
      {included_supply_chain_secure_details && (
        <Box display={'flex'}>
          <Tag
            tooltipText={'Related Mobile Apps'}
            label={String(included_supply_chain_secure_details.related_mobile_apps_count || 0)}
            icon={<SmartphoneIcon fontSize={'small'} />}
          />
          <Tag
            tooltipText={'Related Web Apps'}
            label={String(included_supply_chain_secure_details.related_web_apps_count || 0)}
            icon={<WebAppIcon fontSize={'small'} />}
          />
        </Box>
      )}
    </TableCell>
  );
};

export default memo       (InventoryTableCellRelatedAppsCount);
