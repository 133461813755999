//     
import createKeyable from '@dt/keyable';

import {
  openScanSearchNameSelected,
  openscanErrorOccurred,
  openscanRuleUpdated,
  addAppToOpenscanWatchListGroup,
  removeAppFromOpenscanWatchListGroup,
  removeAppFromOpenscanWatchList,
} from '../actions';

const payload = (_, { payload }) => payload;

export default createKeyable({
  keys: {
    openscan: {
      [openscanErrorOccurred.toString()]: payload,
      [openscanRuleUpdated.toString()]: null,
      [addAppToOpenscanWatchListGroup.toString()]: null,
      [removeAppFromOpenscanWatchListGroup.toString()]: null,
      [removeAppFromOpenscanWatchList.toString()]: null,
      [openScanSearchNameSelected.toString()]: null,
    },
  },
});
