//     
import createKeyable from '@dt/keyable';

import {
  apiKeyEditResponseReceived,
  apiKeyErrorReceived,
  userEditError,
  userEditReceived,
  userInvitedError,
  userInvitedReceived,
  apiKeyCreateResponseReceived,
} from '../actions';
import { linkedIssuesRoutine } from '../actions/securityFindings';

const payload = (_, { payload }) => payload;

export default createKeyable({
  keys: {
    apiKeyEdit: {
      [apiKeyErrorReceived.toString()]: payload,
      [apiKeyEditResponseReceived.toString()]: null,
    },
    apiKeyCreate: {
      [apiKeyErrorReceived.toString()]: payload,
      [apiKeyCreateResponseReceived.toString()]: null,
    },
    userInvite: {
      [userInvitedError.toString()]: payload,
      [userInvitedReceived.toString()]: null,
    },
    userEdit: {
      [userEditError.toString()]: payload,
      [userEditReceived.toString()]: null,
    },
    linkedIssues: {
      [linkedIssuesRoutine.REQUEST.toString()]: null,
      [linkedIssuesRoutine.FAILURE.toString()]: false,
    },
  },
});
