//     
import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Text } from '@dt/material-components';
import { CompliancePolicyValues } from '@dt/graphql-support/enums';
                                                                  
import Box from '@mui/material/Box';

                                                        
                                   
      
  

export const PopoutFilterPluginCompliancePolicyDisplayLookup         
                                                          
  = {
  [CompliancePolicyValues.CALOPPA]: 'CalOPPA',
  [CompliancePolicyValues.CCPA]: 'CCPA',
  [CompliancePolicyValues.COPPA]: 'COPPA',
  [CompliancePolicyValues.FTC]: 'FTC',
  [CompliancePolicyValues.GDPR]: 'GDPR',
  [CompliancePolicyValues.HIPAA]: 'HIPAA',
  [CompliancePolicyValues.MITRE]: 'MITRE ATT&CK',
  [CompliancePolicyValues.OWASP]: 'OWASP Top 10',
  [CompliancePolicyValues.OWASP_MSTG]: 'OWASP MSTG',
  [CompliancePolicyValues.OWASP_ASVS]: 'OWASP ASVS',
  [CompliancePolicyValues.PCI]: 'PCI',
  [CompliancePolicyValues.VPPA]: 'VPPA',
};
              
                                                   
                                                                                                                     
  

/*
 * Popout filter plugin.
 * User can select compliance policies.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
export const PopoutFilterPluginCompliancePolicy = ({ value, onChange }       ) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginCompliancePolicyDisplayLookup).map(key => {
        const val = PopoutFilterPluginCompliancePolicyDisplayLookup[key];
        return (
          <FormControlLabel
            key={key}
            label={<Text variant="body">{val}</Text>}
            control={
              <Checkbox
                color="primary"
                inputProps={{
                  'aria-label': `${val}`,
                }}
                checked={!!value[key]}
                onChange={e => {
                  (value =>
                    onChange(prev => ({
                      ...prev,
                      // $FlowFixMe - string -> enum
                      [key]: value,
                    })))(e.target.checked);
                }}
              />
            }
          />
        );
      })}
    </Box>
  );
};
