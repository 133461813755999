//     
import React, { memo } from 'react';
import { Grid, Typography, Tooltip } from '@mui/material';
import { Label } from '@dt/material-components';
import WarningIcon from '@mui/icons-material/Warning';
import FlagIcon from '@mui/icons-material/AssistantPhoto';
import InfoIcon from '@mui/icons-material/Info';

              
                            
                                                   
                                                   
                                                
    
  

export default memo       (function AssetsOpenPolicyViolationsCountCardTagStrips({ policy_violations_count }       ) {
  const {
    open_important_policy_violations_count,
    open_proactive_policy_violations_count,
    open_urgent_policy_violations_count,
  } = policy_violations_count;

  return (
    <Grid container spacing={2}>
      {/** urgent policy violation counts */}
      <Tooltip disableInteractive title={`${open_urgent_policy_violations_count} open Urgent policy violations`}>
        <Grid item>
          <Label variant={open_urgent_policy_violations_count > 0 ? 'red' : 'gray'}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{
                color: 'inherit',
              }}
            >
              <Grid item>
                <WarningIcon fontSize="inherit" />
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  aria-label="policy-violation-urgent-total-count"
                  style={{ color: 'inherit' }}
                >
                  {open_urgent_policy_violations_count}
                </Typography>
              </Grid>
            </Grid>
          </Label>
        </Grid>
      </Tooltip>

      {/** important policy violation counts */}
      <Tooltip disableInteractive title={`${open_important_policy_violations_count} open Important policy violations`}>
        <Grid item>
          <Label variant={open_important_policy_violations_count > 0 ? 'yellow' : 'gray'}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{
                color: 'inherit',
              }}
            >
              <Grid item>
                <FlagIcon fontSize="inherit" />
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  aria-label="policy-violation-important-total-count"
                  style={{ color: 'inherit' }}
                >
                  {open_important_policy_violations_count}
                </Typography>
              </Grid>
            </Grid>
          </Label>
        </Grid>
      </Tooltip>

      {/** proactive policy violation counts */}
      <Tooltip disableInteractive title={`${open_proactive_policy_violations_count} open Proactive policy violations`}>
        <Grid item>
          <Label variant={open_proactive_policy_violations_count > 0 ? 'blue' : 'gray'}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              style={{
                color: 'inherit',
              }}
            >
              <Grid item>
                <InfoIcon fontSize="inherit" />
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  aria-label="policy-violation-proactive-total-count"
                  style={{ color: 'inherit' }}
                >
                  {open_proactive_policy_violations_count}
                </Typography>
              </Grid>
            </Grid>
          </Label>
        </Grid>
      </Tooltip>
    </Grid>
  );
});
