var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Paper } from '@mui/material';
var PaperContainer = function (_a) {
    var children = _a.children, style = _a.style, _b = _a.noPadding, noPadding = _b === void 0 ? false : _b;
    return (React.createElement(Paper, { style: __assign({ borderRadius: 8, padding: noPadding ? 0 : 16, boxShadow: 'none' }, style) }, children));
};
export default PaperContainer;
