//     
import React, { memo } from 'react';
import startCase from 'lodash/startCase';
import numeral from 'numeral';

                                                                                           
                                                                                 
                                                                         
                                                                            
                                                                   
import { isClosedStatus, isNeutralStatus, sortTargets } from '@dt/findings/targets/status';
import Skeleton from '@mui/material/Skeleton';

import CardWithTargets from './../CardWithTargets';
import IconLabel from './../IconLabel';
import PreviewInline from './../PreviewInline';

import CardStatusLineForTarget from './CardStatusLineForTarget';
import CardSeeMoreSimple from './CardSeeMoreSimple';
import {
  getBadgeDescriptionFromBadgeType,
  getCompletedAppProtectionBadgeKeyByBadgeTitle,
  getIncompleteAppProtectionBadgeKeyByBadgeTitle,
                        
} from './../app-protection-badges/util';

const visibleTargetLimit = 3;

              
             
                
                                             
                                         
                                 
                                  
                           
                                 
                                                                          
                                
                                                           
                    
  

                                          
                   
                                  
                                        
                                     
  

export default memo       (function SecurityFindingCard({
  id,
  title,
  aggregated_status,
  severity,
  priority,
  targets,
  numberOfComments,
  hasMediawatchArticles,
  compliance_policy_references,
  app_protection_score,
  app_info,
  preview,
}) {
  const indicatorType = isNeutralStatus(aggregated_status)
    ? 'neutral'
    : isClosedStatus(aggregated_status)
    ? 'good'
    : 'bad';

  const sortedTargets = targets && targets.slice().sort(sortTargets);
  const visibleTargets =
    sortedTargets &&
    sortedTargets.slice(0, sortedTargets.length > visibleTargetLimit ? visibleTargetLimit - 1 : visibleTargetLimit);

  const app_protection_percent =
    !app_info.loading && app_protection_score
      ? numeral(parseInt(app_protection_score, 10) / parseInt(app_info.app_max_app_protection_score, 10)).format('0%')
      : null;

  // App Protection
  const completedAppProtectionBadgeKey = !app_info.loading
    ? getCompletedAppProtectionBadgeKeyByBadgeTitle(title, app_info.app_badge_fields)
    : null;
  const incompleteAppProtectionBadgeKey = !app_info.loading
    ? getIncompleteAppProtectionBadgeKeyByBadgeTitle(title, app_info.app_badge_fields)
    : null;
  const badgeType = completedAppProtectionBadgeKey || incompleteAppProtectionBadgeKey;
  const badgeDescription = badgeType && getBadgeDescriptionFromBadgeType(badgeType);

  const app_name_with_icon = app_info.loading ? (
    <Skeleton animation="wave" height={16} width={200} />
  ) : (
    <IconLabel platform={app_info.app_platform}>{app_info.app_name}</IconLabel>
  );

  return (
    <CardWithTargets
      text={[
        preview ? <PreviewInline>${title}</PreviewInline> : title,
        id && `#${id}`,
        app_name_with_icon,
        typeof severity === 'string' ? `Severity: ${startCase(severity.toLowerCase())}` : null,
      ]}
      indicator={indicatorType}
      priority={priority}
      commentCount={numberOfComments}
      compliance_policy_references={compliance_policy_references}
      mediawatchPresent={hasMediawatchArticles}
      badge={app_protection_percent && `+${app_protection_percent} Protections`}
      appProtectionBadgeTitle={badgeDescription}
      appProtectionBadgeAchieved={!!completedAppProtectionBadgeKey}
    >
      {visibleTargets &&
        visibleTargets.map(target => <CardStatusLineForTarget key={target.id} target={target} preview={preview} />)}
      {sortedTargets && sortedTargets.length > visibleTargetLimit && (
        <CardSeeMoreSimple hack>Click to see {sortedTargets.length - visibleTargetLimit + 1} more</CardSeeMoreSimple>
      )}
    </CardWithTargets>
  );
});
