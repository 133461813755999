//     
import { createAction } from 'redux-actions';

export const graphqlApisReceived = createAction 
                          
                   
   
                                 
       
    
 ('graphql_apis/received', graphql_apis => ({
  graphql_apis,
}));
