//      
import React, { memo } from 'react';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
                                                  

              
                   
                    
                
                            
                                                                         
  

const InventoryTableFilterKeywordComponent = function InventoryTableFilterKeyword({
  filters,
  onChange,
  onKeyPress,
  filterKey,
  label,
}       ) {
  const handleChangeKeyword = value => {
    const { ...rest } = filters;
    onChange({
      ...rest,
      [filterKey]: value || null,
    });
  };

  return (
    <TextField
      fullWidth
      value={filters?.[filterKey] || ''}
      onChange={e => handleChangeKeyword(e.target.value)}
      onKeyPress={onKeyPress}
      id="inventory-filter-keyword"
      label={label}
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
    />
  );
};

export default memo       (InventoryTableFilterKeywordComponent);
