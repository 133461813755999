//     
import React from 'react';
import { PolicyViolationsStatusesTag } from './PolicyViolationsStatusesTag';
import Box from '@mui/material/Box';

const containerStyle = {
  display: 'flex',
  alignItems: 'flex-end',
  width: 'fit-content',
  marginLeft: -8, // counteract padding on first child element
};

              
                  
                     
                     
  

export const PolicyViolationsStatusesTagStripCounts = ({ urgent, important, proactive }       ) => {
  return (
    <div style={containerStyle}>
      <Box mr={1}>
        <PolicyViolationsStatusesTag count={urgent} type="urgent" />
      </Box>
      <Box mr={1}>
        <PolicyViolationsStatusesTag count={important} type="important" />
      </Box>
      <PolicyViolationsStatusesTag count={proactive} type="info" />
    </div>
  );
};
