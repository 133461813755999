//     
import gql from 'graphql-tag';

const get = gql`
  query PolicyViolationsGetQuery($id: ID) {
    policy_violation_by_id(id: $id) {
      policy_violations {
        id
        date_created
        date_resolved
        exception_date_created
        exception_type
        additional_info
        status
        affected_api_operation_id
        remediation {
          remediation_instructions
          remediation_url
        }

        violated_policy_rule_id
        violated_policy_rule {
          id
          policy_rule_type {
            id
          }
        }
        affected_web_application {
          id
          base_url
          asset_type_name
          asset_type_icon_url
          most_recent_analysis_result {
            title
          }
          network_service {
            id
            hosted_on
          }
        }
        affected_network_service {
          id
          domain_name {
            id
            name
          }
          hosted_on
          asset_type_name
          asset_type_icon_url
        }
        affected_graphql_api {
          id
          url
          asset_type_name
          asset_type_icon_url
          hosted_on
        }
        affected_api_operation {
          id
          path
          http_method
          restful_api {
            id
            title
            base_url
            asset_type_name
            asset_type_icon_url
            network_service {
              id
              hosted_on
            }
          }
        }
        affected_cloud_resource {
          id
          name
          url
          asset_type_name
          asset_type_icon_url
          hosted_on
          cloud_console_url
        }
        affected_graphql_api {
          id
          url
          asset_type_name
          asset_type_icon_url
          hosted_on
        }
      }
      policy_rules {
        id
        relevance
      }
      policy_rule_types {
        id
        title
        description
        recommendation
      }
    }
  }
`;

const list = gql`
  query PolicyViolationsListQuery(
    $cursor: String
    $filter_by_policy_rule_type_id: [ID]
    $is_eligible_for_auto_remediation: Boolean
    $filter_by_violation_status: [PolicyViolationStatusEnum]
    $was_discovered_after: Date
    $filter_by_importance_tag: [FilterByImportanceTagEnum]
    $filter_by_product: [FilterByProductEnum]
  ) {
    policy_violations_list(
      cursor: $cursor
      is_eligible_for_auto_remediation: $is_eligible_for_auto_remediation
      filter_by_policy_rule_type_id: $filter_by_policy_rule_type_id
      filter_by_violation_status: $filter_by_violation_status
      was_discovered_after: $was_discovered_after
      filter_by_importance_tag: $filter_by_importance_tag
      filter_by_product: $filter_by_product
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      policy_violations {
        id
        additional_info
        affected_api_operation_id
        date_resolved
        date_created
        exception_type
        exception_date_created
        violated_policy_rule_id
        status
        violated_policy_rule {
          id
          relevance
          policy_rule_type {
            id
            title
            compliance_policy_references {
              compliance_standard
              compliance_standard_criteria
              description
              link
            }
          }
        }
        affected_restful_api {
          id
          title
          base_url
          asset_type_name
          asset_type_icon_url
          network_service {
            id
            hosted_on
          }
        }
        affected_api_operation {
          id
          http_method
          path
          restful_api {
            id
            title
            base_url
            asset_type_name
            asset_type_icon_url
            network_service {
              id
              hosted_on
            }
          }
        }
        affected_cloud_resource {
          id
          asset_type_name
          url
        }
        affected_graphql_api {
          id
          url
          asset_type_name
          asset_type_icon_url
          hosted_on
        }
        affected_network_service {
          id
          hosted_on
          url
          hosted_on
          asset_type_icon_url
          asset_type_name
          domain_name {
            name
          }
        }
        affected_web_application {
          id
          base_url
        }
      }
      restful_apis {
        id
        title
        base_url
        asset_type_name
        asset_type_icon_url
        date_created
        discovered_via
        api_operations {
          id
          http_method
          path
        }
        network_service {
          id
          hosted_on
        }
      }
    }
  }
`;

const v2_get = gql`
  query PolicyViolationGetVersion2($id: ID, $include: String) {
    policy_violation_details_v2(id: $id, include: $include) {
      id
      date_created
      date_resolved
      date_last_checked
      exception_type
      additional_info
      status
      exception_date_created
      exception_explanation
      custom_description
      custom_recommendation
      custom_secure_code
      custom_performed_attack
      custom_expected_response
      custom_real_response
      affected_asset_details
      external_integrations {
        id
        external_key
        external_url
        integration_icon_url
        integration_type
      }
      auto_remediation_info {
        remediation_instructions
        remediation_url
      }
      http_trace {
        http_request
        http_response
      }
      affected_asset {
        id
        name
        url
        cloud_console_url
        asset_type
        asset_type_icon_url
        asset_type_name
        date_created
        hosted_on
        aws_arn
        belongs_to_asset_group {
          id
          name
        }
        belongs_to_cloud_account {
          id
          cloud_account_icon_url
          cloud_account_type_name
          discovered_via_cloud_authenticator_id
          customer_supplied_name
        }
      }
      violated_policy_rule {
        id
        policy_rule_type {
          id
          title
          description
          recommendation
          secure_code
          default_relevance
          screenshot_url
          compliance_policy_references {
            compliance_standard
            compliance_standard_criteria
            description
            link
          }
          related_rule_types {
            id
            title
            description
            recommendation
            default_relevance
            compliance_policy_references {
              compliance_standard
              compliance_standard_criteria
              description
              link
            }
          }
        }
        relevance
        severity
      }
      included_sast_issue_summary {
        code_owner_name
        code_owner_email
        detected_in_branch_name
        detected_in_branch_url
        detected_in_file_name
        detected_in_file_path
        detected_on_line
        issue_code_snippet
        see_issue_in_platform_url
      }
    }
  }
`;

const v2_list = gql`
  query PolicyViolationsListQueryVersion2(
    $cursor: String
    $order_by: String
    $include: String
    $was_discovered_after: Date
    $filter_by_importance_tag: [FilterByImportanceTagEnum]
    $filter_by_product: [FilterByProductEnum]
    $filter_by_violation_status: [PolicyViolationStatusEnum]
    $filter_by_relevance: [String]
    $filter_by_asset_group_id: [ID]
    $filter_by_asset_ownership: String
    $filter_by_related_first_party_asset_ids: [ID]
    $filter_by_no_asset_group: Boolean
    $filter_by_asset_vendor_ids: [String]
    $filter_by_policy_rule_id: [ID]
    $filter_by_policy_rule_type_id: [ID]
    $filter_by_ongoing_triaging_effort: Boolean
    $is_eligible_for_auto_remediation: Boolean
    $filter_by_named_filter: String
    $filter_by_has_policy_violations_created_on_month: String
  ) {
    policy_violations_list_v2(
      cursor: $cursor
      order_by: $order_by
      include: $include
      was_discovered_after: $was_discovered_after
      filter_by_importance_tag: $filter_by_importance_tag
      filter_by_product: $filter_by_product
      filter_by_violation_status: $filter_by_violation_status
      filter_by_relevance: $filter_by_relevance
      filter_by_asset_group_id: $filter_by_asset_group_id
      filter_by_asset_ownership: $filter_by_asset_ownership
      filter_by_related_first_party_asset_ids: $filter_by_related_first_party_asset_ids
      filter_by_no_asset_group: $filter_by_no_asset_group
      filter_by_asset_vendor_ids: $filter_by_asset_vendor_ids
      filter_by_policy_rule_id: $filter_by_policy_rule_id
      filter_by_policy_rule_type_id: $filter_by_policy_rule_type_id
      filter_by_ongoing_triaging_effort: $filter_by_ongoing_triaging_effort
      is_eligible_for_auto_remediation: $is_eligible_for_auto_remediation
      filter_by_named_filter: $filter_by_named_filter
      filter_by_has_policy_violations_created_on_month: $filter_by_has_policy_violations_created_on_month
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      policy_violations {
        id
        affected_asset {
          id
          name
          url
          asset_type
          asset_type_icon_url
          asset_type_name
          date_created
          hosted_on
          api_operation_authentication_scheme
        }
        affected_asset_id
        affected_asset_name
        affected_asset_type
        affected_asset_type_name
        affected_asset_type_icon_url
        affected_asset_hosted_on
        date_created
        date_resolved
        status
        additional_info
        opened_by_certificate_chain_id
        exception_type
        exception_explanation
        exception_date_created
        violated_policy_rule {
          id
          relevance
          policy_rule_type {
            id
            title
            description
            compliance_policy_references {
              compliance_standard
              compliance_standard_criteria
              description
              link
            }
          }
        }
      }
    }
  }
`;

const patch = gql`
  mutation PolicyViolationsPatchMutation($id: ID!, $body: PolicyViolationsPatchParams!) {
    policy_violations_patch(id: $id, body: $body) {
      policy_violations {
        id
        status
        exception_type
        exception_explanation
        exception_date_created
      }
    }
  }
`;

export default {
  get,
  list,
  v2_get,
  v2_list,
  patch,
};
