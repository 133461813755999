//     
import gql from 'graphql-tag';

const list = gql`
  query ComplianceStandardCriteriaList($filter_by_named_filter: String, $filter_by_text: String) {
    compliance_standard_criteria_list(
      filter_by_named_filter: $filter_by_named_filter
      filter_by_text: $filter_by_text
    ) {
      compliance_standard_criteria {
        compliance_standard
        criterion
        description
        title
        link
        referenced_by_policy_rule_type_ids
      }
    }
  }
`;

export const ComplianceCategories = {
  OWASP_A: 'OWASP_A',
  OWASP_M: 'OWASP_M',
  OWASP_W: 'OWASP_W',
  OWASP_R: 'OWASP_R',
  OWASP_MSTG: 'OWASP_MSTG',
  OWASP_ASVS: 'OWASP_ASVS',
  PCI_DSS: 'PCI_DSS',
  NIST_800_53: 'NIST_800_53',
  FFIEC_VC2: 'FFIEC_VC2',
  CIS_BENCHMARK: 'CIS_BENCHMARK',
  FEDRAMP_V5_API_SECURITY: 'FEDRAMP_V5_API_SECURITY',
};

export default {
  list,
};
