//     
import createReducerCreator from 'rezz/createReducerCreator';
import set from 'rezz/set';
import { createAction, handleActions } from 'redux-actions';
import { appFileSubmitted, appFileAccepted, appFileRejected } from './actions';

                                                      

export const setString = createAction 
               
                   
   
                
                  
       
    
 ('SET_STRING', (key        , value        ) => ({ key, value }));

export default createReducerCreator                    ({
  initialState: {},
  payloadStrategy: action => action.payload.value,
  keyStrategy: action => action.payload.key,
})(
  set(setString.toString()),
  handleActions(
    {
      [appFileAccepted.toString()]: state => ({
        ...state,
        fileUploadResponse: 'true',
      }),
      [appFileRejected.toString()]: (state, action) => ({
        ...state,
        fileUploadError: action.payload,
      }),
      [appFileSubmitted.toString()]: state => ({
        ...state,
        apps: null,
        fileUploadResponse: null,
        fileUploadError: null,
      }),
    },
    {},
  ),
);
