/**
 * truncate a given number and adds "K", 'M", etc to end of it
 * some examples => 10000 -> 10K, 3293604 -> 3.3M
 * @param {given number} num
 * @param {decimal point} fixed
 */
export function truncateNumber(_num, fixed) {
    if (!_num) {
        return '0';
    }
    var num = _num === null || _num === void 0 ? void 0 : _num.valueOf();
    var fixedValue = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
    var b = num.toPrecision(2).split('e'); // get power
    var k = b.length === 1 ? 0 : Math.floor(Math.min(Number(String(b[1]).slice(1)), 14) / 3); // floor at decimals, ceiling at trillions
    var c = Number(k < 1 ? num.toFixed(0 + fixedValue) : (num / Math.pow(10, k * 3)).toFixed(1 + fixedValue)); // divide by power
    var d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
    var e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
}
