//      
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { ExtLink } from '@dt/material-components';
                                                             

               
                           
                                                                             
   

const useStyles = makeStyles({
  container: {
    backgroundColor: palette.blue50,
    padding: '25px 25px',
  },
  containerFullScreen: {
    backgroundColor: palette.blue50,
    padding: '25px 25px',
  },
  link: {
    color: palette.blue,
  },
  button: {
    color: palette.black,
  },
  text: {
    '& p': {
      color: palette.black,
    },
    '& h3': {
      color: palette.black,
    },
  },
});

function OnboardingBanner({ accountInfo, fullScreen = false }       ) {
  const classes = useStyles();
  const [dismissed, setDismissed] = useState(false);

  if (accountInfo.onboarding_completed || dismissed) return null;

  return (
    <div className={fullScreen ? classes.containerFullScreen : classes.container}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={7} className={classes.text}>
              <Grid
                container
                style={{ height: '100%' }}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Typography variant="h5" component="h3">
                    Get Started
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Use Data Theorem&apos;s setup wizard for your Web, Mobile, APIs, and Cloud applications.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={5}>
              <Grid container direction="row" justifyContent="flex-end">
                <Button
                  aria-label="Complete Onboarding"
                  variant="outlined"
                  style={{ color: palette.black, borderColor: palette.black }}
                  onClick={() => {}}
                >
                  <ExtLink to="/management/onboarding-wizard" target="_self">
                    <div className={classes.button}>
                      <div>COMPLETE ONBOARDING</div>
                    </div>
                  </ExtLink>
                </Button>

                <Button
                  aria-label="Dismiss"
                  variant="outlined"
                  style={{
                    color: palette.black,
                    borderColor: palette.black,
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    setDismissed(true);
                  }}
                >
                  <div className={classes.button}>
                    <div>DISMISS</div>
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default React.memo       (OnboardingBanner);
