//      

import { put, call } from 'redux-saga/effects';
import { events } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';

import { withCache, handleNormalizedResponse } from './../resource_fetch/sagas';
import resourceType from './resourceType';

export const getEventsList = withCache                  ('events_list', function* getEventsList(
  params                  ,
)             {
  yield put(paginationBeginRequest(resourceType, { ...params }));
  const response = yield* callPromise(events.list, params);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
  yield put(paginationEndRequest(resourceType, { ...params }, response.body.pagination_information));
});
