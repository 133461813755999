import React, { useCallback, useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { Button, Grid, IconButton, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore: #TODO-IMPLEMENT-REACT-ROUTER
import { useNavigate } from '@reach/router';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import format from 'date-fns/format';
import isAfter from 'date-fns/is_after';
import useCookie from '../hooks/use_cookie';
var safeParse = function (value) {
    try {
        return value ? JSON.parse(value) : null;
    }
    catch (e) {
        console.error(e);
        return null;
    }
};
var useStyles = makeStyles({
    snackbar: {
        width: '80%',
    },
});
var HIDE_AFTER_SHOWN_TIMES = 2;
var expires = '2023-09-03';
var hash = 'c7fa6ff47492e011a686218193275812';
var expiryDate = format(new Date(expires));
var daysToExpiry = differenceInCalendarDays(expiryDate, new Date());
var cookieKey = hash ? "DT-".concat(hash) : '';
var TemporaryBroadcastMessage = function () {
    var classes = useStyles();
    var navigate = useNavigate();
    var _a = useState(false), dismissed = _a[0], setDismissed = _a[1];
    var _b = useState(false), isClosed = _b[0], setIsClosed = _b[1];
    var _c = useCookie(cookieKey), broadcastCookie = _c[0], setBroadcastCookie = _c[1];
    var broadcastCookieJSON = safeParse(broadcastCookie);
    var dismissedOrExpiredCookie = broadcastCookieJSON
        ? broadcastCookieJSON.dismissed || isAfter(format(new Date()), broadcastCookieJSON.expires)
        : false;
    var timesShownCookie = (broadcastCookieJSON === null || broadcastCookieJSON === void 0 ? void 0 : broadcastCookieJSON.timesShown) || 1;
    var _d = useState(timesShownCookie), timesShown = _d[0], setTimeShown = _d[1];
    var incrementTimesShown = function () {
        var cookieValue = JSON.stringify({
            dismissed: false,
            expires: expiryDate,
            timesShown: timesShown + 1,
        });
        setBroadcastCookie(cookieValue, { expires: daysToExpiry });
        setTimeShown(function (prev) { return prev + 1; });
        setIsClosed(true);
    };
    var BROADCAST_MESSAGE = {
        title: "Watch Data Theorem's QBR for 2023 Q3",
        message: (React.createElement("div", { style: { alignItems: 'center', display: 'flex', flexDirection: 'column' } },
            React.createElement(IconButton, { size: "small", style: { position: 'absolute', top: 6, right: 6 }, onClick: incrementTimesShown }, "X"),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(Text, { variant: "body", component: "span" }, "New Product: SAST")),
                React.createElement("li", null,
                    React.createElement(Text, { variant: "body", component: "span" }, "New Features: Mobile Secure, API Secure, and Slack"))))),
        timesShown: timesShown,
        expires: expires,
        hash: hash,
    };
    // No existing cookie for the current broadcast message
    useEffect(function () {
        if (typeof broadcastCookie === 'undefined') {
            var cookieValue = JSON.stringify({
                dismissed: false,
                expires: expiryDate,
                timesShown: timesShown,
            });
            daysToExpiry > 0 || timesShown < 2
                ? setBroadcastCookie(cookieValue, { expires: daysToExpiry })
                : setBroadcastCookie(cookieValue);
        }
    }, [broadcastCookie, setBroadcastCookie, timesShown]);
    var handleDismissBroadcastMessage = useCallback(function (event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setDismissed(true);
        var cookieValue = JSON.stringify({
            dismissed: true,
            expires: broadcastCookie ? JSON.parse(broadcastCookie)['expires'] : 0,
        });
        setBroadcastCookie(cookieValue, {
            expires: daysToExpiry > 0 ? daysToExpiry : 0,
        });
    }, [setBroadcastCookie, broadcastCookie]);
    if (isClosed ||
        dismissed ||
        dismissedOrExpiredCookie ||
        daysToExpiry <= 0 ||
        timesShown > HIDE_AFTER_SHOWN_TIMES ||
        timesShownCookie > HIDE_AFTER_SHOWN_TIMES) {
        return null;
    }
    if (!BROADCAST_MESSAGE.message) {
        return null;
    }
    return (React.createElement(Snackbar, { className: classes.snackbar, open: !dismissed, onClose: handleDismissBroadcastMessage, anchorOrigin: { vertical: 'top', horizontal: 'center' } },
        React.createElement(Alert, { style: { position: 'relative' }, severity: "success", variant: "standard", onClose: handleDismissBroadcastMessage, action: React.createElement(Grid, { container: true, direction: 'row', justifyContent: 'flex-end' },
                React.createElement(Button, { "aria-label": 'Dismiss', variant: "outlined", size: 'small', color: "primary", onClick: function () {
                        handleDismissBroadcastMessage();
                        navigate('/devsecops/v2/stanford-dish-security-university');
                    } }, "Watch Now")) },
            BROADCAST_MESSAGE.title && React.createElement(AlertTitle, null, BROADCAST_MESSAGE.title),
            BROADCAST_MESSAGE.message)));
};
export default TemporaryBroadcastMessage;
