import React, { useState } from 'react';
import Box from '@mui/material/Box';
// @ts-ignore Ignore JS Files
import { InventoryTableCard, inventoryTableColumnEnum } from '../../../components';
// @ts-ignore
import { ShadowAssetsLayoutTriageButton } from '../../../layouts/shadow_assets/ShadowAssetsLayoutTriageButton';
var ApiShadowAssetsPageTable = function (_a) {
    var filter_by_named_filter = _a.filter_by_named_filter;
    var _b = useState([]), checkedIds = _b[0], setCheckedIds = _b[1];
    var readOnlyFilters = {
        filter_by_is_shadow: true,
        filter_by_named_filter: filter_by_named_filter || null,
    };
    var handleChangeSelection = function (ids) {
        setCheckedIds(ids);
    };
    return (React.createElement(InventoryTableCard, { productBasePath: '/api', columns: [
            inventoryTableColumnEnum.asset_type_icon_url,
            inventoryTableColumnEnum.asset_name,
            inventoryTableColumnEnum.policy_violations,
            inventoryTableColumnEnum.hosted_on,
            inventoryTableColumnEnum.discovered_via,
            inventoryTableColumnEnum.discovered_date,
            inventoryTableColumnEnum.shadow_reasons,
        ], readOnlyFilters: readOnlyFilters, checkboxSelection: true, isV2: true, checkedIds: checkedIds, onChangeSelection: handleChangeSelection, renderAction: React.createElement(Box, { ml: 1 },
            React.createElement(ShadowAssetsLayoutTriageButton, { checkedIds: checkedIds })) }));
};
export default ApiShadowAssetsPageTable;
