//     
import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import MenuItem from './Item';

              
                
                  
                   
                    
                 
                  
                   
              
                    
                  
                       
                 
                        
                       
                        
                    
  

export class MenuLinkItemReactRouter extends Component        {
  render() {
    const {
      path,
      exact,
      strict,
      tooltip,
      replace,
      hasSubMenu,
      isSubMenu,
      isChildApp,
      ...appMenuItemProps
    } = this.props;

    return (
      <Route path={path} exact={exact} strict={strict}>
        {({ match }) => {
          if (path) {
            return (
              <Link to={path} replace={replace} style={{ display: 'block' }}>
                <MenuItem
                  tooltip={tooltip}
                  hasSubMenu={hasSubMenu}
                  isSubMenu={isSubMenu}
                  isChildApp={isChildApp}
                  active={!!match}
                  {...appMenuItemProps}
                />
              </Link>
            );
          }

          return <MenuItem {...appMenuItemProps} />;
        }}
      </Route>
    );
  }
}
