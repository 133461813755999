//     
                                                            
import TemplateScanTypeEnum from '@dt/enums/TemplateScanTypeEnum';
import { fromNow } from '@dt/date';
import { default as MobileAppReleaseTypeEnum } from '@dt/enums/MobileAppReleaseTypeEnum';

const SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE = {
  NO_SUBSCRIPTION: 5,
  P1_ALERTS: 10,
  STATIC: 20,
  APP_LOGIC: 50,
  DYNAMIC: 40,
};

const SUBSCRIPTION_LEVEL                         = Object.keys(SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE);

export function hasSubscription(app                                )          {
  return app ? app.subscription !== TemplateScanTypeEnum.NO_SUBSCRIPTION : false;
}

export function isScanBlocked(blockedScanReason                               )          {
  return blockedScanReason === 'CORRUPTED_APP_PACKAGE' || blockedScanReason === 'NO_CREDENTIALS';
}

export function getHumanReadableScanStatus(app             )          {
  if (!app || !app.most_recent_scan) {
    return null;
  }

  return getHumanReadableScanStatusFromScan(app.most_recent_scan);
}

export function getHumanReadableScanStatusFromScan(
  scan    
                          
                        
       
   ,
  blockedScanReason                               ,
)         {
  const status = getScanStatus(scan, blockedScanReason);
  return status === scanStatusEnum.SCANNED && scan && scan.end_date
    ? scanStatusToText[status] + ' ' + fromNow(scan.end_date)
    : scanStatusToText[status];
}

export const scanStatusEnum = {
  NEVER_SCANNED: ('never-scanned'                 ),
  BLOCKED: ('blocked'           ),
  ONGOING: ('ongoing'           ),
  SCANNED: ('scanned'           ),
};

const scanStatusToText = {
  [scanStatusEnum.NEVER_SCANNED]: 'Never Scanned',
  [scanStatusEnum.BLOCKED]: 'Scan Blocked',
  [scanStatusEnum.ONGOING]: 'Scan Ongoing',
  [scanStatusEnum.SCANNED]: 'Scanned',
};

                                                 

export function getScanStatus(
  scan    
                          
                        
       
   ,
  blockedScanReason                               ,
)             {
  if (!scan) return scanStatusEnum.NEVER_SCANNED;
  const { start_date, end_date } = scan;
  if (!start_date) return scanStatusEnum.NEVER_SCANNED;
  if (!end_date && isScanBlocked(blockedScanReason)) return scanStatusEnum.BLOCKED;
  if (!end_date) return scanStatusEnum.ONGOING;
  return scanStatusEnum.SCANNED;
}

export function getListOfHigherSubscriptions(subscription        )                          {
  const currentSubscriptionPower = SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[subscription];
  if (!currentSubscriptionPower) {
    return null;
  }

  return SUBSCRIPTION_LEVEL.reduce(
    (final, sub) => (SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[sub] > currentSubscriptionPower ? final.concat(sub) : final),
    [],
  ).sort((a, b) => SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[a] - SUBSCRIPTION_LEVEL_TO_RELATIVE_PRICE[b]);
}

export function isAppStoreApp(app                                 )          {
  return app && app.release_type === MobileAppReleaseTypeEnum.APP_STORE;
}

export function isAppStoreAppThirdParty(app                                 )          {
  return app && app.release_type === MobileAppReleaseTypeEnum.APP_STORE_THIRD_PARTY;
}

export function isEnterpriseApp(app                                 )          {
  return app && app.release_type === MobileAppReleaseTypeEnum.ENTERPRISE;
}

export function isPreProdApp(app                                 )          {
  return app && app.release_type === MobileAppReleaseTypeEnum.PRE_PROD;
}

export function isProdApp(app                                                                  )          {
  return isEnterpriseApp(app) || (isAppStoreApp(app) && !app.is_enterprise_internal);
}
