//     
import { createSelector } from 'reselect';
import qs from 'query-string';

                                                                          

export const search = createSelector 
          
              
                                                                          
    
 (
  (state, props) =>
    props &&
    typeof props.location === 'object' &&
    typeof props.location.search == 'string'
      ? props.location.search
      : null,

  search => {
    if (!search) {
      return null;
    }

    return qs.parse(search);
  },
);
