//      
import React, { memo, useMemo, useState } from 'react';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import { Waypoint } from 'react-waypoint';
import debounce from 'lodash/fp/debounce';
import { TextField, ClickAwayListener, Popover, MenuList, MenuItem } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import ErrorState from '../containers/ErrorState';
import asset_tags from '@dt/graphql-support/horizon/asset_tags';
             
                           
                         
                                  
                                   

                                                                     

              
                     
                                
                             
                     
  

const AssetTagsEditDialogAssetTagRowValue = ({ assetTag, assetTagErrorMessage, onUpdate, disabled }       ) => {
  const [anchorEl, setAnchorEl] = useState                   (null);
  const handleActionClose = () => setAnchorEl(null);

  const [fetchAssetTagValuesQuery, { data, error, loading, fetchMore }] = useLazyQuery 
                           
                                    
   (asset_tags.values.list, {
    fetchPolicy: 'network-only',
  });

  const onTextFieldChange = useMemo(
    () =>
      debounce(500)((text        ) => {
        assetTag.id &&
          fetchAssetTagValuesQuery({
            variables: {
              tag_uuid: assetTag.id,
              filter_by_text: text,
            },
          });
      }),
    [fetchAssetTagValuesQuery, assetTag.id],
  );

  const onSelectAssetTagValue = (assetTagValue          ) => {
    onUpdate(assetTagValue);
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        label="value"
        placeholder="Enter asset tag value"
        margin="dense"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        value={assetTag.value || ''}
        onChange={e => {
          onUpdate({
            ...assetTag,
            value: e.target.value,
          });
          if (e.target.value) {
            setAnchorEl(e.currentTarget);
            onTextFieldChange(e.target.value);
          }
        }}
        disabled={disabled}
        error={Boolean(assetTagErrorMessage)}
        helperText={assetTagErrorMessage || null}
      />
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Popover
          id={'inventory-edit-action'}
          open={(loading || error || Boolean(data)) && Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleActionClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
        >
          <MenuList
            style={{
              maxHeight: 400,
              overflow: 'auto',
              width: 250,
              paddingBottom: 0,
            }}
          >
            {error ? (
              <ErrorState error={error} />
            ) : loading ? (
              [0, 1, 2, 3, 4, 5].map((_, key) => (
                <MenuItem dense key={key}>
                  <Skeleton animation="wave" width={`100%`} height={30} />
                </MenuItem>
              ))
            ) : (
              data?.asset_tag_value_list.asset_tags.map((assetTagValue, idx) => (
                <MenuItem
                  key={idx}
                  onClick={() =>
                    onSelectAssetTagValue({
                      id: assetTag.id,
                      tag_id: assetTag.tag_id,
                      tag: assetTag.tag,
                      value: assetTagValue.value,
                    })
                  }
                >
                  {assetTag.value}
                </MenuItem>
              ))
            )}
            {!loading &&
            !error &&
            data &&
            !data?.asset_tag_value_list.asset_tags.find(tag => tag.value === assetTag.value) ? (
              <MenuItem onClick={() => onSelectAssetTagValue(assetTag)}>{assetTag.value} (new tag value)</MenuItem>
            ) : null}
            {fetchMore && (
              <Waypoint
                onEnter={() => {
                  if (fetchMore) {
                    fetchMore();
                  }
                }}
              >
                <MenuItem dense>
                  <Skeleton animation="wave" width={`100%`} height={40} />
                </MenuItem>
              </Waypoint>
            )}
          </MenuList>
        </Popover>
      </ClickAwayListener>
    </>
  );
};

export default memo       (AssetTagsEditDialogAssetTagRowValue);
