var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useEffect, useState } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Chip, CircularProgress, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useMutation, useQuery } from '@apollo/client';
import { Message } from '@components';
import Text from '@dt/ahura/src/components/Text';
import { get, put } from '@dt/graphql-support/horizon/ts/configurations_shadow';
import { ShadowAssetsContext } from './ApiSecureShadowAssetsPage';
var ApiShadowAssetsPageChartConfigurationItems = function () {
    var _a = useContext(ShadowAssetsContext), setBulkId = _a.setBulkId, isPolling = _a.isPolling;
    var _b = useState({}), initialConfig = _b[0], setInitialConfig = _b[1];
    var _c = useState({}), togglableConfig = _c[0], setTogglableConfig = _c[1];
    var _d = useState(false), hasChanged = _d[0], setChanged = _d[1];
    var _e = useQuery(get), data = _e.data, error = _e.error, loading = _e.loading;
    var _f = useMutation(put, {
        update: function (cache, result) {
            var _a;
            if (result === null || result === void 0 ? void 0 : result.errors) {
                return;
            }
            var bulkId = (_a = result.data) === null || _a === void 0 ? void 0 : _a.configurations_shadow_put.bulk_action_id;
            if (bulkId) {
                setBulkId(bulkId);
            }
            setChanged(false);
            setInitialConfig(togglableConfig);
        },
    }), putConfigurationsShadow = _f[0], putConfigurationsLoading = _f[1].loading;
    var cancel = function () {
        setTogglableConfig(initialConfig);
        setChanged(false);
    };
    var handleApplyClick = function () {
        putConfigurationsShadow({
            variables: {
                body: {
                    configuration: {
                        should_mark_new_assets_except_on_approved_asset_types: true,
                        should_mark_new_cloud_resources_except_on_approved_cloud_providers: true,
                        should_mark_new_mobile_apps_except_on_approved_app_stores: true,
                        should_mark_new_network_services_with_dev_in_url: togglableConfig['Domains with DEV in URL'],
                        should_mark_new_network_services_with_preprod_in_url: togglableConfig['Domains with PREPROD in URL'],
                        should_mark_new_network_services_with_uat_in_url: togglableConfig['Domains with UAT in URL'],
                        should_mark_new_apis_not_going_through_gateway: togglableConfig['APIs Detached from Gateway'],
                        should_mark_new_mobile_apps_on_3rd_party_stores: togglableConfig['Mobile Apps on 3rd Party Stores'],
                        should_mark_new_mobile_apps_on_unofficial_app_stores: togglableConfig['Unofficial App Stores'],
                        should_mark_new_mobile_apps_on_official_stores: togglableConfig['Mobile Apps on Official Stores'],
                        // Not supported.
                        public_asm_enabled: true,
                        approved_asset_types: __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], (!togglableConfig['GraphQL APIs'] ? ['GRAPHQL_API'] : []), true), (!togglableConfig['RESTful APIs'] ? ['RESTFUL_API'] : []), true), (!togglableConfig['Single Page Applications'] ? ['WEB_APPLICATION'] : []), true), (!togglableConfig['Mobile Applications'] ? ['MOBILE_APPLICATION'] : []), true), (!togglableConfig['API Operations'] ? ['API_OPERATION'] : []), true), (!togglableConfig['Cloud Resources'] ? ['CLOUD_RESOURCE'] : []), true), (!togglableConfig['Domains'] ? ['DOMAIN_NAME'] : []), true), (!togglableConfig['Web Servers'] ? ['NETWORK_SERVICE'] : []), true),
                        approved_cloud_providers: __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], (!togglableConfig['Unknown Host'] ? ['UNKNOWN'] : []), true), (!togglableConfig['Amazon Web Services (AWS)'] ? ['AMAZON_WEB_SERVICES'] : []), true), (!togglableConfig['Google Cloud Platform (GCP)'] ? ['GOOGLE_CLOUD_PLATFORM'] : []), true), (!togglableConfig['Microsoft Azure'] ? ['AZURE'] : []), true), (!togglableConfig['Apigee'] ? ['APIGEE'] : []), true), (!togglableConfig['Mulesoft'] ? ['MULESOFT'] : []), true), (!togglableConfig['Kong'] ? ['KONG'] : []), true), (!togglableConfig['Axway'] ? ['AXWAY'] : []), true), (!togglableConfig['On-prem'] ? ['ON_PREMISE'] : []), true),
                        approved_app_stores: __spreadArray(__spreadArray([], (!togglableConfig['Apple - App Store'] ? ['APPLE_APP_STORE'] : []), true), (!togglableConfig['Google - Play Store'] ? ['GOOGLE_PLAY_STORE'] : []), true),
                    },
                },
            },
        });
    };
    useEffect(function () {
        var initialBulkId = data === null || data === void 0 ? void 0 : data.configurations_shadow_get.bulk_action_id;
        setBulkId(initialBulkId || '');
    }, [data, setBulkId]);
    useEffect(function () {
        var config = data === null || data === void 0 ? void 0 : data.configurations_shadow_get.configuration;
        if (!config)
            return;
        var initialConfig = {
            'GraphQL APIs': !config.approved_asset_types.includes('GRAPHQL_API'),
            'RESTful APIs': !config.approved_asset_types.includes('RESTFUL_API'),
            'Single Page Applications': !config.approved_asset_types.includes('WEB_APPLICATION'),
            'Mobile Applications': !config.approved_asset_types.includes('MOBILE_APPLICATION'),
            'API Operations': !config.approved_asset_types.includes('API_OPERATION'),
            'Cloud Resources': !config.approved_asset_types.includes('CLOUD_RESOURCE'),
            Domains: !config.approved_asset_types.includes('DOMAIN_NAME'),
            'Web Servers': !config.approved_asset_types.includes('NETWORK_SERVICE'),
            // Approved App Stores
            'Apple - App Store': !config.approved_app_stores.includes('APPLE_APP_STORE'),
            'Google - Play Store': !config.approved_app_stores.includes('GOOGLE_PLAY_STORE'),
            // Approved Cloud Providers
            'Unknown Host': !config.approved_cloud_providers.includes('UNKNOWN'),
            'Amazon Web Services (AWS)': !config.approved_cloud_providers.includes('AMAZON_WEB_SERVICES'),
            'Google Cloud Platform (GCP)': !config.approved_cloud_providers.includes('GOOGLE_CLOUD_PLATFORM'),
            'Microsoft Azure': !config.approved_cloud_providers.includes('AZURE'),
            Apigee: !config.approved_cloud_providers.includes('APIGEE'),
            Mulesoft: !config.approved_cloud_providers.includes('MULESOFT'),
            Kong: !config.approved_cloud_providers.includes('KONG'),
            Axway: !config.approved_cloud_providers.includes('AXWAY'),
            'On-prem': !config.approved_cloud_providers.includes('ON_PREMISE'),
            // Marks
            'Domains with UAT in URL': config.should_mark_new_network_services_with_uat_in_url,
            'Domains with PREPROD in URL': config.should_mark_new_network_services_with_preprod_in_url,
            'Domains with DEV in URL': config.should_mark_new_network_services_with_dev_in_url,
            'APIs Detached from Gateway': config.should_mark_new_apis_not_going_through_gateway,
            'Mobile Apps on 3rd Party Stores': config.should_mark_new_mobile_apps_on_3rd_party_stores,
            'Unofficial App Stores': config.should_mark_new_mobile_apps_on_unofficial_app_stores,
            'Mobile Apps on Official Stores': config.should_mark_new_mobile_apps_on_official_stores,
        };
        setInitialConfig(initialConfig);
        setTogglableConfig(initialConfig);
    }, [data]);
    if (loading) {
        return React.createElement(Skeleton, { height: 50 });
    }
    if (error) {
        return React.createElement(Message, { variant: 'error', message: error.message, m: 0 });
    }
    if (!data) {
        // Shouldn't happen.
        return null;
    }
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Text, { variant: "titleXS", component: "div" }, "Choose one or multiple rules to define shadow assets")),
        React.createElement(Grid, { item: true, xs: 12 }, Object.keys(togglableConfig).map(function (key) { return (React.createElement(Box, { display: "inline-block", mr: 1, mb: 1, key: key },
            React.createElement(Chip, { style: { cursor: 'pointer' }, 
                // @ts-ignore
                icon: togglableConfig[key] ? React.createElement(DoneIcon, null) : null, label: key, size: "medium", variant: togglableConfig[key] ? undefined : 'outlined', onClick: function () {
                    if (!hasChanged) {
                        setChanged(true);
                    }
                    setTogglableConfig(function (config) {
                        var _a;
                        return (__assign(__assign({}, config), (_a = {}, _a[key] = !config[key], _a)));
                    });
                } }))); })),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Box, { mt: 1 },
                React.createElement(Button, { variant: "contained", color: "primary", size: "small", disabled: !hasChanged || putConfigurationsLoading || isPolling, startIcon: putConfigurationsLoading || isPolling ? React.createElement(CircularProgress, { size: 16 }) : null, style: { marginRight: 8 }, onClick: function () {
                        handleApplyClick();
                    } }, "Apply"),
                React.createElement(Button, { size: "small", disabled: !hasChanged || putConfigurationsLoading || isPolling, variant: "contained", onClick: function () {
                        cancel();
                    } }, "Cancel")))));
};
export default ApiShadowAssetsPageChartConfigurationItems;
