//      
import { createAction } from 'redux-actions';

export const policiesReceived = createAction                                               (
  'policies/received',
  params => params,
);

export const policiesMounted = createAction                                                  (
  'policies/mounted',
  () => ({ key: 'policies' }),
);

export const policiesDetailsMounted = createAction 
                             
           
   
                    
                      
       
    
 ('policies/details/mounted', policy_id => ({ key: 'policies', policy_id }));

export const policiesCreate = createAction 
                    
                                            
                                          
 ('policies/create', params => params);

export const policiesCreateSuccess = createAction                                             (
  'policies/create/success',
  params => params,
);

export const policiesCreateError = createAction                                           (
  'policies/create/error',
  params => params,
);

export const policiesCreateDone = createAction                        ('policies/create/done');

export const policiesDeleteButtonClicked = createAction                                             (
  'policies/delete/clicked',
  policy_id => policy_id,
);
