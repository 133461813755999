//     
import { memo, useEffect, useRef } from 'react';
import { useTracking } from 'react-tracking';

               
                 
                           
                 
       
    
   

export default memo       (function Analytics({ trackOnMount }) {
  const trackOnMountRef = useRef(trackOnMount);
  let trackingInfo = trackOnMountRef.current || trackOnMount;

  if (trackingInfo !== trackOnMount) {
    const oldKeys = Object.keys(trackingInfo);
    const newKeys = Object.keys(trackOnMount);
    if (
      oldKeys.length !== newKeys.length ||
      oldKeys.some(k => trackOnMount[k] !== trackingInfo[k])
    ) {
      trackingInfo = trackOnMount;
    }
  }

  const tracking = useTracking();
  useEffect(() => {
    tracking.trackEvent(trackingInfo);
  }, [tracking, trackingInfo]);

  trackOnMountRef.current = trackingInfo;

  return null;
});
