//     
import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Text } from '@dt/material-components';
import { MobileAppReleaseTypeValues } from '@dt/graphql-support/enums';
                                                                      
import Box from '@mui/material/Box';

                                                            
                                       
      
  

export const PopoutFilterPluginMobileAppReleaseTypeDisplayLookup         
                                                                     
  = {
  [MobileAppReleaseTypeValues.PRE_PROD]: 'PreProd Apps',
  [MobileAppReleaseTypeValues.APP_STORE]: 'Prod Apps',
  [MobileAppReleaseTypeValues.ENTERPRISE]: 'Corporate Apps',

  // NOTE: Excluded now until we know what to do with these.
  [MobileAppReleaseTypeValues.APP_STORE_THIRD_PARTY]: null,
};

              
                                                       
              
                                                                                                          
            
  

/*
 * Popout filter plugin.
 * User can select mobile app release types.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
export const PopoutFilterPluginMobileAppReleaseType = ({ value, onChange }       ) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginMobileAppReleaseTypeDisplayLookup)
        .filter(key => !!PopoutFilterPluginMobileAppReleaseTypeDisplayLookup[key])
        .map(key => {
          const val = PopoutFilterPluginMobileAppReleaseTypeDisplayLookup[key] || '';
          return (
            <FormControlLabel
              key={key}
              label={<Text variant="body">{val}</Text>}
              control={
                <Checkbox
                  color="primary"
                  inputProps={{
                    'aria-label': `${val}`,
                  }}
                  checked={!!value[key]}
                  onChange={e => {
                    (value =>
                      onChange(prev => ({
                        ...prev,
                        // $FlowFixMe - string -> enum
                        [key]: value,
                      })))(e.target.checked);
                  }}
                />
              }
            />
          );
        })}
    </Box>
  );
};
