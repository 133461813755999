//     
import React, { memo } from 'react';
import { palette } from '@dt/theme';
import { Card, CardContent, Grid } from '@mui/material';
import Text from './Text';
import ErrorIcon from '@mui/icons-material/Error';

              
                   
  

const CardEmptyComponent = function CardEmpty({ message }       ) {
  return (
    <Card>
      <CardContent>
        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item>
            <ErrorIcon style={{ width: 50, height: 50 }} />
          </Grid>
          <Grid item container direction="row" spacing={2} wrap="nowrap">
            <Grid item>
              <Text color={palette.gray20} variant="body" style={{ whiteSpace: 'pre-line' }}>
                {message}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const CardEmpty = memo       (CardEmptyComponent);
