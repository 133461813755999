//     
import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Add from '@mui/icons-material/Add';
import { palette } from '@dt/theme';
import Text from './Text';
import { PolicyViolationsCommentsItem } from './PolicyViolationsCommentsItem';
import { PolicyViolationsCommentsListEmpty } from './PolicyViolationsCommentsListEmpty';

                
               
                         
                 
                                
                 
     
  

              
                                   
                                           
                           
                              
                   
                      
  

/*
 * DO NOT use directly.
 *
 * Body details for "Advice/Notes" used by the following components:
 *
 * - PolicyViolationsCommentsSection
 * - PolicyViolationsCommentsSectionCard
 */
const PolicyViolationsCommentsSectionBodyComponent = function PolicyViolationsCommentsSectionBody({
  isCommentsWorkflowOpen,
  sortedComments,
  latestComment,
  onOpenWorkflow,
  isV2,
  hideTag = false,
}       ) {
  const latestPx = isV2 ? 3 : 1;
  const latestPy = isV2 ? 2 : 1;

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius={4}
      bgcolor={palette.white}
      px={sortedComments.length <= 0 || !latestComment ? 3 : latestPx}
      py={sortedComments.length <= 0 || !latestComment ? 2 : latestPy}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent={isV2 && 'space-between'}
        marginBottom={1}
        marginTop={isV2 && -1}
      >
        <Text variant="body" style={{ margin: 0, padding: 0 }}>
          Latest Message
        </Text>
        {/* Do not show when comments column is open or if we don't have any comments to show */}
        {!isCommentsWorkflowOpen && sortedComments.length > 0 && latestComment && !isV2 && (
          <Text variant="link" style={{ marginLeft: '16px', cursor: 'pointer' }}>
            <span onClick={onOpenWorkflow}>view all</span>
          </Text>
        )}

        {isV2 && (
          <IconButton onClick={onOpenWorkflow} aria-label="View all">
            <Add style={{ fontSize: 18 }} />
          </IconButton>
        )}
      </Box>

      {sortedComments.length <= 0 || !latestComment ? (
        <PolicyViolationsCommentsListEmpty variant="inline" onAddNewClicked={onOpenWorkflow} />
      ) : (
        <Box borderRadius={4} bgcolor={palette.gray50}>
          <PolicyViolationsCommentsItem
            hideTag={hideTag}
            author={latestComment.author_email}
            date={latestComment.date}
            is_internal_comment={latestComment.is_internal_comment}
            isFlat
          >
            {latestComment.text}
          </PolicyViolationsCommentsItem>
        </Box>
      )}
    </Box>
  );
};

export const PolicyViolationsCommentsSectionBody = PolicyViolationsCommentsSectionBodyComponent;
