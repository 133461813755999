//      
import React, { memo } from 'react';
import { Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  divider: props => ({
    height: 2,
    width: '20%',
    backgroundColor: props.color || palette.blue20,
    marginTop: 12,
    marginBottom: 12,
  }),
});

              
                
  

function ToolkitDivider({ color }       ) {
  const classes = useStyles({ color });
  return (
    <Grid container>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
}

export default memo       (ToolkitDivider);
