//     
import { createAction } from 'redux-actions';

                                                                                 
                                                            
import { createRoutine } from 'redux-saga-routines';

export const updateStatusStarted = createAction 
                          
                   
   
                      
                     
       
    
 ('UPDATE_STATUS_STARTED', (findingId, targetId) => ({ findingId, targetId }));

export const updateStatusFailure = createAction 
                          
                   
   
                      
                     
       
    
 ('UPDATE_STATUS_FAILURE', (findingId, targetId) => ({ findingId, targetId }));

export const updateStatusSuccess = createAction 
                          
                                 
   
                      
                         
                     
       
    
 ('UPDATE_STATUS_SUCCESS', (findingId, targetId, status) => ({
  findingId,
  status,
  targetId,
}));

export const updateStatus = createAction 
                  
                                            
   
                              
                     
                                       
       
    
 ('UPDATE_STATUS', (securityFindingId, targetId, newStatus) => ({
  securityFindingId,
  targetId,
  newStatus,
}));

export const changePriority = createAction 
                    
                                              
   
                                      
                              
       
    
 ('CHANGE_PRIORITY', (priority, finding) => ({
  priority,
  finding,
}));

export const securityFindingOpened = createAction 
                            
                                                  
   
                             
                                              
    
 ('SECURITY_FINDING_OPENED', (finding, linkedApps) => ({
  finding,
  linkedApps,
}));

export const updatePermanentlyClosedStatus = createAction 
                                     
                                                        
   
                             
                                  
                                                        
       
    
 ('UPDATE_PERMANENTLY_CLOSED_STATUS', (finding, isPermanentlyClosed, requestedAggregatedStatus) => ({
  finding,
  isPermanentlyClosed,
  requestedAggregatedStatus,
}));

export const securityFindingsFetchAllStart = createAction                                               (
  'SECURITY_FINDINGS_FETCH_ALL_START',
);

export const securityFindingsFetchAllFinish = createAction                                                (
  'SECURITY_FINDINGS_FETCH_ALL_FINISH',
);

                                         
                           
                                          
     
  
                                         
                       
                       
     
  
                                          
                                                   
     
  
                                   
                       
                       
     
  

                                       

export const linkedIssuesRoutine = createRoutine 
                                   
                                   
                             
                           
                                    
 ('LINKED_ISSUES');

export const securityFindingLightboxOpened = createAction                                                      (
  'SECURITY_FINDING_LIGHTBOX_OPENED',
  findingId => findingId,
);
