//      
import React from 'react';
import { makeStyles } from '@mui/styles';
                                                                                                          
import {
  getIconFromComplianceStandard,
  getDisplayTextFromComplianceStandard,
  getDisplayTextFromComplianceStandardCriteria,
} from '@dt/material-components/compliance-tag/util';
import { palette } from '@dt/theme';
import { Tooltip, Typography } from '@mui/material';

                  
                                                     
                                                                      
                       
   

                               

const useStyles = makeStyles({
  container: {
    padding: '4px 6px',
    backgroundColor: palette.gray45,
    borderRadius: 4,
    margin: '8px 4px 0',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: '14px',
    width: '14px',
    marginRight: 4,
  },
});

const PolicyViolationsComplianceTagComponent = function PolicyViolationsComplianceTag({
  compliance_standard: standard,
  compliance_standard_criteria: criteria,
  description,
}       ) {
  const classes = useStyles();

  const tooltipText = (
    <>
      {getDisplayTextFromComplianceStandard(standard)} {getDisplayTextFromComplianceStandardCriteria(criteria)}
      <hr />
      {description}
    </>
  );

  return (
    <Tooltip title={tooltipText}>
      <div className={classes.container}>
        <img src={getIconFromComplianceStandard(standard)} className={classes.image} />
        <Typography variant="caption">
          {`${getDisplayTextFromComplianceStandard(standard)} ${getDisplayTextFromComplianceStandardCriteria(
            criteria,
          )}`}
        </Typography>
      </div>
    </Tooltip>
  );
};

export const PolicyViolationsComplianceTag = React.memo          (PolicyViolationsComplianceTagComponent);
