//      
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                  
               
                   
               
                        
                        
   

                                                                       

                                           
                                               
   

export async function list()                                            {
  return fetch(`${api}/${version}/cloud_resource_types`).then(parse);
}
