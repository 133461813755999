//      
import { createReducerCreator, updateInList } from 'rezz';
                                                
import { handleActions } from 'redux-actions';
import {
  commentsReceived,
  commentInsert,
  commentInsertError,
  commentInsertSuccess,
  commentDelete,
  commentDeleteError,
  commentDeleteSuccess,
} from './actions';

                              
                         
                       
                                 
               
                   
       
     
                                
               
                   
       
     
                                  
               
                   
       
     
                     
   

export const initialState                = {
  insertSuccess: false,
  insertError: null,
  deleteSuccess: [],
  deleteErrors: [],
  deleteLoadings: [],
  list: [],
};

const replaceWithId =                       (
  state                  
               
             
       
    ,
  id,
  value   ,
) =>                  
             
           
     
      {
  return [
    ...state.filter(l => l.id !== id),
    {
      id,
      value,
    },
  ];
};

export default createReducerCreator                           ({
  initialState: initialState,
  equalityCheck: (a, b) => a.id === b.id,
})(
  handleActions(
    {
      [commentInsert.toString()]: state => ({
        ...state,
        insertSuccess: false,
        insertError: null,
      }),
      [commentInsertSuccess.toString()]: state => ({
        ...state,
        insertSuccess: true,
        insertError: null,
      }),
      [commentInsertError.toString()]: (state, action                          ) => ({
        ...state,
        insertSuccess: false,
        insertError: action.payload,
      }),
      [commentDelete.toString()]: (state, action                                  ) => ({
        ...state,
        deleteSuccess: replaceWithId(state.deleteSuccess, action.payload.commentId, false),
        deleteLoadings: replaceWithId(state.deleteLoadings, action.payload.commentId, true),
        deleteErrors: replaceWithId(state.deleteErrors, action.payload.commentId, null),
      }),
      [commentDeleteSuccess.toString()]: (state, action                                         ) => ({
        ...state,
        deleteSuccess: replaceWithId(state.deleteSuccess, action.payload.commentId, true),
        deleteLoadings: replaceWithId(state.deleteLoadings, action.payload.commentId, false),
        deleteErrors: replaceWithId(state.deleteErrors, action.payload.commentId, null),
      }),
      [commentDeleteError.toString()]: (state, action                                       ) => ({
        ...state,
        deleteSuccess: replaceWithId(state.deleteSuccess, action.payload.commentId, false),
        deleteLoadings: replaceWithId(state.deleteLoadings, action.payload.commentId, false),
        deleteErrors: replaceWithId(state.deleteErrors, action.payload.commentId, action.payload.error),
      }),
    },
    initialState,
  ),
  (state = initialState, action                                     , rezzOptions) => {
    return action.type === commentsReceived.toString()
      ? {
          ...state,
          list: updateInList(commentsReceived.toString())(
            state.list,
            action,
            // reez typings proxies reducers types,
            rezzOptions,
          ),
        }
      : state;
  },
);
