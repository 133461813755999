//      

import { putResolve, call } from 'redux-saga/effects';
import { policies } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import resourceType from './resourceType';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';

import { withCache, handleNormalizedResponse } from './../resource_fetch/sagas';

export const getPolicyList = withCache         ('policy_list', function* getPolicyRuleTypeList(params)             {
  yield putResolve(paginationBeginRequest(resourceType, params));
  const response = yield* callPromise(policies.list, params);
  if (response._type === 'error') {
    throw new Error(response.title);
  }
  yield call(handleNormalizedResponse, response.body);
  if (response.body.pagination_information) {
    yield putResolve(paginationEndRequest(resourceType, params, response.body.pagination_information));
  }
});

export const getPolicyDetails = withCache        (
  policy_id => `policy_details:${policy_id}`,
  function* getPolicyRuleTypeList(policy_id)             {
    const response = yield* callPromise(policies.details, policy_id);
    if (response._type === 'error') {
      throw new Error(response.title);
    }
    yield call(handleNormalizedResponse, response.body);
  },
);
