import React from 'react';
import { Grid } from '@mui/material';
import Text from '@dt/ahura/src/components/Text';
import ApiShadowAssetsPageChartConfigurationItems from './ApiShadowAssetsPageChartConfigurationItems';
var ApiShadowAssetsPageChartConfiguration = function () {
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Text, { variant: "body", component: "div" }, "\"Shadow\" Apps/APIs are new, unknown, and possibly unauthorized assets in your environment. As Data Theorem's\n          ASM scans by the hour, get alerts in real-time on any asset associated with your organization.")),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(ApiShadowAssetsPageChartConfigurationItems, null))));
};
export default ApiShadowAssetsPageChartConfiguration;
