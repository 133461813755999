//      
import config from '@dt/config';
import qs from 'query-string';
import fetch, { parse } from '@dt/fetch';

                                                                       

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                                       
                          
    

export async function list(params   
      
 )                                      {
  return fetch(`${api}/${version}/ip_ranges?${qs.stringify(params)}`).then(
    parse,
  );
}

                                    
                    
                                              
                                                    
   

                                      
                          
   

export async function create(
  params                     ,
)                                       {
  if (
    params.has_continuous_discovery_enabled &&
    params.ip_addresses_to_import !== null
  ) {
    throw new Error(
      "Unable to create ip address range. Unable to import 'ip_addresses_to_import' when 'has_continuous_discovery_enabled' is enabled.",
    );
  }

  return fetch(`${api}/${version}/ip_ranges`, {
    method: 'POST',
    body: JSON.stringify({
      ...params,
      ip_addresses_to_import:
        params.ip_addresses_to_import !== null
          ? params.ip_addresses_to_import
          : [],
    }),
  }).then(parse);
}

export async function get(id        )                         {
  return fetch(`${api}/${version}/ip_ranges/${id}`).then(parse);
}

                                   
                                             
   

                                     
                          
   

export async function patch(
  id        ,
  params                    ,
)                                      {
  return fetch(`${api}/${version}/ip_ranges/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  }).then(parse);
}
