//      

import React, { memo } from 'react';
import { TableCell } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Tag } from '@dt/material-components';

            
     
                    
     
     
                      
                   
                        
      

const InventoryTableCellDiscoveredVia = (props       ) => {
  if (props.loading) {
    return (
      <TableCell>
        <Box display={'flex'} alignItems={'center'}>
          <Skeleton animation="wave" variant="text" height={40} width={100} />
        </Box>
      </TableCell>
    );
  }

  const { iconUrl, name, maxWidth } = props;

  return (
    <TableCell>
      <Tag
        label={name}
        icon={<img style={{ height: 14, width: 14 }} src={iconUrl} alt={name} />}
        maxWidth={maxWidth || '100%'}
      />
    </TableCell>
  );
};

export default memo       (InventoryTableCellDiscoveredVia);
