//      
import React, { memo } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import Tag from './Tag';

              
                
  

function TagRegion({ value }       ) {
  return <Tag icon={<LocationOnIcon style={{ width: 14, height: 14 }} />} label={`REGION: ${value}`} />;
}

export default memo       (TagRegion);
