import React from 'react';
import { Grid } from '@mui/material';
import PaperContainer from '../../../components/PaperContainer';
import ApiShadowAssetsPageChartConfiguration from './ApiShadowAssetsPageChartConfiguration';
import ApiShadowAssetsPageChartDonutContainer from './ApiShadowAssetsPageChartDonutContainer';
import ApiShadowAssetsPageChartLoadingContainer from './ApiShadowAssetsPageChartLoadingContainer';
var ApiShadowAssetsPageChartContainer = function () {
    return (React.createElement(PaperContainer, null,
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(ApiShadowAssetsPageChartConfiguration, null)),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Grid, { container: true, spacing: 2 },
                    React.createElement(ApiShadowAssetsPageChartLoadingContainer, null),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ApiShadowAssetsPageChartDonutContainer, null)))))));
};
export default ApiShadowAssetsPageChartContainer;
