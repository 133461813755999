//     
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';

import FindingsBadge from './FindingsBadge';
import FindingsBadgeType from './FindingsBadgeTypeEnum';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: ({ justify }       ) => justify,
  },

  badge: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    marginLeft: 5,
  },
});

                      
                   
                                            
                                           
   

const getCountForBadgeType = (type                       , openMetadataCount               )         => {
  switch (type) {
    case FindingsBadgeType.P1:
      return openMetadataCount.P1;
    case FindingsBadgeType.BLOCKER:
      return openMetadataCount.APPLE_BLOCKER + openMetadataCount.GOOGLE_BLOCKER;
    case FindingsBadgeType.COMPLIANCE:
      return openMetadataCount.COMPLIANCE;
    case FindingsBadgeType.INSIGHT:
      return openMetadataCount.INSIGHT;
  }

  throw new Error(`${type} not supported by FindingStats.`);
};

               
                  
                                    
   

function FindingStats(props) {
  const { openMetadataCount, badgeTypes, noLabel } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      {openMetadataCount ? (
        badgeTypes
          .map(type => [type, getCountForBadgeType(type, openMetadataCount)])
          .map(([type, count]) => (
            <div key={type} className={classes.badge}>
              <FindingsBadge noLabel={noLabel} type={FindingsBadgeType[type]} count={count} />
            </div>
          ))
      ) : (
        <div>Stats not currently available.</div>
      )}
    </div>
  );
}

FindingStats.defaultProps = {
  noLabel: false,
  justify: 'space-between',
  badgeTypes: [FindingsBadgeType.P1],
};

export default memo       (FindingStats);
