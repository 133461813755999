//      
import React from 'react';

import { Container } from '@mui/material';

              
                 
                      
                                       
                       
  

const PageLayoutComponent = function PageLayout({ children, fullWidth, classes, style = {} }       ) {
  return (
    <Container
      maxWidth={fullWidth ? false : 'lg'}
      disableGutters={fullWidth}
      classes={classes}
      style={{
        paddingTop: fullWidth ? 0 : 8,
        paddingBottom: 16,
        ...style,
      }}
    >
      {children}
    </Container>
  );
};

export default PageLayoutComponent;
