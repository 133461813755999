var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMutation } from '@apollo/client';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-APOLLO-LINK-SCHEMA-REST
import { useQuery } from '@dt/apollo-link-schema-rest';
import policy_violations from '@dt/graphql-support/horizon/ts/policy_violations';
import alerts_integrations from '@dt/graphql-support/public/integrations/ts/alerts_integrations';
var useExportViolationExportToJira = function (_a) {
    var policyViolationId = _a.policyViolationId, handleClose = _a.handleClose, setSnackOpen = _a.setSnackOpen, setJiraTicketId = _a.setJiraTicketId;
    var _b = useQuery(alerts_integrations.list, {
        fetchPolicy: 'network-only',
        variables: {
            filter_by_integration_type: ['JIRA'],
        },
    }), data = _b.data, fetchMore = _b.fetchMore, loading = _b.loading, error = _b.error;
    var _c = useMutation(policy_violations.exportToJira, {
        update: function (cache, result) {
            var _a;
            if (result === null || result === void 0 ? void 0 : result.errors) {
                return;
            }
            var queryVariables = {
                id: policyViolationId,
            };
            var q = cache.readQuery({
                query: policy_violations.v2_get,
                variables: queryVariables,
            });
            var newItem = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.policy_violation_export_jira;
            setJiraTicketId((newItem === null || newItem === void 0 ? void 0 : newItem.external_key) || '');
            var previousExternalIntegrations = q === null || q === void 0 ? void 0 : q.policy_violation_details_v2.external_integrations;
            cache.writeQuery({
                query: policy_violations.v2_get,
                variables: queryVariables,
                data: {
                    policy_violation_details_v2: __assign(__assign({}, q === null || q === void 0 ? void 0 : q.policy_violation_details_v2), { external_integrations: __spreadArray(__spreadArray([], (previousExternalIntegrations || []), true), [newItem], false) }),
                },
            });
            handleClose();
            setSnackOpen('OK');
        },
        onError: function () {
            handleClose();
            setSnackOpen('ERR');
        },
    }), exportToJira = _c[0], _d = _c[1], errorExportToJira = _d.error, loadingExportToJira = _d.loading;
    return {
        data: data,
        loading: loading,
        error: error,
        fetchMore: fetchMore,
        loadingExportToJira: loadingExportToJira,
        errorExportToJira: errorExportToJira,
        exportToJira: exportToJira,
    };
};
export default useExportViolationExportToJira;
