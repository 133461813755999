//     
import React from 'react';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { PolicyViolationWontFixButton, SharedLinksButton } from '@dt/material-components';
import { PolicyViolationsActionBarRemediateButton } from './PolicyViolationsActionBarRemediateButton';
// $FlowFixMe Ignore TS files
import PolicyViolationExportToJira from './PolicyViolationExportToJira';

              
                       
                
                        
                               
                                
                                 
                                
                                                              
  

/*
 * Provides the user action items to take against a policy violation.
 *
 * @param id - Policy violation id to act against.
 */
export const PolicyViolationsActionBanner = ({
  isLoading,
  jiraTicketId,
  policyViolationName,
  shouldShowRemediate = true,
  shouldShowSharedLink = true,
  shouldWontFixButton = true,
  id,
  onUpdatePolicyViolation,
}       ) => {
  if (isLoading || !id) {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <Skeleton variant="text" animation="pulse" width={180} height={80} />
        </Grid>

        <Grid item>
          <Skeleton variant="text" animation="pulse" width={180} height={80} />
        </Grid>

        <Grid item>
          <Skeleton variant="text" animation="pulse" width={180} height={80} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" spacing={1}>
      {shouldShowRemediate && (
        <Grid item>
          <PolicyViolationsActionBarRemediateButton policy_violation_id={id} />
        </Grid>
      )}

      {shouldShowSharedLink && (
        <Grid item>
          <SharedLinksButton policy_violation_id={id} />
        </Grid>
      )}

      <Grid item>
        <PolicyViolationExportToJira
          policyViolationId={id}
          policyViolationName={policyViolationName}
          jiraTicketId={jiraTicketId}
        />
      </Grid>

      {shouldWontFixButton && (
        <Grid item>
          <PolicyViolationWontFixButton policy_violation_id={id} onUpdatePolicyViolation={onUpdatePolicyViolation} />
        </Grid>
      )}
    </Grid>
  );
};
