var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
var list = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query AlertsIntegrationsList($filter_by_integration_type: [String], $cursor: String) {\n    alerts_integrations_list(filter_by_integration_type: $filter_by_integration_type, cursor: $cursor) {\n      pagination_information {\n        next_cursor\n        total_count\n      }\n      alerts_integrations {\n        id\n        name\n        integration_type\n        status\n        most_recent_attempt_date\n      }\n    }\n  }\n"], ["\n  query AlertsIntegrationsList($filter_by_integration_type: [String], $cursor: String) {\n    alerts_integrations_list(filter_by_integration_type: $filter_by_integration_type, cursor: $cursor) {\n      pagination_information {\n        next_cursor\n        total_count\n      }\n      alerts_integrations {\n        id\n        name\n        integration_type\n        status\n        most_recent_attempt_date\n      }\n    }\n  }\n"])));
var getAsmAssetTypeValues = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query GetAsmAssetTypeValues {\n    alerts_integration_asm_asset_type_values {\n      alerts_integration_asm_asset_type_values {\n        name\n        value_category\n        value\n      }\n    }\n  }\n"], ["\n  query GetAsmAssetTypeValues {\n    alerts_integration_asm_asset_type_values {\n      alerts_integration_asm_asset_type_values {\n        name\n        value_category\n        value\n      }\n    }\n  }\n"])));
export default {
    list: list,
    getAsmAssetTypeValues: getAsmAssetTypeValues,
};
var templateObject_1, templateObject_2;
