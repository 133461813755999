var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import UsersIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/ExitToAppOutlined';
import HelpIcon from '@mui/icons-material/HelpOutline';
import ActivityIcon from '@mui/icons-material/History';
import NewReleaseIcon from '@mui/icons-material/NewReleases';
// @ts-ignore
import AlertsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import PollIcon from '@mui/icons-material/Poll';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton, ListItemIcon, Menu, MenuItem, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useMutation } from '@apollo/client';
// @ts-ignore
import { useSession } from '@dt/ahura/src/session';
// @ts-ignore
import { ApolloLinkSchemaRestError } from '@dt/apollo-link-schema-rest';
// @ts-ignore Ignore JS files
import UserRole from '@dt/enums/UserRoleEnum';
import { invalidate_session } from '@dt/graphql-support/sevenhell/ts/users';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { ExtLink } from '@dt/material-components';
// @ts-ignore
import { clearSessionAndRedirectToLogin, getInvariantUserAccount } from '@dt/session';
var SettingsMenu = function (_a) {
    var anchorEl = _a.anchorEl, handleClose = _a.handleClose;
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useState(false), shouldRemoveSesssion = _c[0], setRemoveSession = _c[1];
    var loading = useSession({
        unauthenticatedRedirect: false,
    }).loading;
    var _d = useMutation(invalidate_session, {
        onCompleted: function () {
            setRemoveSession(true);
        },
    }), invalidateSession = _d[0], errorInvalidate = _d[1].error;
    useEffect(function () {
        if (shouldRemoveSesssion && !errorInvalidate) {
            clearSessionAndRedirectToLogin();
        }
    }, [shouldRemoveSesssion, errorInvalidate]);
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        if (errorInvalidate &&
            ((_b = (_a = errorInvalidate.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.originalError) instanceof ApolloLinkSchemaRestError &&
            // @ts-ignore
            ((_e = (_d = (_c = errorInvalidate.graphQLErrors) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.originalError) === null || _e === void 0 ? void 0 : _e.statusCode) !== 204) {
            setError('There was an error signing out, please try again.');
        }
    }, [errorInvalidate]);
    if (loading)
        return null;
    var currentUser = getInvariantUserAccount().currentUser;
    return (React.createElement(Menu, { anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        } },
        error && (React.createElement(Snackbar, { anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, open: Boolean(error), autoHideDuration: 2000 },
            React.createElement(Alert, { variant: "filled", severity: 'error', action: React.createElement(IconButton, { "aria-label": "close", color: "inherit", size: "small", onClick: function () {
                        setError(null);
                    } },
                    React.createElement(CloseIcon, { fontSize: "inherit" })) }, error))),
        React.createElement(ExtLink, { to: "/devsecops/v2/stanford-dish-security-university#whats-new", target: "_self", onClick: handleClose, "aria-label": "What's New Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(NewReleaseIcon, null)),
                "What's New")),
        currentUser.role === UserRole.MANAGER && (React.createElement(ExtLink, { to: "/management/v2/activity", target: "_self", onClick: handleClose, "aria-label": "Activity Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(ActivityIcon, null)),
                "Activity"))),
        React.createElement(ExtLink, { to: currentUser.role === 'MANAGER' ? '/management/v2/users' : '/management/users/invite', target: "_self", onClick: handleClose, "aria-label": "Users Menu Item" },
            React.createElement(MenuItem, { disabled: !currentUser.can_invite_users },
                React.createElement(ListItemIcon, null,
                    React.createElement(UsersIcon, null)),
                currentUser.role === 'MANAGER' ? 'Manage ' : 'Invite ',
                "Users")),
        React.createElement(ExtLink, { to: "/management/v2/preferences", target: "_self", onClick: handleClose, "aria-label": "Preferences Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(AlertsIcon, null)),
                "Alerts")),
        React.createElement(ExtLink, { to: "/management/v2/shared-links", target: "_self", onClick: handleClose, "aria-label": "Shared Links Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(ShareIcon, null)),
                "Shared Links")),
        React.createElement(ExtLink, { to: "/management/v2/compliance-report", target: "_self", onClick: handleClose, "aria-label": "Compliance Report Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(PollIcon, null)),
                "Compliance")),
        React.createElement(ExtLink, { to: "/management/v2/profile", target: "_self", onClick: handleClose, "aria-label": "Profile Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(PersonIcon, null)),
                "Profile")),
        React.createElement(ExtLink, { to: "https://support.securetheorem.com/", target: "_blank", onClick: handleClose, "aria-label": "Help and Support Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(HelpIcon, null)),
                "Help & Support")),
        React.createElement(MenuItem, { onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    invalidateSession();
                    return [2 /*return*/];
                });
            }); }, "aria-label": "Sign Out Menu Item" },
            React.createElement(ListItemIcon, null,
                React.createElement(LogoutIcon, null)),
            "Sign out")));
};
export default SettingsMenu;
