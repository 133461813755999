//      
import {
  SecurityPropertiesEnum,
                                  
} from '@dt/horizon-api';

const enumData   
                                  
         
              
          
                         
                              
                                           
                                     
                 
               
               
            
                     
       
    
      
  = {
  [SecurityPropertiesEnum.MATCHES_HOSTNAME]: {
    text: value => (value ? 'Matches hostname' : 'Does not match hostname'),
  },
  [SecurityPropertiesEnum.IS_EXPIRING]: {
    text: (value, certificate_chain) =>
      `Expires on ${certificate_chain.certificates[0].not_valid_after.toLocaleDateString()}`,
  },
  [SecurityPropertiesEnum.SUPPORTS_MUST_STAPLE]: {
    text: value =>
      value ? 'Supports OCSP Staple' : 'Does not support OCSP Staple',
  },
  [SecurityPropertiesEnum.EMBEDS_CERTIFICATE_TRANSPARENCY_SCT]: {
    text: value =>
      value
        ? 'Embeds Certificate Transparency SCT'
        : 'Does not embed Certificate Transparency SCT',
  },
  [SecurityPropertiesEnum.VULNERABLE_TO_SHA1_COLLISION]: {
    text: value =>
      value
        ? 'Vulnerable to SHA1 Collision'
        : 'Not vulnerable to SHA1 Collision',
  },
  [SecurityPropertiesEnum.RSA_KEY_SMALLER_THAN_2048_BITS]: {
    text: value =>
      value
        ? 'RSA key should not be smaller than 2048 bits'
        : 'RSA Key is not smaller than 2048 bits',
  },
  [SecurityPropertiesEnum.LEGACY_SYMANTEC_ANCHOR]: {
    text: value =>
      value
        ? 'Uses Legacy Symantec Anchor'
        : 'Does not use Legacy Symantec Anchor',
  },
};

export default enumData;
