import React, { useReducer } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
// @ts-ignore - TODO: add types
import { Tag } from '@dt/material-components';
// @ts-ignore
import { palette } from '@dt/theme';
var useStyles = makeStyles({
    link: { fontSize: 13, color: palette.blue20, textDecoration: 'underline', marginTop: '3px' },
    button: {
        all: 'unset',
        ontSize: 13,
        color: palette.blue20,
        textDecoration: 'underline',
        marginTop: '3px',
        cursor: 'pointer',
    },
    tags: {
        display: 'grid',
        gap: 8,
        gridTemplateColumns: '1fr 1fr',
        '&>span': {
            justifyContent: 'flex-start',
            '&>span': {
                maxWidth: function (props) { return (props.largeTags ? 150 : 65); },
            },
        },
    },
    tagsNotExpandable: {
        display: 'grid',
        gap: 8,
        gridTemplateColumns: '1fr 1fr 1fr',
        '&>span': {
            justifyContent: 'flex-start',
            '&>span': {
                maxWidth: function (props) { return (props.largeTags ? 150 : 65); },
            },
        },
    },
});
var InventoryLayoutApiAssetsTabTableRowTags = function (_a) {
    var tags = _a.tags, _b = _a.largeTags, largeTags = _b === void 0 ? false : _b;
    var _c = useReducer(function (state) { return !state; }, false), isExpanded = _c[0], toggleIsExpanded = _c[1];
    var styles = useStyles({ largeTags: largeTags });
    var validTags = [];
    tags.forEach(function (tag) {
        if (tag)
            validTags.push(tag);
    });
    if (validTags.length === 0) {
        return null;
    }
    var isExpandable = validTags.length > 2;
    var createTooltipText = function (tag) {
        return tag.value ? "".concat(tag.tag, ": ").concat(tag.value) : tag.tag;
    };
    var displayedTags = isExpanded ? validTags : validTags.slice(0, 2);
    return (React.createElement(Box, { style: { display: 'flex', gap: '8px', alignItems: 'flex-start' } },
        React.createElement("div", { className: styles.tags }, displayedTags.map(function (tag) { return (React.createElement(Tag, { key: tag.id, noPadding: true, label: tag.tag, tooltipText: createTooltipText(tag), width: "100%" })); })),
        isExpandable && (React.createElement("button", { className: styles.button, onClick: toggleIsExpanded }, isExpanded ? 'Less -' : "+ ".concat(validTags.length - 2, " more")))));
};
export default InventoryLayoutApiAssetsTabTableRowTags;
