import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
var productMapping = {
    api: 'API',
    web: 'Web',
    mobile: 'Mobile',
    cloud: 'Cloud',
    openscan: 'Supply Chain Security',
    dashboard: 'Dashboard',
};
var NavigationNoAccess = function (_a) {
    var product = _a.product;
    return (React.createElement("div", { style: {
            display: 'flex',
            alignItems: 'center',
            padding: 16,
            justifyContent: 'center',
            backgroundColor: palette.blue50,
        } },
        React.createElement(InfoIcon, { style: { marginRight: 16, color: palette.blue10 } }),
        React.createElement(Text, { variant: "titleXS", component: "div" }, "".concat(productMapping[product], " Secure is not currently licensed. Please contact")),
        React.createElement("a", { href: "mailto:support@datatheorem.com" },
            React.createElement(Text, { variant: "titleXS", component: "div", style: { color: 'rgb(0,0,238)' } }, "\u00A0support@datatheorem.com\u00A0")),
        React.createElement(Text, { variant: "titleXS", component: "div" }, "for temporary access and/or live demo of your results.")));
};
export default NavigationNoAccess;
