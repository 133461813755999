//      
import { createAction } from 'redux-actions';
export const eventsReceived = createAction                                             (
  'events/received',
  events => events,
);

export const activityDashboardMounted = createAction 
                             
     
                                     
 ('mounted/events/dashboard', () => ({
  key: 'activity_dashboard',
}));
