//     
import React from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

const CenteredCircularProgress = () => (
  <Box style={{ width: '100%', height: '100%' }} display="flex" alignItems="center" justifyContent="center">
    <CircularProgress color="primary" size={45} />
  </Box>
);

export default CenteredCircularProgress;
