import React, { memo } from 'react';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Checkbox, List, ListItem, ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
// @ts-ignore TODO-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
// @ts-ignore TODO-THEME
import { palette } from '@dt/theme';
var useStyles = makeStyles({
    mainList: {
        maxHeight: 350,
        overflow: 'auto',
        overflowX: 'auto',
    },
    list: {
        padding: 0,
        paddingLeft: '16px',
    },
    itemLogo: {
        justifyContent: 'center',
    },
});
var TableFiltersSeverity = function (_a) {
    var selectedList = _a.selectedList, onChange = _a.onChange;
    var classes = useStyles();
    var handleChange = function (id) {
        if (selectedList.includes(id)) {
            onChange(selectedList.filter(function (item) { return item !== id; }));
        }
        else {
            onChange([id]);
        }
    };
    return (React.createElement(List, { className: classes.mainList, component: "nav" },
        React.createElement(ListItem, { button: true, dense: true, selected: selectedList.includes('FAILED'), onClick: function () { return handleChange('FAILED'); } },
            React.createElement(Checkbox, { checked: Boolean(selectedList.includes('FAILED')) }),
            React.createElement(ListItemIcon, { className: classes.itemLogo },
                React.createElement(Avatar, { style: { height: 24, width: 24, backgroundColor: palette.red50 } },
                    React.createElement(WarningIcon, { style: { fontSize: 16, fill: palette.red10 } }))),
            React.createElement(Text, { component: 'div', variant: 'body', noWrap: true }, "FAIL")),
        React.createElement(ListItem, { button: true, dense: true, selected: selectedList.includes('PASSED'), onClick: function () { return handleChange('PASSED'); } },
            React.createElement(Checkbox, { checked: Boolean(selectedList.includes('PASSED')) }),
            React.createElement(ListItemIcon, { className: classes.itemLogo },
                React.createElement(Avatar, { style: { height: 24, width: 24, backgroundColor: palette.green50 } },
                    React.createElement(ThumbUpIcon, { style: { fontSize: 16, fill: palette.green10 } }))),
            React.createElement(Text, { component: 'div', variant: 'body', noWrap: true }, "PASS"))));
};
export default memo(TableFiltersSeverity);
