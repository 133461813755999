//      
import immer from 'immer';

import { graphqlApisReceived } from './actions';
                                                  

const initialState = {
  id: {},
  for_policy_violation_id: {},
};

                                
                                           
                                                            
   

                                                      

export default immer                          ((draft, action         ) => {
  if (action.type === graphqlApisReceived.toString()) {
    for (const graphql_api of action.payload.graphql_apis) {
      draft.id[graphql_api.id] = graphql_api;
      for (const policy_violation_id of graphql_api.policy_violation_ids) {
        draft.for_policy_violation_id[policy_violation_id] = graphql_api.id;
      }
    }
  }
}, initialState);
