//     
import gql from 'graphql-tag';

const get = gql`
  query AlertsIntegrationsGet($id: ID!) {
    alerts_integrations_get(id: $id) {
      id
      name
      integration_type
      status
      most_recent_attempt_date
      is_api_protect_alerting_enabled
      is_mobile_protect_alerting_enabled

      webhook_config {
        authorization_header
        subscribed_events
        subscribed_to_all_events
        url
      }

      jira_config {
        instance_url
        username
        password
        project_key
        issue_type
        relevance_field_id
        relevance_proactive_value {
          name
          id
        }
        relevance_important_value {
          name
          id
        }
        relevance_urgent_value {
          name
          id
        }
        policy_violations_auto_export_setting
        static_fields {
          field_id
          field_type
          string_value {
            name
            id
          }
          string_array_value {
            name
            id
          }
          component_array_value {
            name
            id
          }
          option_value {
            name
            id
          }
          option_array_value {
            name
            id
          }
          number_value {
            name
            id
          }
          user_value {
            name
            id
          }
        }
        should_export_asset_types
        should_export_asset_group_uuids
        should_export_policy_rule_uuids
      }

      service_now_config {
        instance_url
        username
        policy_violations_auto_export_setting
      }

      datadog_config {
        instance_url
        api_key
        policy_violations_auto_export_setting
        should_export_api_issues
        should_export_cloud_issues
        should_export_web_issues
      }

      slack_or_msft_teams_config {
        url
        should_send_asm_notifications_for_asset_types
        should_send_asm_notifications_for_all_asset_types
        should_send_asm_notifications
        mobile_secure_config {
          should_notify_about_mobile_scan_alerts
          should_send_summary_notifications
          should_send_real_time_notifications
          should_notify_about_security_p1_issues
          should_notify_about_store_blocker_issues
          should_notify_about_high_severity_issues
          should_notify_about_medium_severity_issues
          should_notify_about_low_severity_issues
          should_notify_about_all_mobile_apps
          should_notify_about_mobile_app_ids
        }
        api_web_cloud_secure_config {
          should_send_summary_notifications
          should_send_real_time_notifications
        }
      }
    }
  }
`;

const list = gql`
  query AlertsIntegrationsList($filter_by_integration_type: [String], $cursor: String) {
    alerts_integrations_list(filter_by_integration_type: $filter_by_integration_type, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      alerts_integrations {
        id
        name
        integration_type
        status
        most_recent_attempt_date
      }
    }
  }
`;

const test = gql`
  mutation AlertsIntegrationsTest($body: AlertsIntegrationsTestRequest!) {
    alerts_integrations_test(body: $body) {
      nothing
    }
  }
`;

const create = gql`
  mutation AlertsIntegrationsCreate($body: AlertsIntegrationCreateRequest!) {
    alerts_integrations_create(body: $body) {
      id
      name
      integration_type
      status
      most_recent_attempt_date
    }
  }
`;

const update = gql`
  mutation AlertsIntegrationsUpdate($id: ID!, $body: AlertsIntegrationCreateRequest!) {
    alerts_integrations_update(id: $id, body: $body) {
      id
      name
      integration_type
      status
      most_recent_attempt_date
    }
  }
`;

const del = gql`
  mutation AlertsIntegrationsDelete($id: ID!) {
    alerts_integrations_delete(id: $id) {
      nothing
    }
  }
`;

const listEventValues = gql`
  query AlertsIntegrationListEventValues {
    alerts_integration_list_event_values {
      alerts_integration_event_values {
        name
        value
        value_category
      }
    }
  }
`;

export default {
  get,
  create,
  update,
  delete: del,
  list,
  test,
  listEventValues,
};
