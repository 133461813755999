var open_high = 'Open Issues (High)';
var open_medium = 'Open Issues (Medium)';
var open_low = 'Open Issues (Low)';
var closed_high = 'Closed Issues (High)';
var closed_medium = 'Closed Issues (Medium)';
var closed_low = 'Closed Issues (Low)';
export var chartLegend = {
    open_high: open_high,
    open_medium: open_medium,
    open_low: open_low,
    closed_high: closed_high,
    closed_medium: closed_medium,
    closed_low: closed_low,
};
var monthNameToDate = function (month_name) {
    var parts = month_name.split(' ');
    parts.splice(1, 0, '1');
    return parts.join(' ');
};
var mapTitle = function (title) {
    if (title.indexOf('High') > -1)
        return 'high';
    if (title.indexOf('Medium') > -1)
        return 'medium';
    if (title.indexOf('Low') > -1)
        return 'low';
    return 'medium';
};
export var formattedChartData = function (data) {
    var result = data.months.map(function (date) {
        var openMetrics = {
            date: monthNameToDate(date === null || date === void 0 ? void 0 : date.month_name),
            high: 0,
            medium: 0,
            low: 0,
        };
        date.bars[0].metrics.forEach(function (severity) {
            var title = mapTitle(severity.title);
            openMetrics[title] = Number(severity.count) || 0;
        });
        var closedMetrics = {
            date: monthNameToDate(date.month_name),
            high: 0,
            medium: 0,
            low: 0,
        };
        date.bars[1].metrics.forEach(function (severity) {
            var title = mapTitle(severity.title);
            closedMetrics[title] = Number(severity.count) || 0;
        });
        return {
            date: monthNameToDate(date.month_name),
            open: openMetrics,
            closed: closedMetrics,
        };
    });
    return result;
};
