//     
import React from 'react';
import { Match, Link } from '@reach/router';
import MenuItem from './Item';
                                  

              
                
                  
                   
                    
                 
                  
                   
              
                    
                  
                       
                 
                        
                       
                        
                    
  

export const MenuLinkItemReachRouter = (props       ) => {
  const {
    path,
    exact,
    strict,
    tooltip,
    replace,
    hasSubMenu,
    isSubMenu,
    isChildApp,
    ...appMenuItemProps
  } = props;

  return (
    <Match path={path || ''}>
      {({ match }) => {
        if (path) {
          return (
            <Link to={path} replace={replace} style={{ display: 'block' }}>
              <MenuItem
                tooltip={tooltip}
                hasSubMenu={hasSubMenu}
                isSubMenu={isSubMenu}
                isChildApp={isChildApp}
                active={!!match}
                {...appMenuItemProps}
              />
            </Link>
          );
        }

        return <MenuItem {...appMenuItemProps} />;
      }}
    </Match>
  );
};
