//      
import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Divider, Avatar } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { palette } from '@dt/theme';
import { AssetTypeDict } from '@dt/material-components';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { makeStyles } from '@mui/styles';
import { AssetsLink } from './AssetsLink';
import { AssetsTypeIcon } from '../../components/assets/AssetsTypeIcon';
                                                               

              
                                                                  
                  
                               
                                 
                             
                   
                
                  
     
  

const useStyles = makeStyles({
  avatar: { color: palette.gray20, backgroundColor: palette.gray45 },
});

/*
 * Shows the user the relationship between multiple assets.
 * An asset's connected assets.
 *
 * @param assets - List of connected assets.
 * @param productBasePath - Used to maintain navigational context for the user.
 */
const AssetsConnectedAssetsComponent = function AssetsConnectedAssets({ assets, productBasePath }       ) {
  const classes = useStyles();

  if (assets.length > 0) {
    return (
      <>
        {assets.map((a, i) => {
          const location = (a.asset_type === AssetTypeDict.CLOUD_RESOURCE ? a.name : a.url) || '';

          return (
            <Box key={a.id}>
              <Box p="4px 12px" key={a.id} display="flex" alignItems="center">
                <AssetsTypeIcon
                  key={a.id}
                  asset_type_name={a.asset_type_name}
                  asset_type_icon_url={a.asset_type_icon_url}
                />
                <Box p="12px">
                  <Typography>{`${a.asset_type_name} - ${location}`}</Typography>
                </Box>
                <Box p="12px" color={palette.gray20} display="flex" ml="auto">
                  <AssetsLink productBasePath={productBasePath} asset_type={a.asset_type} asset_id={a.id}>
                    <OpenInNewIcon color="inherit" />
                  </AssetsLink>
                </Box>
              </Box>
              {i < assets.length - 1 && <Divider />}
            </Box>
          );
        })}
      </>
    );
  }

  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      <Box mr="12px">
        <Avatar className={classes.avatar}>
          <TrackChangesIcon color="inherit" />
        </Avatar>
      </Box>
      <Typography variant="body1" color="textPrimary">
        This asset doesn&apos;t seem to be connected to any other assets
      </Typography>
    </Box>
  );
};

export const AssetsConnectedAssets = AssetsConnectedAssetsComponent;
