//     
import createReducerCreator from 'rezz/createReducerCreator';
import set from 'rezz/set';
import { createAction } from 'redux-actions';

                                                             

// $FlowFixMe FlowUpgrade
export const setRowCount = createAction                       ('SET_ROW_COUNT', (query        , rowCount        ) => ({
  query,
  rowCount,
}));

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: {},
  payloadStrategy: action => action.payload.rowCount,
  keyStrategy: action => action.payload.query,
})(set(setRowCount.toString()));
