//      
import React, { memo } from 'react';
import SvgIcon from '@mui/material/SvgIcon';

                                          

function OculusLogo(props       ) {
  return (
    <SvgIcon {...props} viewBox="0 0 2400 2400">
      <path d="m 1830,1200 c -32.8,-22.56 -69.1,-36.13 -108.1,-42.47 -39,-6.33 -77.8,-5.09 -116.83,-5.09 -268.22,0 -536.44,0 -804.67,0 -39.08,0 -77.89,-1.24 -116.92,5.11 -39.05,6.35 -75.35,19.95 -108.13,42.55 -65.65,45.26 -105.07,119.42 -105.03,199.34 0.05,79.88 39.51,153.98 105.17,199.19 32.77,22.55 69.05,36.13 108.08,42.46 39,6.33 77.78,5.09 116.83,5.09 268.23,0 536.45,0 804.67,0 39.13,0 77.93,1.24 116.93,-5.1 39,-6.35 75.3,-19.96 108.1,-42.55 65.7,-45.27 105.1,-119.43 105,-199.34 0,-79.89 -39.5,-153.99 -105.1,-199.19 z m 324.4,772.25 c -86.4,69.35 -185.3,117.25 -292.8,143.12 -61.7,14.81 -123.3,21.39 -186.4,23.46 -46.7,1.52 -93.43,1.1 -140.15,1.1 -221.54,0 -443.08,0 -664.63,0 -46.74,0 -93.47,0.42 -140.21,-1.1 -63.14,-2.07 -124.8,-8.66 -186.45,-23.5 -107.56,-25.89 -206.39,-73.8 -292.77,-143.15 -173.74,-139.49 -274.91,-350.06 -274.89,-572.94 0.02,-222.86 101.22,-433.42 274.98,-572.87 86.41,-69.35 185.25,-117.24 292.84,-143.11 61.61,-14.82 123.24,-21.4 186.34,-23.46 46.73,-1.53 93.44,-1.1 140.16,-1.1 221.55,0 443.09,0 664.63,0 46.74,0 93.45,-0.43 140.25,1.1 63.1,2.06 124.8,8.66 186.4,23.49 107.6,25.89 206.4,73.81 292.8,143.16 173.7,139.48 274.9,350.06 274.9,572.93 0,222.87 -101.2,433.42 -275,572.87" />
    </SvgIcon>
  );
}

export default memo       (OculusLogo);
