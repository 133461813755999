//      

import React, { memo } from 'react';
import { TableCell, Tooltip, Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import HelpIcon from '@mui/icons-material/Help';
import { Text } from '@dt/material-components';
import { palette } from '@dt/theme';
                                                                                        

              
                                                  
  

const InventoryTableCellVendor = ({ vendor }) => {
  return (
    <TableCell>
      {vendor ? (
        <Tooltip disableInteractive title={vendor.name}>
          <Box display={'flex'} alignItems={'center'}>
            <Box display={'flex'} mr={1}>
              <img height={24} width={24} src={vendor.icon_url} alt={`${vendor.name} Icon`} />
            </Box>
            <Text variant={'body'} component={'div'} noWrap>
              {vendor.name}
            </Text>
          </Box>
        </Tooltip>
      ) : (
        <Box display={'flex'} alignItems={'center'}>
          <Box display={'flex'} mr={1}>
            <Avatar
              style={{
                backgroundColor: palette.gray45,
                color: palette.gray20,
                width: 24,
                height: 24,
              }}
            >
              <HelpIcon fontSize={'small'} />
            </Avatar>
          </Box>
          <Text variant={'body'} component={'div'} noWrap>
            {'Unknown Vendor'}
          </Text>
        </Box>
      )}
    </TableCell>
  );
};

export default memo       (InventoryTableCellVendor);
