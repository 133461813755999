//     

const storeBlockers = {
  APPLE_P1: 'APPLE_P1',
  GOOGLE_P1: 'GOOGLE_P1',
};

const importance = {
  SECURITY_P1: 'SECURITY_P1',
  SECURITY: 'SECURITY',
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  ...storeBlockers,
};

export default importance;

                                                                         

export const StoreBlockersEnumValues = storeBlockers;

                                                                   
                       
  
