//      
import gql from 'graphql-tag';

const list = gql`
  query VendorsList(
    $filter_by_text: String
    $filter_by_related_first_party_asset_id: [ID]
    $filter_by_related_first_party_asset_type: [SupplyChainSecureAppTypeEnum]
    $filter_by_asset_type: [AssetTypeEnum]
    $filter_by_uses_third_party_vendor_id: [String]
    $filter_by_asset_type_group: [ThirdPartyAssetTypeGroupEnum]
    $cursor: String
    $include: VendorsListInclude
    $page_size: Float
    $order_by: VendorListOrderByEnum
  ) {
    vendors_list(
      filter_by_text: $filter_by_text
      filter_by_related_first_party_asset_id: $filter_by_related_first_party_asset_id
      filter_by_related_first_party_asset_type: $filter_by_related_first_party_asset_type
      filter_by_asset_type: $filter_by_asset_type
      filter_by_uses_third_party_vendor_id: $filter_by_uses_third_party_vendor_id
      filter_by_asset_type_group: $filter_by_asset_type_group
      cursor: $cursor
      include: $include
      page_size: $page_size
      order_by: $order_by
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      vendors {
        id
        icon_url
        name
        website_url
        date_created
        date_updated
        included_supply_chain_secure_stats {
          cloud_resources_count
          graphql_apis_count
          mobile_sdks_count
          open_important_policy_violations_count
          open_proactive_policy_violations_count
          open_urgent_policy_violations_count
          related_first_party_mobile_apps_count
          related_first_party_web_apps_count
          restful_apis_count
        }
        included_supply_chain_secure_dashboard_metrics {
          embed_rate_percent
          total_third_party_assets_count
          total_third_party_assets_count_30_day_trend
          total_urgent_policy_violations_count
          total_urgent_policy_violations_count_30_day_trend
        }
      }
    }
  }
`;

const mobile_sdks_list = gql`
  query VendorsMobileSdkList($vendorId: ID!, $cursor: String) {
    vendors_mobile_sdk_list(vendorId: $vendorId, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      mobile_sdks {
        id
        status
        asset_type_icon_url
        asset_type_name
        date_created
        name
        sevenhell_sdk_uuid
      }
    }
  }
`;

export default {
  list,
  mobile_sdks: {
    list: mobile_sdks_list,
  },
};
