//     

/*
 * @deprecated - Don't relly on this.
 */
export const restNormalizer = (body     )        => {
  // Normalize 'restful_apis'.
  if (body && body.restful_apis && Array.isArray(body.restful_apis)) {
    const { restful_apis, api_operations } = separateOperationsFromApis(body.restful_apis);
    return {
      ...body,
      api_operations,
      restful_apis,
    };
  }

  return body;
};

function separateOperationsFromApis(data) {
  const restful_apis = [];
  const api_operations = [];

  for (const api of data) {
    if (!api || typeof api !== 'object') {
      continue;
    }

    // copy the operations into their own collection
    const api_operation_ids = [];
    if (api.api_operations && Array.isArray(api.api_operations)) {
      for (const operation of api.api_operations) {
        if (operation && typeof operation.id === 'string') {
          api_operation_ids.push(operation.id);
        }
        api_operations.push(operation);
      }
    }

    restful_apis.push({ ...api, api_operation_ids });
  }

  return {
    restful_apis,
    api_operations,
  };
}
