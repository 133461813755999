import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, Snackbar, Tooltip } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import PolicyViolationExportToJiraDialogStep1 from './PolicyViolationExportToJiraDialogStep1';
import PolicyViolationExportToJiraDialogStep2 from './PolicyViolationExportToJiraDialogStep2';
import PolicyViolationExportToJiraDialogStepNoIntegrations from './PolicyViolationExportToJiraDialogStepNoIntegrations';
import jiraIcon from './jiraIcon.svg';
import useExportViolationExportToJira from './usePolicyViolationExportToJira';
var PolicyViolationExportToJira = function (_a) {
    var policyViolationId = _a.policyViolationId, policyViolationName = _a.policyViolationName, jiraTicketId = _a.jiraTicketId;
    var _b = useState('no-integrations'), step = _b[0], setStep = _b[1];
    var _c = useState(false), isModalOpen = _c[0], setIsModalOpen = _c[1];
    var _d = useState(), jiraIntegration = _d[0], setJiraIntegration = _d[1];
    var _e = useState(jiraTicketId), ticketId = _e[0], setJiraTicketId = _e[1];
    var _f = useState(null), snackOpen = _f[0], setSnackOpen = _f[1];
    var handleClose = function () {
        setStep('select');
        setJiraIntegration(undefined);
        setIsModalOpen(false);
    };
    var handleSnackClose = function (event) {
        if (event)
            event.stopPropagation();
        setSnackOpen(null);
    };
    var _g = useExportViolationExportToJira({
        policyViolationId: policyViolationId,
        setJiraTicketId: setJiraTicketId,
        setSnackOpen: setSnackOpen,
        handleClose: handleClose,
    }), data = _g.data, loading = _g.loading, fetchMore = _g.fetchMore, exportToJira = _g.exportToJira, errorExportToJira = _g.errorExportToJira, loadingExportToJira = _g.loadingExportToJira;
    var items = ((data === null || data === void 0 ? void 0 : data.alerts_integrations_list) || {}).alerts_integrations;
    var isExportButtonDisabled = loading || loadingExportToJira || jiraTicketId;
    useEffect(function () {
        if ((items === null || items === void 0 ? void 0 : items.length) === 1) {
            setStep('confirm');
            setJiraIntegration(items[0]);
        }
        if ((items === null || items === void 0 ? void 0 : items.length) === 0) {
            setStep('no-integrations');
        }
        if ((items === null || items === void 0 ? void 0 : items.length) > 1) {
            setStep('select');
        }
    }, [items]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: jiraTicketId ? "This policy violation has already been exported to Jira as ticket ".concat(jiraTicketId) : '' },
            React.createElement("div", null,
                React.createElement(Button, { "aria-controls": "export-to-jira-button", "aria-haspopup": "true", onClick: function () { return setIsModalOpen(true); }, disabled: isExportButtonDisabled, variant: "contained", size: "small", startIcon: loadingExportToJira ? React.createElement(CircularProgress, { size: 18 }) : null },
                    React.createElement(Box, { display: "flex", alignItems: "center" },
                        React.createElement("img", { src: jiraIcon, alt: "jira-icon" }),
                        React.createElement(Box, { ml: 1 }, "Export to Jira"))))),
        React.createElement(Dialog, { open: isModalOpen, onClose: handleClose, fullWidth: true, maxWidth: "sm", id: "jira-export-menu" },
            step === 'no-integrations' && (React.createElement(PolicyViolationExportToJiraDialogStepNoIntegrations, { handleClose: handleClose })),
            step === 'select' && (React.createElement(PolicyViolationExportToJiraDialogStep1, { handleClose: handleClose, setJiraIntegration: setJiraIntegration, handleNext: function () {
                    setStep('confirm');
                }, jiraIntegration: jiraIntegration, fetchMore: fetchMore, integrations: items })),
            step === 'confirm' && (React.createElement(PolicyViolationExportToJiraDialogStep2, { handleClose: handleClose, loading: loadingExportToJira, errorMessage: errorExportToJira === null || errorExportToJira === void 0 ? void 0 : errorExportToJira.message, handleNext: function () {
                    if (jiraIntegration) {
                        exportToJira({
                            variables: {
                                id: policyViolationId,
                                body: {
                                    alerts_integration_id: jiraIntegration.id,
                                },
                            },
                        });
                    }
                }, policyViolationName: policyViolationName, jiraIntegrationName: (jiraIntegration === null || jiraIntegration === void 0 ? void 0 : jiraIntegration.name) || '' }))),
        React.createElement(Snackbar, { anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 5000, open: Boolean(snackOpen), onClose: handleSnackClose },
            React.createElement(Box, null,
                snackOpen === 'ERR' && (React.createElement(Alert, { onClose: handleSnackClose, severity: "error" }, "An error occurred. Please try again later.")),
                snackOpen === 'OK' && (React.createElement(Alert, { onClose: handleSnackClose, severity: "success" }, "Success! The violation was exported to the following Jira Ticket: ".concat(ticketId)))))));
};
export default PolicyViolationExportToJira;
