//     
import React from 'react';
import { Link } from '@reach/router';
import { AssetTypeEnumValues } from '@dt/graphql-support/enums';
import { palette } from '@dt/theme';
                                  
                                                               

const assetTypeToPath                                      = Object.freeze({
  [AssetTypeEnumValues.CLOUD_RESOURCE]: 'cloud-resources',
  [AssetTypeEnumValues.NETWORK_SERVICE]: 'network-services',
  [AssetTypeEnumValues.RESTFUL_API]: 'assets',
  [AssetTypeEnumValues.WEB_APPLICATION]: 'web-applications',
  [AssetTypeEnumValues.API_OPERATION]: 'api-operations',
  [AssetTypeEnumValues.GRAPHQL_API]: 'graphql-apis',
  [AssetTypeEnumValues.GRPC_SERVICE]: 'assets',
  [AssetTypeEnumValues.GRPC_METHOD]: 'assets',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: 'assets',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: 'assets',
  [AssetTypeEnumValues.SOAP_API]: 'assets',
  [AssetTypeEnumValues.SOAP_API_OPERATION]: 'assets',
  [AssetTypeEnumValues.REPOSITORY]: 'assets',
});

              
                                                                  
                  
                 
                    
                
                               
       
    
  

const PolicyViolationsAffectedAssetLinkComponent = function PolicyViolationsAffectedAssetLink({
  productBasePath,
  affected_asset,
  children,
  isV2,
}       ) {
  return (
    <Link
      to={`${productBasePath}/${isV2 ? 'v2/' : ''}${assetTypeToPath[affected_asset.asset_type]}/${affected_asset.id}`}
      style={{ color: palette.blue20 }}
    >
      {children}
    </Link>
  );
};

export const PolicyViolationsAffectedAssetLink = PolicyViolationsAffectedAssetLinkComponent;
