//      

import { cloud_resource_types } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { put } from 'redux-saga/effects';
import { withCache } from '../../resource_fetch/sagas';
import { InventorySearchcloudResourceTypesReceived } from './actions';

export const getAllCloudResourceTypes = withCache         (
  'cloud_resource_types',
  function* getCloudResourceTypes()             {
    const response = yield* callPromise(cloud_resource_types.list);

    if (response._type === 'error') {
      throw new Error('response error');
    }

    if (response.body.cloud_resource_types) {
      yield put(InventorySearchcloudResourceTypesReceived(response.body.cloud_resource_types));
    }
  },
);
