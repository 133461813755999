//      
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';

import { Card as ProtectionBadgeCard } from '@dt/components/src/app-protection-badges';
                                                                  
import AppProtectionBadgeTasksDialog from '@dt/ahura/src/components/protection/AppProtectionBadgeTasksDialog';
import { badgeToIssueTypeMap } from '@dt/components';

const LARGE_SIZE = 72;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  card: {
    minWidth: 120,
    width: '18%',
    margin: 2,
  },
});

function getTasksForBadge(badge, tasksForApp, badgeToIssueTypeMapping) {
  const issueTypeIds = [];
  badgeToIssueTypeMapping
    .filter(mapping => mapping.protectionBadge === badge)
    .forEach(issueType => issueTypeIds.push(issueType.taskIssueTypeId));

  return tasksForApp.filter(task => issueTypeIds.includes(task.issue_type_id));
}

function tasksDialog(
  badgeType               ,
  enabled         ,
  tasksForBadge                                   ,
  onClose            ,
) {
  return (
    <AppProtectionBadgeTasksDialog
      onClose={onClose}
      badgeType={badgeType}
      enabled={enabled}
      tasksForBadge={tasksForBadge}
    />
  );
}

               
                     
                                                 
                                                   
   

function ProtectionBadgeList(props) {
  const { badges, tasksForApp } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {badges.map(([badge, enabled], i) => (
        <div key={i} className={classes.card}>
          <ProtectionBadgeCard
            type={badge}
            enabled={enabled}
            width={LARGE_SIZE}
            height={LARGE_SIZE}
            tasksForBadge={tasksForApp ? getTasksForBadge(badge, tasksForApp, badgeToIssueTypeMap) : []}
            tasksDialog={tasksForApp ? tasksDialog : () => {}}
          />
        </div>
      ))}
    </div>
  );
}

export default memo       (ProtectionBadgeList);
