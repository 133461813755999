//      
import React, { memo } from 'react';
import CodeIcon from '@mui/icons-material/Code';

import Tag from './Tag';

              
                
  

function TagRegion({ value }       ) {
  return <Tag icon={<CodeIcon fontSize="small" />} label={`Runtime: ${value}`.toUpperCase()} />;
}

export default memo       (TagRegion);
