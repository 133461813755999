//      
import config from '@dt/config';
import qs from 'query-string';
import fetch, { parse } from '@dt/fetch';

import {
                           
                      
                           
                      
                          
                       
                         
                  
                       
                            
                         
                      
                          
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                            
                                         
                                
                                          
                                
                                         
   

                                              
                                 
                                 
                                            
                                 
                                       
                      
                                        
   

export async function list(params   
      
 )                                                                {
  return fetch(
    `${api}/${version}/network_services?${qs.stringify(params)}`,
  ).then(parse);
}

export async function details(
  id        ,
)                                               {
  return fetch(`${api}/${version}/network_services/${id}`).then(parse);
}

                                    
                       
                
                                                  
                             
   

export async function create(
  params                            ,
)                                {
  return fetch(`${api}/${version}/network_services`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}
