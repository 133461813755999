//     
import tracking from 'react-tracking';
import { isDataTheoremUser } from '@dt/session';
import {            memo } from 'react';
                                                             

               
                           
                    
                 
   

const DATATHEOREM_CUSTOMER_NAMES = ['Data Theorem', 'SideScreen'];

function getCustomerName({ currentUser, accountInfo }) {
  if (
    currentUser.login_email &&
    isDataTheoremUser(currentUser) &&
    !DATATHEOREM_CUSTOMER_NAMES.includes(accountInfo.name)
  ) {
    return '_DT_';
  } else {
    return accountInfo.name;
  }
}

export default memo       (
  tracking          ((props       ) => ({
    customer_name: getCustomerName(props),
  }))(function CustomerNameContext({ children }) {
    return children;
  }),
);
