//     
import createReducerCreator from 'rezz/createReducerCreator';
import assign from 'rezz/assign';
                                                
import { openscanConfigReceived } from '../actions';
                                                                        

                                                                    

export default createReducerCreator                                                                      ({
  initialState: (null                     ),
})(assign(openscanConfigReceived.toString()));
