//      
import React, { memo } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { Text } from '@dt/material-components';
import { Message } from '@components';
import AssetTagsEditDialogAssetTagRow from './AssetTagsEditDialogAssetTagRow';
import AssetTagsEditDialogLogs from './AssetTagsEditDialogLogs';
import AssetTagsEditDialogSave from './AssetTagsEditDialogSave';
import AssetTagsEditDialogAddTagComponent from './AssetTagsEditDialogAddTag';
import useAssetTagEdit from './use_asset_tag_edit.js';
import { palette } from '@dt/theme';

              
                  
                      
                  
                    
  

const useStyles = makeStyles({
  box: {
    paddingBottom: 16,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: palette.gray50,
  },
});

const AssetTagsEditDialog = ({ isOpen, onClose, assetId, assetName }       ) => {
  const classes = useStyles();

  const assetTags = useAssetTagEdit({ onClose, assetId });

  return (
    <Dialog fullWidth open={isOpen} onClose={() => onClose()}>
      <DialogTitle>
        <Text component={'div'} variant={'titleM'}>
          Edit Tags
        </Text>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.box}>
        <Grid container spacing={2}>
          <Grid item container xs={12}>
            <Text component={'div'} variant={'body'} noWrap>
              You are editing tags for {assetName || 'selected asset'}
            </Text>
          </Grid>
          {assetTags.error && (
            <Grid item xs={12}>
              <Message
                m={0}
                variant={'error'}
                icon={<ErrorIcon />}
                message={assetTags.error.message.replace('GraphQL error: ', '')}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {assetTags.loading
                ? [0, 1, 2, 3, 4, 5].map((_, key) => (
                    <Grid item xs={12} container spacing={2} key={key}>
                      <Grid item xs={6}>
                        <Skeleton animation="wave" width={`100%`} height={40} />
                      </Grid>
                      <Grid item xs={6}>
                        <Skeleton animation="wave" width={`100%`} height={40} />
                      </Grid>
                    </Grid>
                  ))
                : assetTags.list.map((assetTag, idx) => (
                    <Grid item xs={12} key={idx}>
                      <AssetTagsEditDialogAssetTagRow
                        assetTag={assetTag}
                        validation={assetTags.validations.find(
                          ({ tag, value }) => tag === assetTag.tag && value === assetTag.value,
                        )}
                        onUpdate={assetTags.update}
                        onRemove={assetTags.remove}
                      />
                    </Grid>
                  ))}
              {!assetTags.loading ? (
                <Grid item xs={12}>
                  <AssetTagsEditDialogAddTagComponent
                    disabled={!assetTags.shouldShowAddNewTagBtn}
                    onClick={assetTags.onAddTagClick}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {!assetTags.loading && assetTags.logs.length ? (
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12}>
                <AssetTagsEditDialogLogs logs={assetTags.logs} />
              </Grid>
              <Grid item xs={12}>
                <AssetTagsEditDialogSave
                  confirm={assetTags.saveChanges}
                  disabled={Boolean(assetTags.validations?.length)}
                  cancel={onClose}
                />
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo       (AssetTagsEditDialog);
