//      
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import {
                         
                 
                  
                  
                      
                          
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                   
                             
                               
                                 
                                 
                                
   

                           
             
                       
               
                      
                             
                             
                                                       
   

                                                        

                                       
                               
                   
   

export async function list()                                                           {
  return fetch(`${api}/${version}/asset_groups`).then(parse);
}

                                          
                               
                   
                     
                       
                              
                                        
   

export async function details(asset_group_id        )                                           {
  return fetch(`${api}/${version}/asset_groups/${asset_group_id}`).then(parse);
}

                                
               
                      
                             
   

export async function create(params                        )                                           {
  return fetch(`${api}/${version}/asset_groups`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export async function remove(asset_group_id        )                   {
  return fetch(`${api}/${version}/asset_groups/${asset_group_id}`, {
    method: 'DELETE',
  }).then(r => r.ok);
}

                               
                
                       
                              
   

export async function patch(
  asset_group_id        ,
  params                       ,
)                                           {
  return fetch(`${api}/${version}/asset_groups/${asset_group_id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  }).then(parse);
}

export const AssetGroupMembershipEnum = {
  ASSET_SEARCH_MATCH: 'ASSET_SEARCH_MATCH',
  MANUAL: 'MANUAL',
};

                                                                                  

                                     
             
                       
                                                
                         
                             
                                
                                    
                                   
                                    
   

                                                                            

                                             
                                                    
   

                                           
                                    
                                     
                                     
                                 
   

export const memberships = {
  list: async (asset_group_id        )                                                              =>
    fetch(`${api}/${version}/asset_groups/${asset_group_id}/memberships`).then(parse),

  create: async (
    asset_group_id        ,
    memberships                            ,
  )                                              =>
    fetch(`${api}/${version}/asset_groups/${asset_group_id}/memberships`, {
      method: 'POST',
      body: JSON.stringify(memberships),
    }).then(parse),
};

                                                                                   

                                              
                                                                 
                                                              
                                                                    
                                                                               
                                                     
                                                    
  

                                         
                                                
  

export const shadow = {
  memberships: {
    delete: async (membership_id        )                      =>
      fetch(`${api}/${version}/asset_groups/shadow/memberships/${membership_id}`, {
        method: 'DELETE',
      }).then(parse),
  },
  configuration: {
    edit: async (configuration                                )                                                  =>
      fetch(`${api}/${version}/asset_groups/shadow/configuration`, {
        method: 'PUT',
        body: JSON.stringify({
          configuration,
        }),
      }).then(parse),
  },
};
