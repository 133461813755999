//     
import React, { PureComponent } from 'react';
import { fontFamilies, fontSizes, palette } from '@dt/theme';
import { Tabs as MUITabs } from '@mui/material';

export const tabStyle = {
  color: palette.navColor,
  fontFamily: fontFamilies.primary,
  fontSize: fontSizes.medium,
  textTransform: 'none',
};

export const activeTabStyle = {
  ...tabStyle,
  color: palette.gray20,
};

              
                 
               
                      
                       
     
  

export default class Tabs extends PureComponent        {
  render() {
    const { children, marginLeft, marginRight, value } = this.props;

    return (
      <MUITabs
        style={{ marginLeft: marginLeft || 0, marginRight: marginRight || 0 }}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        centered
        value={value}
      >
        {children}
      </MUITabs>
    );
  }
}
