//     
import React, { PureComponent } from 'react';
import Button from '@mui/material/Button';

export default class InquireButton extends PureComponent  
                                                                
   {
  render() {
    return (
      <Button variant="outlined" color="primary" aria-label="Upgrade" onClick={this.props.onClickInquire}>
        Upgrade
      </Button>
    );
  }
}
