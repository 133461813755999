//     
import React, { memo } from 'react';

import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import ExtLink from '../ExtLink';
import Text from '../Text';
import format from 'date-fns/format';
import { palette } from '@dt/theme';
                                                                                                      
import { makeStyles } from '@mui/styles';

               
                                                                      
   

export const useStyles = makeStyles({
  iconAvatar: {
    '&&': {
      padding: 0,
      width: 32,
      height: 32,
    },
  },
  detailAvatar: {
    '&&': {
      width: 18,
      height: 18,
    },
  },
});

export default memo       (function NotificationItem({ notification }       ) {
  const styles = useStyles();
  const { date_created, detail, detail_icon_url, icon_url, portal_url, subtitle, title } = notification;

  return (
    <ExtLink to={portal_url} target="_blank" aria-label={detail}>
      <Box display={'flex'}>
        <Box mr={1}>
          <Avatar className={styles.iconAvatar} src={icon_url} />
        </Box>
        <Box>
          {title ? (
            <Box mt={'5px'}>
              <Text component={'div'} color={palette.gray20} variant="titleXS" noWrap>
                {title}
              </Text>
            </Box>
          ) : null}
          {subtitle ? (
            <Box mb={'5px'}>
              <Text component={'div'} color={palette.gray30} variant="body">
                {subtitle}
              </Text>
            </Box>
          ) : null}
          <Box display={'flex'} mb={'4px'}>
            <Box mr={1}>
              <Avatar className={styles.detailAvatar} src={detail_icon_url} />
            </Box>
            <Box>
              <Text component="div" color={palette.gray20} variant="body">
                {detail}
              </Text>
            </Box>
          </Box>
          <Box>
            <Text component="div" color={palette.gray35} variant="caption">
              {format(date_created, 'M/DD/YYYY, h:mm:ss A')}
            </Text>
          </Box>
        </Box>
      </Box>
    </ExtLink>
  );
});
