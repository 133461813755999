//     
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { fontFamilies, fontSizes, palette } from '@dt/theme';
import classnames from 'classnames';

const useStyles = makeStyles({
  PriorityBadge: {
    height: fontSizes.small * 2,
    width: fontSizes.small * 2,
    fontSize: fontSizes.small,
    fontFamily: fontFamilies.primary,
    color: '#ccc',
    fontWeight: 'normal',
    borderRadius: 2,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc',
    textAlign: 'center',
    lineHeight: '24px',
    display: 'inline-block',
  },
  p1: {
    borderColor: palette.darkestBg,
    color: palette.darkestBg,
  },
  invertedColors: {
    color: palette.inacitveColor,
    borderColor: palette.inacitveColor,
    verticalAlign: 'super',
  },
  nonPriority: {
    backgroundColor: palette.brand,
    color: palette.brightBg,
    borderColor: palette.brand,
    width: 30,
    height: 30,
    lineHeight: '30px',
    fontSize: 16,
  },
});

               
                          
                            
   

export default memo       (function PriorityBadge(props) {
  const { type, invertedColors } = props;
  const classes = useStyles();

  if (!type && type !== 0) {
    return null;
  }

  return (
    <div
      className={classnames({
        [classes.PriorityBadge]: true,
        [classes.p1]: type === 'P1' || type === 'P0',
        [classes.invertedColors]: invertedColors,
        [classes.nonPriority]: typeof type !== 'string' ? true : type[0] !== 'P' && type.length !== 2,
      })}
    >
      {type}
    </div>
  );
});
