//     
import set from 'rezz/set';
import createReducerCreator from 'rezz/createReducerCreator';
import { handleActions } from 'redux-actions';

import Actions from './actions';

                                                            

                                
          
              
           
     
  

function progressIndicatorReducer(
  state         = 0,
  action                 ,
)         {
  const act         = action.type;

  switch (act) {
    case Actions.startProgressIndicator.toString():
      return state + 1;
    case Actions.stopProgressIndicator.toString():
      return state > 0 ? state - 1 : 0;
    default:
      return state;
  }
}

const initialState = {};
function createFirstRequestReducer(actions          ) {
  return createReducerCreator                         ({
    initialState: initialState,
    payloadStrategy: () => true,
    keyStrategy: action => action.payload,
  })(
    set('PROGRESS_INDICATOR_STOP'),
    handleActions(actions || {}, initialState),
  );
}

export default {
  progressIndicatorReducer: progressIndicatorReducer,
  createFirstRequestReducer: createFirstRequestReducer,
};
