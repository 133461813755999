//      
import { createAction } from 'redux-actions';

export const assetGroupsReceived = createAction                                                           (
  'asset_groups/received',
  asset_groups => asset_groups,
);

export const assetGroupsListMounted = createAction                                                          (
  'asset_groups/mounted',
  () => ({ key: 'asset_groups' }),
);

export const assetGroupsDetailsMounted = createAction 
                                 
           
                                                  
 ('asset_groups/details/mounted', id => ({
  key: 'asset_groups',
  assetGroupId: id,
}));

export const assetGroupsWizardListMounted = createAction 
                                
     
                                      
 ('asset_groups/wizard/mounted', () => ({ key: 'asset_groups_wizard' }));

export const assetGroupsCreate = createAction 
                        
   
      
                   
                          
                                 
                                                
       
    
    
                 
                        
                               
                                              
     
 ('asset_groups/create', params => params);

export const assetGroupsCreateSuccess = createAction                                                 (
  'asset_groups/create/success',
  params => params,
);

export const assetGroupsCreateError = createAction                                               (
  'asset_groups/create/error',
  params => params,
);

export const assetGroupsCreateDone = createAction                            ('asset_groups/create/done');

export const assetGroupDeleteClicked = createAction                                                 (
  'asset_groups/delete/clicked',
  asset_group_id => asset_group_id,
);

export const assetGroupChangePolicy = createAction 
                               
                                               
                                             
 ('asset_groups/change_policy', params => params);
