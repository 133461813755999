//     
import React from 'react';
import { CenteredCircularProgress } from '@dt/material-components';
import { useSession, useAuthorization, useTemporaryAccessToken } from '../session';
import { UnauthorizedError } from '../components/errors';
import { NotFoundPage } from '@dt/components';
import { PublicAppContext } from './shared_links/public_app/usePublicApp';
import { ErrorState } from '@dt/components';
import { useLocation } from '@reach/router';

/*
 * @deprecated - Only intended to be used for legacy api containers.
 */
const ApiAccessControls = function ApiAccessControls({ children }                   ) {
  const { loading: sessionLoading, error: sessionError, data: sessionData } = useSession();
  const { isAuthorized } = useAuthorization(sessionData, ['can_access_api_inspect']);

  if (sessionError) {
    return <ErrorState error="Currently unable to authenticate you." />;
  }

  if (sessionLoading || !sessionData) {
    return <CenteredCircularProgress />;
  }

  if (!isAuthorized) {
    return <UnauthorizedError />;
  }

  return children;
};

/*
 * High order component to augment pages with "api" access controls.
 *
 * @deprecated - Only intended to be used for legacy api containers.
 */
export const accessControlsApi = (Container     ) => {
  return (props     ) => (
    <ApiAccessControls>
      <Container {...props} />
    </ApiAccessControls>
  );
};

/*
 * @deprecated - Only intended to be used for legacy share containers.
 */
const SharedAppAccessControls = function SharedAccessControls({ token, children }                                  ) {
  const { error, loading } = useTemporaryAccessToken({ token });

  if (error) return <NotFoundPage />;
  if (loading) return <CenteredCircularProgress />;

  return <PublicAppContext.Provider value={true}>{children}</PublicAppContext.Provider>;
};

/*
 * High order component to augment pages with "share" access controls.
 *
 * @deprecated - Only intended to be used for legacy share containers.
 */
export const accessControlsShare = (Container     ) => {
  return (props     ) => (
    <SharedAppAccessControls {...props}>
      <Container {...props} />
    </SharedAppAccessControls>
  );
};

/*
 * @deprecated - Only intended to be used for legacy landing page.
 */
const LandingPageAppAccessControls = function SharedAccessControls({ children }                                  ) {
  const { loading: sessionLoading, error: sessionError, data: sessionData } = useSession();
  const { isAuthorized } = useAuthorization(sessionData, []);

  // Error State.
  if (sessionError) {
    return <ErrorState error="Currently unable to authenticate you." />;
  }

  // Loading State.
  if (sessionLoading || !sessionData) {
    return <CenteredCircularProgress />;
  }

  // Unauthorized State.
  if (!isAuthorized) {
    return <UnauthorizedError />;
  }

  return children;
};

/*
 * High order component to augment pages with "landing page" access controls.
 *
 * @deprecated - Only intended to be used for legacy landing page.
 */
export const accessControlsLandingPage = (Container     ) => {
  return (props     ) => (
    <LandingPageAppAccessControls {...props}>
      <Container {...props} />
    </LandingPageAppAccessControls>
  );
};

/*
 * @deprecated - Only intended to be used for legacy api containers.
 */
const UserManagementAccessControls = function UserManagementAccessControls({ children }                   ) {
  const { loading: sessionLoading, error: sessionError, data: sessionData } = useSession();

  const location = useLocation();
  const { isAuthorized } = useAuthorization(sessionData, ['can_invite_users']);

  if (sessionError) {
    return <ErrorState error="Currently unable to authenticate you." />;
  }

  if (sessionLoading || !sessionData) {
    return <CenteredCircularProgress />;
  }

  if (!isAuthorized || (isAuthorized && location?.pathname.indexOf('/management/users') === -1)) {
    return <UnauthorizedError />;
  }

  return children;
};

/*
 * High order component to augment pages with user management (cross-product) access controls.
 *
 * @deprecated - Only intended to be used for legacy user management api containers.
 */
export const userManagementControlsApi = (Container     ) => {
  return (props     ) => (
    <UserManagementAccessControls>
      <Container {...props} />
    </UserManagementAccessControls>
  );
};
