//     

import { call as originalCall } from 'redux-saga/effects';

export default function* callSaga                            (
  saga                            ,
  ...args      
)          {
  // We want errors to propogate
  return yield originalCall(saga, ...args); //eslint-disable-line redux-saga/no-unhandled-errors
}
