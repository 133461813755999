//      
import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const left_column_base = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'flex-start',
  minWidth: 192,
  height: '100%',
  overflowY: 'hidden',
  borderRadius: '4px',
};

const useStyles = makeStyles({
  grid_container: {
    flex: '1 1 auto',
    overflow: 'auto',
    backgroundColor: 'white',
  },
  grid_item_left: {
    background: palette.gray50,
    ...left_column_base,
  },
  grid_item_left_padded: {
    background: palette.gray50,
    ...left_column_base,
    borderRadius: 0,
  },
  grid_item_left_skeleton: {
    border: `1px solid ${palette.gray50}`,
    ...left_column_base,
  },
  grid_right_item: {
    height: '100%',
    overflow: 'auto',
  },
});

              
                    
                      
                 
  

export function TwoColumnLayout({ children }       ) {
  const css = useStyles();
  return (
    <Grid container direction="row" wrap="nowrap" classes={css.grid_container}>
      {children}
    </Grid>
  );
}

export function LeftColumn({ children, skeleton, padded }       ) {
  const css = useStyles();
  return (
    <Grid
      item
      xs={4}
      classes={skeleton ? css.grid_item_left_skeleton : padded ? css.grid_item_left_padded : css.grid_item_left}
    >
      {children}
    </Grid>
  );
}

export function RightColumn({ children }       ) {
  const css = useStyles();
  return (
    <Grid item xs={8} classes={{ root: css.grid_right_item }}>
      {children}
    </Grid>
  );
}
