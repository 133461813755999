//     

/**
 * NOTE: This file should be the first file included by entry points. This file's responsibility is to set up "globals"
 * and import libraries that affect the global namespace. Try to limit what you import here!
 */

import Raven from 'raven-js';
import './polyfill';
import config from '@dt/config';

import '@dt/theme/global';

if (config.sentryEnabled) {
  Raven.config(
    `https://${config.sentryPublicKey}@${config.sentryHost}/${config.sentryProjectId}`,
    {
      release: process.env.BITBUCKET_COMMIT || '',
    },
  ).install();

  Raven.setTagsContext({
    environment: process.env.NODE_ENV,
    version: process.env.BITBUCKET_BUILD_NUMBER,
  });

  /* eslint-disable no-console */
  if (console && console.info) {
    console.log('Acheron Sentry Initialized.');
  }
  /* eslint-disable no-console */
}

export { Raven };

/* eslint-disable no-console */
if (console && console.info && config.bitbucketBuildNumber) {
  console.info(
    'Acheron Bitbucket Pipeline',
    'https://bitbucket.org/datatheorem/obol/addon/pipelines/home#!/results/' +
      config.bitbucketBuildNumber,
  );
}
/* eslint-enable no-console */
