//     
import { array, boolean, number, object, string } from 'yup';
import qs from 'query-string';
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { result } from './util';
                                                   
                                                                                         
                                                                         
                                                                                           
                                                                           
                                                                                 
                                                                                                                  

// region Typing
                         
               
                                                
                      
                    
     
  

export const findingSortChoiceObject    
                       
                       
                   
                               
             
               
   = {
  PRIORITY: 'PRIORITY',
  SEVERITY: 'SEVERITY',
  STATUS: 'STATUS',
  DATE_CREATED: 'DATE_CREATED',
  AGE: 'AGE',
  NONE: 'NONE',
};

                                                                          

                                 
                    
                                                  
                                                           
                                                                                 
                                                            
             
                                                      
                          
       
    
                                                            
                         
                                                 
                  
                  
     
  

                           
             
                    
                      
                
                      
                                  
     
  

                                                        
// endregion

// region Type Validation
const SearchFilterQuerySchema = object().shape({
  keywords: string().nullable(),
  priorities: array()
    .of(string())
    .ensure(),
  severities: array()
    .of(string())
    .ensure(),
  store_blockers: array()
    .of(string())
    .ensure(),
  compliance_policies: array()
    .of(string())
    .ensure(),
  statuses: object().shape({
    statuses: array()
      .of(string())
      .ensure(),
    date_range: object().shape({
      from_date: string().nullable(),
      to_date: string().nullable(),
      type: string(),
    }),
  }),
  current_statuses: array()
    .of(string())
    .ensure(),
  release_types: array()
    .of(string())
    .ensure(),
  min_age: number(),
  sort_by: string(),
});

const SearchQuerySchema = object().shape({
  id: string(),
  is_owner: boolean().required(),
  is_private: boolean().required(),
  title: string().required(),
  description: string().required(),
  filter_query: SearchFilterQuerySchema.required(),
});

const SearchQueryListResponseSchema = object().shape({
  search_queries: array()
    .of(SearchQuerySchema)
    .ensure(),
  pagination_information: object().shape({
    next_cursor: string().nullable(),
    total_count: string().nullable(),
  }),
});
// endregion

                                              
                                
     
   

                                              
                                                   
                                                            
                                                                                  
                                                             
                
                                                       
                           
     
                                                             
                                                  
                    
                   
     
  

export async function create(params   
                      
                
                      
                                               
     
 )                       {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries`, {
    method: 'POST',
    body: JSON.stringify(params),
  })
    .then(parse)
    .then(result)
    .then(response => SearchQuerySchema.validate(response));
}

export async function get(id        )                       {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries/${id}`)
    .then(parse)
    .then(result)
    .then(response => SearchQuerySchema.validate(response));
}

                                                    

export async function list(params            )                        {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/search_queries${
      params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
    }`,
  )
    .then(parse)
    .then(result)
    .then(response => SearchQueryListResponseSchema.validate(response));
}

export async function patch(
  id        ,
  params   
                          
                    
                          
                                     
       
   ,
)                       {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(params),
  })
    .then(parse)
    .then(result)
    .then(response => SearchQuerySchema.validate(response));
}

export async function remove(id        ) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries/${id}`, {
    method: 'DELETE',
  }).then(parse);
}
