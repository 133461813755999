//      
import { createAction } from 'redux-actions';

export const restfulAPIsReceived = createAction 
                          
                                         
                                                                             
 ('restful_apis/received', (restful_apis, network_services) => ({
  restful_apis,
  network_services,
}));

export const restfulAPIDetailsMounted = createAction 
                                
           
   
                         
                               
       
    
 ('mounted/restful_api_details', restfulAPIId => ({
  restfulAPIId: restfulAPIId,
  key: 'restful_api_details',
}));

export const reachedEndOfRestfulApisList = createAction                                           (
  'restful_apis/list/reached_end',
  () => undefined,
);

export const reachedEndOfRestfulApisListForAssetSearchId = createAction 
                                         
           
         
 ('restful_apis/list/search/reached_end', search_id => search_id);

export const restfulAPIsReceivedIdsForAssetSearch = createAction 
                                     
                          
    
                     
                       
     
 ('restful_apis/search/ids/received', (searchId, ids) => ({
  searchId,
  ids,
}));
