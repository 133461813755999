//     
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  PreviewInline: { filter: 'blur(5px)' },
});

               
                 
   

export default memo       (function PreviewInline(props) {
  const { children } = props;
  const classes = useStyles();

  return <span className={classes.PreviewInline}>{children}</span>;
});
