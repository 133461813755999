//      

import { put } from 'redux-saga/effects';
import { openapi_definitions } from '@dt/horizon-api';
import { withCache } from './../resource_fetch/sagas';
import { openAPIDefinitionsReceived } from './actions';
import { callPromise } from '@dt/redux-saga-wrapped-effects';

export const getOpenAPIDefinitions = withCache         (
  'openapi_definitions_list',
  function* getOpenAPIDefinitions()             {
    const response = yield* callPromise(openapi_definitions.list, {});

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    if (response.body.openapi_definitions) {
      yield put(openAPIDefinitionsReceived(response.body.openapi_definitions));
    }
  },
);
