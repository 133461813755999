//      
import React, { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import { Text } from '@dt/material-components';
                                                                 

              
                                
  

const InventoryTableCellDiscoveredViaComponent = function InventoryTableCellDiscoveredVia({ asset_group }       ) {
  return (
    <TableCell align="center">
      {asset_group && (
        <Text component={'div'} variant={'body'} noWrap>
          {asset_group.name}
        </Text>
      )}
    </TableCell>
  );
};

export default memo       (InventoryTableCellDiscoveredViaComponent);
