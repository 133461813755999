//      
import { useRef, useEffect } from 'react';

/**
 * Shamelessly copied from https://joshwcomeau.com/snippets/react-hooks/use-interval
 */
export default function useInterval(callback            , delay        ) {
  const id = useRef(null);
  const fn = useRef(callback);
  useEffect(() => {
    fn.current = callback;
  });
  useEffect(() => {
    const tick = () => fn.current();
    if (typeof delay === 'number') {
      id.current = setInterval(tick, delay);
      return () => clearInterval(id.current);
    }
  }, [delay]);
  return id.current;
}
