//      
import { createAction } from 'redux-actions';
export const assetGroupsMembershipReceived = createAction 
                                     
                             
                           
 ('asset_groups/membership/received', asset_group_memberships => asset_group_memberships);

export const assetGroupsMembershipMounted = createAction 
                                    
           
                                                             
 ('asset_groups/membership/mounted', id => ({
  key: 'asset_groups_membership',
  assetGroupId: id,
}));

export const assetGroupMembershipCreate = createAction 
                                   
   
     
                           
                                         
         
      
    
   
                         
                                       
       
    
 ('asset_groups/membership/create', params => params);
