//     
import React from 'react';
import { SharedLinksButton } from '@dt/material-components';
import Skeleton from '@mui/material/Skeleton';
import { AssetTypeEnumValues } from '@dt/graphql-support/enums';

              
                      
            
                
                        
       
    
  

export const AssetsSharedLinksButton = ({ isLoading, asset }       ) => {
  // Hide secure share until backend is implemented
  if (
    [AssetTypeEnumValues.KUBERNETES_CLUSTER, AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT].includes(
      asset?.asset_type,
    )
  ) {
    return null;
  }

  if (isLoading || !asset) {
    return <Skeleton variant="rectangular" height={40} width={150} />;
  }

  return <SharedLinksButton restful_api_id={asset.id} />;
};
