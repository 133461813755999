//      
import React, { memo } from 'react';
import { Button } from '@mui/material';

              
                    
                      
  

const AssetTagsEditDialogAddTag = ({ disabled, onClick }       ) => {
  return (
    <Button variant="contained" disabled={disabled} onClick={onClick}>
      Add Tag
    </Button>
  );
};

export default memo       (AssetTagsEditDialogAddTag);
