import React from 'react';
import { Button, CircularProgress, DialogActions, DialogContent } from '@mui/material';
import Box from '@mui/material/Box';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
// @ts-ignore: #TODO-IMPLEMENT-THEME
import { palette } from '@dt/theme';
import PolicyViolationExportToJiraDialogHeader from './PolicyViolationExportToJiraDialogHeader';
var PolicyViolationExportToJiraDialogStep2 = function (_a) {
    var handleClose = _a.handleClose, handleNext = _a.handleNext, jiraIntegrationName = _a.jiraIntegrationName, policyViolationName = _a.policyViolationName, errorMessage = _a.errorMessage, loading = _a.loading;
    return (React.createElement(React.Fragment, null,
        React.createElement(PolicyViolationExportToJiraDialogHeader, { title: "A new Jira ticket is going to be created:", handleClose: handleClose }),
        React.createElement(DialogContent, null,
            React.createElement(Box, { display: "flex", flexDirection: "column", mt: 2, mb: 2 },
                React.createElement(Text, { variant: "body", component: "span" }, "Policy violation: ".concat(policyViolationName)),
                React.createElement(Text, { variant: "body", component: "span" }, "Jira integration: ".concat(jiraIntegrationName))),
            errorMessage && (React.createElement(Box, { bgcolor: palette.red50, padding: 1, mt: 2 },
                React.createElement(Text, { variant: "body", component: "span", mb: 1, style: {
                        color: palette.red10,
                    } }, errorMessage)))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: handleClose }, "Cancel"),
            React.createElement(Button, { onClick: handleNext, color: "primary", disabled: loading, startIcon: loading ? React.createElement(CircularProgress, { size: 18 }) : null }, "Confirm"))));
};
export default PolicyViolationExportToJiraDialogStep2;
