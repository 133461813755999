//     
import React from 'react';
import { Grid } from '@mui/material';
import { Text } from '@dt/material-components';
import Log4ShellDiagram from '@dt/material-components/svg_components/Log4ShellDiagram';
import { palette } from '@dt/theme';

const PolicyRuleTypesCardLog4ShellDiagram = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Text variant={'body'}>How Data Theorem Analyzer Scans Log4j:</Text>
      </Grid>
      <Grid item xs={12}>
        <Log4ShellDiagram style={{ border: `1px solid ${palette.gray40}` }} />
      </Grid>
    </Grid>
  );
};

export default React.memo    (PolicyRuleTypesCardLog4ShellDiagram);
