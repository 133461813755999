//      
import config from '@dt/config';
import qs from 'query-string';
import fetch, { parse } from '@dt/fetch';
import {
                                  
                         
                      
                  
                           
                              
                         
                      
                          
                           
                       
} from './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                   
                                 
                                               

                                         
                                

                                          
                                
                                         
   

export async function list(params   
      
 )                                                       {
  return fetch(`${api}/${version}/restful_apis?${qs.stringify(params)}`).then(
    parse,
  );
}

                                          
                        

                      
   

export async function details(
  id        ,
)                                           {
  return fetch(`${api}/${version}/restful_apis/${id}`).then(parse);
}

/** API Operations **/

                                        
                                     
                                                         
   

export async function api_operation_details(
  restful_api_id        ,
  api_operation_id        ,
)                                         {
  return fetch(
    `${api}/${version}/restful_apis/${restful_api_id}/api_operations/${api_operation_id}`,
  ).then(parse);
}
