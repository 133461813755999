//     

import { HostedOnEnum } from '@dt/horizon-api';
import React from 'react';
import UnknownIcon from '@mui/icons-material/HelpOutline';
import { palette } from '@dt/theme';

              
                
                  
               
  

const AssetHostedOnIcon = ({ size = 14, iconUrl, name }) => {
  if (iconUrl === HostedOnEnum.UNKNOWN) {
    return <UnknownIcon style={{ height: size, width: size, color: palette.gray }} />;
  }
  return <img height={size} width={size} src={iconUrl} alt={`hosted-on-icon-${name}`} />;
};

export default React.memo       (AssetHostedOnIcon);
