import React, { memo, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { Checkbox, ListItem } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
// @ts-ignore JS component
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
// @ts-ignore JS component
import { ErrorState } from '@dt/components';
// @ts-ignore JS component
import asset_tags from '@dt/graphql-support/horizon/asset_tags';
// @ts-ignore JS component
import { Text } from '@dt/material-components';
var useStyles = makeStyles(function () { return ({
    item: {
        alignItems: 'center',
    },
}); });
var TableFiltersAssetTagKeyValues = function (_a) {
    var _b, _c, _d;
    var assetTagKey = _a.assetTagKey, selectedAssetTags = _a.selectedAssetTags, onToggleAssetTagValue = _a.onToggleAssetTagValue, setTotalValues = _a.setTotalValues;
    var classes = useStyles();
    var shouldSelectAllValues = selectedAssetTags[assetTagKey.name] === null;
    var _e = useLazyQuery(asset_tags.values.list, {
        fetchPolicy: 'network-only',
    }), fetchAssetTagValuesQuery = _e[0], _f = _e[1], data = _f.data, error = _f.error, loading = _f.loading, called = _f.called, fetchMore = _f.fetchMore, pageNumber = _f.pageNumber;
    useEffect(function () {
        fetchAssetTagValuesQuery({
            variables: {
                tag_uuid: assetTagKey.id,
            },
        });
    }, [fetchAssetTagValuesQuery, called, assetTagKey.id]);
    useEffect(function () {
        var _a, _b, _c, _d;
        if ((_b = (_a = data === null || data === void 0 ? void 0 : data.asset_tag_value_list) === null || _a === void 0 ? void 0 : _a.pagination_information) === null || _b === void 0 ? void 0 : _b.total_count) {
            setTotalValues(Number((_d = (_c = data === null || data === void 0 ? void 0 : data.asset_tag_value_list) === null || _c === void 0 ? void 0 : _c.pagination_information) === null || _d === void 0 ? void 0 : _d.total_count));
        }
    }, [data]);
    return (React.createElement(React.Fragment, null,
        error ? (React.createElement(ErrorState, { error: error })) : !pageNumber && loading ? ([0, 1, 2, 3, 4].map(function (_, key) { return (React.createElement(ListItem, { button: true, dense: true, key: key },
            React.createElement(Skeleton, { animation: "wave", width: "100%", height: 40 }))); })) : !((_c = (_b = data === null || data === void 0 ? void 0 : data.asset_tag_value_list) === null || _b === void 0 ? void 0 : _b.asset_tags) === null || _c === void 0 ? void 0 : _c.filter(function (assetTag) { return assetTag.value; }).length) ? (React.createElement(Box, { ml: 4 },
            React.createElement(Text, { component: 'div', noWrap: true }, "There is no value for this asset tag."))) : ((_d = data === null || data === void 0 ? void 0 : data.asset_tag_value_list) === null || _d === void 0 ? void 0 : _d.asset_tags.map(function (assetTag, key) {
            var _a;
            return (assetTag === null || assetTag === void 0 ? void 0 : assetTag.value) && (React.createElement(ListItem, { button: true, dense: true, key: key, onClick: function () { return onToggleAssetTagValue(assetTag.tag, assetTag.value); }, className: classes.item },
                React.createElement(Checkbox, { checked: Boolean(selectedAssetTags[assetTag.tag] === null) ||
                        Boolean((_a = selectedAssetTags[assetTag.tag]) === null || _a === void 0 ? void 0 : _a.includes(assetTag.value)), color: shouldSelectAllValues ? 'default' : 'primary', style: { opacity: shouldSelectAllValues ? '0.4' : '1' } }),
                React.createElement(Text, { component: 'div', noWrap: true }, assetTag.value)));
        })),
        fetchMore && (React.createElement(React.Fragment, null,
            React.createElement(Box, { component: 'div' },
                React.createElement(ListItem, { button: true, dense: true },
                    React.createElement(Skeleton, { animation: "wave", width: "100%", height: 40 })),
                React.createElement(ListItem, { button: true, dense: true },
                    React.createElement(Skeleton, { animation: "wave", width: "100%", height: 40 })),
                React.createElement(ListItem, { button: true, dense: true },
                    React.createElement(Skeleton, { animation: "wave", width: "100%", height: 40 }))),
            React.createElement(Waypoint, { onEnter: function () {
                    if (fetchMore) {
                        fetchMore();
                    }
                } })))));
};
export default memo(TableFiltersAssetTagKeyValues);
