//      
import React, { memo, useState } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InventoryTableEditDrawer from './InventoryTableEditDrawer';

const InventoryTableEditComponent = function InventoryTableEdit() {
  const [isEditDrawerOpen, setEditDrawerOpen] = useState         (false);

  return (
    <>
      <Button variant="contained" size="small" startIcon={<EditIcon />} onClick={() => setEditDrawerOpen(true)}>
        Edit Assets
      </Button>
      <InventoryTableEditDrawer open={isEditDrawerOpen} onClose={() => setEditDrawerOpen(false)} />
    </>
  );
};

export default memo    (InventoryTableEditComponent);
