var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import clsx from 'clsx';
var variantToElement = {
    titleXL: 'h1',
    titleL: 'h2',
    titleM: 'h3',
    titleS: 'h4',
    titleXS: 'h5',
    body: 'p',
    bodyS: 'p',
    caption: 'span',
    label: 'span',
    labelL: 'span',
    link: 'span',
    button: 'span',
    helperText: 'span',
    chip: 'span',
    numberXL: 'span',
    code: 'code',
};
var Text = function (_a) {
    var children = _a.children, _b = _a.className, className = _b === void 0 ? '' : _b, color = _a.color, _c = _a.component, component = _c === void 0 ? 'div' : _c, label = _a.label, noWrap = _a.noWrap, onClick = _a.onClick, _d = _a.style, style = _d === void 0 ? {} : _d, _e = _a.variant, variant = _e === void 0 ? 'body' : _e;
    var Component = component || variantToElement[variant];
    return (React.createElement(Component, { "aria-label": label, className: clsx("text-".concat(variant), noWrap && 'text-truncate', className), style: __assign({ color: color }, style), onClick: onClick }, children));
};
export default Text;
