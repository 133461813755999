//      
import immer from 'immer';
import {
  policyRulesReceived,
  policyRuleDeleteFlowStep,
  policyRuleDeleteFlowStart,
  policyRuleDeleteFlowStop,
  policyRuleDeleteFlowComplete,
  policyRuleEdit,
  policyRuleViewViolationsClicked,
  policyRuleViewViolationsCloseClicked,
  policyRuleCreate,
  policyRuleCreateError,
  policyRuleCreateSuccess,
} from './actions';
                                                  


                                 
                                           
                                                                 
                              
                                
                                 
                                            
          
      
        
    
                   
                 
                            
       
    
                     
                                 
                                             
                            
                             
           
        
          
      
        
    
                       
                            
    
   

              
                                          
                                               
                                                
                                               
                                                   
                                     
                                       
                                              
                                            
                                                      
                                                            

const initialState                   = {
  id: {},

  for_policy: {},
  for_policy_and_rule_type_id: {},
  deleteRuleFlow: {
    step: 0,
    policy_rule_id: null,
  },
  policyRuleCreate: {},
  viewRuleIssuesFlow: {
    policy_rule_id: null,
  },
};

// $FlowFixMe
export default immer                  ((draft                  , action         ) => {
  switch (action.type) {
    case policyRulesReceived.toString(): {
      for (const policy_rule of action.payload) {
        const policy_rule_id = policy_rule.id;
        const policy_id = policy_rule.belongs_to_policy_id;
        const policy_rule_type_id = policy_rule.policy_rule_type_id;

        // Index 1: id
        draft.id[policy_rule_id] = policy_rule;

        // Index 2: policy_id
        draft.for_policy[policy_id] = draft.for_policy[policy_id] || [];
        if (draft.for_policy[policy_id]) draft.for_policy[policy_id].push(policy_rule.id);

        // Index 3: policy_id and policy_rule_type_id
        draft.for_policy_and_rule_type_id[policy_id] = draft.for_policy_and_rule_type_id[policy_id] || {};
        if (draft.for_policy_and_rule_type_id[policy_id]) {
          draft.for_policy_and_rule_type_id[policy_id][policy_rule_type_id] = policy_rule_id;
        }
      }
      return;
    }

    case policyRuleDeleteFlowStart.toString(): {
      draft.deleteRuleFlow = {
        step: 0,
        policy_rule_id: action.payload.policy_rule.id,
      };
      return;
    }

    case policyRuleDeleteFlowStop.toString(): {
      draft.deleteRuleFlow = {
        step: 0,
        policy_rule_id: null,
      };
      return;
    }

    case policyRuleDeleteFlowStep.toString(): {
      draft.deleteRuleFlow.step = action.payload.step;
      return;
    }

    case policyRuleDeleteFlowComplete.toString(): {
      for (const id in draft.id) {
        if (id === action.payload.policy_rule_id) {
          draft.id[id] = {
            ...draft.id[id],
            deleted_by_user_id: '_', // todo: get real user?
          };
        }
      }
      return;
    }

    case policyRuleEdit.toString(): {
      const policy_rule = draft.id[action.payload.policy_rule_id];
      if (policy_rule) {
        draft.id[policy_rule.id] = {
          ...policy_rule,
          ...action.payload.params,
        };
      }
      return;
    }

    case policyRuleViewViolationsClicked.toString(): {
      draft.viewRuleIssuesFlow.policy_rule_id = action.payload.policy_rule_id;
      return;
    }

    case policyRuleViewViolationsCloseClicked.toString(): {
      draft.viewRuleIssuesFlow.policy_rule_id = null;
      return;
    }

    case policyRuleCreate.toString(): {
      const policyId = action.payload.policy_id;
      const policyRuleTypeId = action.payload.params.policy_rule_type_id;

      draft.policyRuleCreate[policyId] = draft.policyRuleCreate[policyId] || {};

      if (draft.policyRuleCreate[policyId]) {
        draft.policyRuleCreate[policyId][policyRuleTypeId] = {
          isCreating: true,
          isSuccess: false,
          error: null,
        };
      }
      return;
    }

    case policyRuleCreateSuccess.toString(): {
      const policyId = action.payload.policy_id;
      const policyRuleTypeId = action.payload.policy_rule_type_id;

      draft.policyRuleCreate[policyId] = draft.policyRuleCreate[policyId] || {};

      if (draft.policyRuleCreate[policyId]) {
        draft.policyRuleCreate[policyId][policyRuleTypeId] = {
          isCreating: false,
          isSuccess: true,
          error: null,
        };
      }
      return;
    }
    case policyRuleCreateError.toString(): {
      const policyId = action.payload.policy_id;
      const policyRuleTypeId = action.payload.policy_rule_type_id;
      const error = action.payload.error;

      draft.policyRuleCreate[policyId] = draft.policyRuleCreate[policyId] || {};

      if (draft.policyRuleCreate[policyId]) {
        draft.policyRuleCreate[policyId][policyRuleTypeId] = {
          isCreating: false,
          isSuccess: false,
          error: error,
        };
      }
      return;
    }
  }
}, initialState);
