//     
import { createAction } from 'redux-actions';

export const apiOperationsReceived = createAction 
                            
                      
                                          
 ('api_operations/received', api_operations => ({
  api_operations,
}));
