//     
import React, { memo } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from '@reach/router';
import { Grid, Typography, Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { Label } from '@dt/material-components';
import { palette } from '@dt/theme';
import WarningIcon from '@mui/icons-material/Warning';
import FlagIcon from '@mui/icons-material/AssistantPhoto';
import InfoIcon from '@mui/icons-material/Info';
import { ErrorState } from '@dt/components';
import cloud_resources from '@dt/graphql-support/horizon/cloud_resources';
import { PolicyViolationStatusEnumValues } from '@dt/graphql-support/enums';
                                                                                               

const useStyles = makeStyles({
  lowerCaseButton: {
    textTransform: 'none',
  },
});

              
                                                                 
             
                 
  

export default memo       (function PolicyViolationList({ productBasePath, id, isV2 }       ) {
  const classes = useStyles();

  const { data, loading, error } = useQuery                                                  (
    cloud_resources.policy_violations.list,
    {
      variables: { id: id },
    },
  );

  if (error) {
    return <ErrorState error={error.message} />;
  }

  if (loading) {
    return (
      <Grid container spacing={1}>
        {[1, 2, 3, 4, 5, 6].map((_, i) => (
          <Grid item xs={12} key={i}>
            <Skeleton variant="rectangular" animation="wave" width={'60%'} height={30} />
          </Grid>
        ))}
      </Grid>
    );
  }

  const policyViolations = data?.cloud_resource_details_policy_violations_list.policy_violations || [];

  return (
    <Grid container spacing={2}>
      {policyViolations.map(
        ({ id, status, violated_policy_rule }, i) =>
          status === PolicyViolationStatusEnumValues.OPEN && (
            <Grid item xs={12} key={i}>
              <Link
                to={
                  isV2 ? `${productBasePath}/v2/policy-violations/${id}` : `${productBasePath}/policy-violations/${id}`
                }
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    {violated_policy_rule.relevance === 'URGENT' && (
                      <Label variant="red">
                        <WarningIcon fontSize="small" style={{ color: palette.red20 }} />
                      </Label>
                    )}
                    {violated_policy_rule.relevance === 'IMPORTANT' && (
                      <Label variant="yellow">
                        <FlagIcon fontSize="small" style={{ color: palette.gray20 }} />
                      </Label>
                    )}
                    {violated_policy_rule.relevance === 'PROACTIVE' && (
                      <Label variant="blue">
                        <InfoIcon fontSize="small" style={{ color: palette.blue20 }} />
                      </Label>
                    )}
                  </Grid>
                  <Button variant="text" classes={{ root: classes.lowerCaseButton }}>
                    <Typography variant="body2" color="textPrimary" display="block">
                      {violated_policy_rule.policy_rule_type.title}
                    </Typography>
                  </Button>
                </Grid>
              </Link>
            </Grid>
          ),
      )}
    </Grid>
  );
});
