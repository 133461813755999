import React, { memo } from 'react';
import { Chip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { palette } from '@dt/theme';                                            
import { useParseAssetTagFilters } from '@dt/components';
import format from 'date-fns/format';

const FilterChip = withStyles({
  root: {
    '&&': {
      background: palette.white,
      color: palette.gray20,
      border: `0.5px solid ${palette.gray20}`,
      marginRight: '4px',
    },
  },
  deleteIcon: {
    '&&': {
      color: `${palette.gray20}!important`,
      '&:hover': {
        color: `${palette.gray30}!important`,
      },
    },
  },
})(Chip);

const AssetFilterChips = ({ filters, readOnlyFilters, onResetFilter }) => {
  const assetTagsFilter = useParseAssetTagFilters(filters?.filter_by_asset_tags);

  return (
    <>
      {filters.filter_by_text && (
        <FilterChip
          size={'small'}
          label={`keyword = ${filters.filter_by_text}`}
          disabled={Boolean(readOnlyFilters.filter_by_text)}
          onDelete={() => {
            const { filter_by_text, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_external_id && (
        <FilterChip
          size={'small'}
          label={`external ID = ${filters.filter_by_external_id}`}
          disabled={Boolean(readOnlyFilters.filter_by_external_id)}
          onDelete={() => {
            const { filter_by_external_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* issue type filter chip */}
      {filters.filter_by_web_change_type && filters.filter_by_web_change_type[0] && (
        <FilterChip
          size={'small'}
          label={`Issue Source =
            "${filters.filter_by_web_change_type[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_web_change_type?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_web_change_type?.length) - 1) + ' more') ||
              ''
            }
          `}
          disabled={Boolean(readOnlyFilters.filter_by_web_change_type)}
          onDelete={() => {
            const { filter_by_web_change_type, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* asset type filter chip */}
      {filters.filter_by_asset_type && filters.filter_by_asset_type[0] && (
        <FilterChip
          size={'small'}
          label={`Asset Type =
            "${filters.filter_by_asset_type[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_asset_type?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_asset_type?.length) - 1) + ' more') ||
              ''
            }
          `}
          disabled={Boolean(readOnlyFilters.filter_by_asset_type)}
          onDelete={() => {
            const { filter_by_asset_type, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* issue type filter chip */}
      {filters.filter_by_type && filters.filter_by_type[0] && (
        <FilterChip
          size={'small'}
          label={`Issue Source =
            "${filters.filter_by_type[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_type?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_type?.length) - 1) + ' more') ||
              ''
            }
          `}
          disabled={Boolean(readOnlyFilters.filter_by_type)}
          onDelete={() => {
            const { filter_by_type, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* asset type filter chip */}
      {filters.filter_by_related_first_party_asset_type && filters.filter_by_related_first_party_asset_type[0] && (
        <FilterChip
          size={'small'}
          label={`Asset Type =
            "${filters.filter_by_related_first_party_asset_type[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_related_first_party_asset_type?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_related_first_party_asset_type?.length) - 1) + ' more') ||
              ''
            }
          `}
          disabled={Boolean(readOnlyFilters.filter_by_related_first_party_asset_type)}
          onDelete={() => {
            const { filter_by_related_first_party_asset_type, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* discovered via filter chip */}
      {filters.filter_by_discovered_via && filters.filter_by_discovered_via[0] && (
        <FilterChip
          size={'small'}
          label={`Discovery Source =
            "${filters.filter_by_discovered_via[0].split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_discovered_via?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_discovered_via?.length) - 1) + ' more') ||
              ''
            }
          `}
          disabled={Boolean(readOnlyFilters.filter_by_discovered_via)}
          onDelete={() => {
            const { filter_by_discovered_via, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* hosted on filter chip */}
      {filters.filter_by_cloud_provider && filters.filter_by_cloud_provider[0] && (
        <FilterChip
          size={'small'}
          label={`Hosted On =
            "${filters.filter_by_cloud_provider[0].split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_cloud_provider?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_cloud_provider?.length) - 1) + ' more') ||
              ''
            }
          `}
          disabled={Boolean(readOnlyFilters.filter_by_cloud_provider)}
          onDelete={() => {
            const { filter_by_cloud_provider, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* asset group filter chip */}
      {filters.filter_by_asset_group_id && (
        <FilterChip
          size={'small'}
          label={`Asset Group ID = ${filters.filter_by_asset_group_id[0] || ''}
            ${
              (Number(filters.filter_by_asset_group_id?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_asset_group_id?.length) - 1) + ' more') ||
              ''
            }`}
          disabled={Boolean(readOnlyFilters.filter_by_asset_group_id)}
          onDelete={() => {
            const { filter_by_asset_group_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_no_asset_group && (
        <FilterChip
          size={'small'}
          label={'Ungrouped Asset = on'}
          disabled={Boolean(readOnlyFilters.filter_by_no_asset_group)}
          onDelete={() => {
            const { filter_by_no_asset_group, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* Shadow Assets Filter Chip */}
      {filters.filter_by_is_shadow && (
        <FilterChip
          size={'small'}
          label={`shadow assets = on`}
          disabled={Boolean(readOnlyFilters.filter_by_is_shadow)}
          onDelete={() => {
            const { filter_by_is_shadow, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* Mobile platform */}
      {filters.filter_by_platform && (
        <FilterChip
          size={'small'}
          label={`Platform = ${filters.filter_by_platform[0] || ''}
            ${
              (Number(filters.filter_by_platform?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_platform?.length) - 1) + ' more') ||
              ''
            }`}
          disabled={Boolean(readOnlyFilters.filter_by_platform)}
          onDelete={() => {
            const { filter_by_platform, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_active_protection_status && (
        <FilterChip
          size={'small'}
          label={`Active Protection Status = ${filters.filter_by_active_protection_status[0] || ''}
            ${
              (Number(filters.filter_by_active_protection_status?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_active_protection_status?.length) - 1) + ' more') ||
              ''
            }`}
          disabled={Boolean(readOnlyFilters.filter_by_active_protection_status)}
          onDelete={() => {
            const { filter_by_active_protection_status, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_vulnerable_to_toolkit_hacks && (
        <FilterChip
          size={'small'}
          label={`Hack Status = ${filters.filter_by_vulnerable_to_toolkit_hacks[0] || ''}
            ${
              (Number(filters.filter_by_vulnerable_to_toolkit_hacks?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_vulnerable_to_toolkit_hacks?.length) - 1) + ' more') ||
              ''
            }`}
          disabled={Boolean(readOnlyFilters.filter_by_vulnerable_to_toolkit_hacks)}
          onDelete={() => {
            const { filter_by_vulnerable_to_toolkit_hacks, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* Shadow Assets Filter Chip */}
      {assetTagsFilter.list()?.map((assetTag, idx) => (
        <FilterChip
          key={idx}
          size={'small'}
          label={`asset tag = ${assetTag.key}${assetTag.value ? ':' + assetTag.value : ''}`}
          disabled={Boolean(readOnlyFilters.filter_by_asset_tags)}
          onDelete={() => {
            const { filter_by_asset_tags, ...rest } = filters;
            onResetFilter({
              ...rest,
              filter_by_asset_tags: assetTagsFilter.remove(assetTag),
            });
          }}
        />
      ))}
      {/* Mobile Phishing Filters */}
      {filters.filter_by_discovered_application_keyword && (
        <FilterChip
          size={'small'}
          label={`Keyword = ${filters.filter_by_discovered_application_keyword}`}
          disabled={Boolean(readOnlyFilters.filter_by_discovered_application_keyword)}
          onDelete={() => {
            const { filter_by_discovered_application_keyword, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_infringement_level && filters.filter_by_infringement_level[0] && (
        <FilterChip
          size={'small'}
          label={`Fraudulent behavior =
            "${filters.filter_by_infringement_level[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_infringement_level?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_infringement_level?.length) - 1) + ' more') ||
              ''
            }
          `}
          disabled={Boolean(readOnlyFilters.filter_by_infringement_level)}
          onDelete={() => {
            const { filter_by_infringement_level, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_discovered_app_status && filters.filter_by_discovered_app_status[0] && (
        <FilterChip
          size={'small'}
          label={`App status =
            "${filters.filter_by_discovered_app_status[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_discovered_app_status?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_discovered_app_status?.length) - 1) + ' more') ||
              ''
            }
          `}
          disabled={Boolean(readOnlyFilters.filter_by_discovered_app_status)}
          onDelete={() => {
            const { filter_by_discovered_app_status, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_triaging_decision && filters.filter_by_triaging_decision[0] && (
        <FilterChip
          size={'small'}
          label={`Triaging decision =
              "${filters.filter_by_triaging_decision[0]?.split('_').join(' ') || ''}"
              ${
                (Number(filters.filter_by_triaging_decision?.length) > 1 &&
                  '+ ' + (Number(filters.filter_by_triaging_decision?.length) - 1) + ' more') ||
                ''
              }
            `}
          disabled={Boolean(readOnlyFilters.filter_by_triaging_decision)}
          onDelete={() => {
            const { filter_by_triaging_decision, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* Policy Rules */}
      {filters.filter_by_severity && filters.filter_by_severity[0] && (
        <FilterChip
          size={'small'}
          label={`Severity =
                "${filters.filter_by_severity[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                ${
                  (Number(filters.filter_by_severity?.length) > 1 &&
                    '+ ' + (Number(filters.filter_by_severity?.length) - 1) + ' more') ||
                  ''
                }
              `}
          disabled={Boolean(readOnlyFilters.filter_by_severity)}
          onDelete={() => {
            const { filter_by_severity, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_has_policy_violations_with_status &&
        filters.filter_by_has_policy_violations_with_status[0] && (
          <FilterChip
            size={'small'}
            label={`Status =
                  "${
                    filters.filter_by_has_policy_violations_with_status[0] === 'RESOLVED'
                      ? 'Fixed'
                      : filters.filter_by_has_policy_violations_with_status[0] === 'WONT_FIX'
                        ? 'Closed'
                        : filters.filter_by_has_policy_violations_with_status[0]?.split('_').join(' ') || ''
                  }"
                  ${
                    (Number(filters.filter_by_has_policy_violations_with_status?.length) > 1 &&
                      '+ ' + (Number(filters.filter_by_has_policy_violations_with_status?.length) - 1) + ' more') ||
                    ''
                  }
                `}
            disabled={Boolean(readOnlyFilters.filter_by_has_policy_violations_with_status)}
            onDelete={() => {
              const { filter_by_has_policy_violations_with_status, ...rest } = filters;
              onResetFilter({ ...rest });
            }}
          />
        )}
      {/* Vendors Filter Chip */}
      {filters.filter_by_uses_third_party_vendor_id && (
        <FilterChip
          size={'small'}
          label={`Vendors = ${Number(filters.filter_by_uses_third_party_vendor_id?.length)} vendors`}
          disabled={Boolean(readOnlyFilters.filter_by_uses_third_party_vendor_id)}
          onDelete={() => {
            const { filter_by_uses_third_party_vendor_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* Web/Mobile Apps Filter Chip */}
      {filters.filter_by_related_first_party_asset_id && (
        <FilterChip
          size={'small'}
          label={`Web/Mobile Apps = ${Number(filters.filter_by_related_first_party_asset_id?.length)} apps`}
          disabled={Boolean(readOnlyFilters.filter_by_related_first_party_asset_id)}
          onDelete={() => {
            const { filter_by_related_first_party_asset_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {/* Third Party Asset Type Filter Chip */}
      {filters.filter_by_asset_type_group && (
        <FilterChip
          size={'small'}
          label={`Third Party Asset Type = ${Number(filters.filter_by_asset_type_group?.length)} Asset Types`}
          disabled={Boolean(readOnlyFilters.filter_by_asset_type_group)}
          onDelete={() => {
            const { filter_by_asset_type_group, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_subscription && filters.filter_by_subscription[0] && (
        <FilterChip
          size={'small'}
          label={`Subscription =
                      "${filters.filter_by_subscription[0]?.split('_').join(' ') || ''}"
                      ${
                        (Number(filters.filter_by_subscription?.length) > 1 &&
                          '+ ' + (Number(filters.filter_by_subscription?.length) - 1) + ' more') ||
                        ''
                      }
                    `}
          disabled={Boolean(readOnlyFilters.filter_by_subscription)}
          onDelete={() => {
            const { filter_by_subscription, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_belongs_to_products && filters.filter_by_belongs_to_products[0] && (
        <FilterChip
          size={'small'}
          label={`Products =
                    ${filters.filter_by_belongs_to_products[0]?.split('_').join(' ') || ''}${
                      (filters.filter_by_belongs_to_products?.length || 0) > 1 ? ',' : ''
                    } ${filters.filter_by_belongs_to_products?.[1]?.split('_').join(' ') || ''}
                    ${
                      (Number(filters.filter_by_belongs_to_products?.length) > 2 &&
                        '+ ' + (Number(filters.filter_by_belongs_to_products?.length) - 2) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_belongs_to_products)}
          onDelete={() => {
            const { filter_by_belongs_to_products, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_sast_issue_type && filters.filter_by_sast_issue_type[0] && (
        <FilterChip
          size={'small'}
          label={`Issue Source =
                    "${filters.filter_by_sast_issue_type[0]?.split('_').join(' ') || ''}"
                    ${
                      (Number(filters.filter_by_sast_issue_type?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_sast_issue_type?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_sast_issue_type)}
          onDelete={() => {
            const { filter_by_sast_issue_type, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_cnapp_category && filters.filter_by_cnapp_category[0] && (
        <FilterChip
          size={'small'}
          label={`CNAPP Category =
                    "${filters.filter_by_cnapp_category[0]?.split('_').join(' ') || ''}"
                    ${
                      (Number(filters.filter_by_cnapp_category?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_cnapp_category?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_cnapp_category)}
          onDelete={() => {
            const { filter_by_cnapp_category, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_current_status && filters.filter_by_current_status[0] && (
        <FilterChip
          size={'small'}
          label={`Status =
                    "${filters.filter_by_current_status[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_current_status?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_current_status?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_current_status)}
          onDelete={() => {
            const { filter_by_current_status, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_priority && filters.filter_by_priority[0] && (
        <FilterChip
          size={'small'}
          label={`Priority =
                    "${filters.filter_by_priority[0]?.split('_').join(' ') || ''}"
                    ${
                      (Number(filters.filter_by_priority?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_priority?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_priority)}
          onDelete={() => {
            const { filter_by_priority, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_compliance_policy && filters.filter_by_compliance_policy[0] && (
        <FilterChip
          size={'small'}
          label={`Compliance Policy =
                    "${filters.filter_by_compliance_policy[0]?.split('_').join(' ') || ''}"
                    ${
                      (Number(filters.filter_by_compliance_policy?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_compliance_policy?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_compliance_policy)}
          onDelete={() => {
            const { filter_by_compliance_policy, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_release_type && filters.filter_by_release_type[0] && (
        <FilterChip
          size={'small'}
          label={`Release Type =
                    "${filters.filter_by_release_type[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_release_type?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_release_type?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_release_type)}
          onDelete={() => {
            const { filter_by_release_type, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_store_blocker && filters.filter_by_store_blocker[0] && (
        <FilterChip
          size={'small'}
          label={`Store Blocker =
                    "${filters.filter_by_store_blocker[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_store_blocker?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_store_blocker?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_store_blocker)}
          onDelete={() => {
            const { filter_by_store_blocker, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_age && (
        <FilterChip
          size={'small'}
          label={`Min Age = ${filters.filter_by_age}`}
          disabled={Boolean(readOnlyFilters.filter_by_age)}
          onDelete={() => {
            const { filter_by_age, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_has_policy_violations_created_on_month && (
        <FilterChip
          size={'small'}
          label={`Policy Violations Created On = ${format(
            filters.filter_by_has_policy_violations_created_on_month,
            'MMM YYYY',
          )}`}
          disabled={Boolean(readOnlyFilters.filter_by_has_policy_violations_created_on_month)}
          onDelete={() => {
            const { filter_by_has_policy_violations_created_on_month, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_team_name && (
        <FilterChip
          size={'small'}
          label={`Team Name = ${filters.filter_by_team_name}`}
          disabled={Boolean(readOnlyFilters.filter_by_team_name)}
          onDelete={() => {
            const { filter_by_team_name, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_workspace_name && (
        <FilterChip
          size={'small'}
          label={`Workspace Name = ${filters.filter_by_workspace_name?.join(', ')}`}
          disabled={Boolean(readOnlyFilters.filter_by_workspace_name)}
          onDelete={() => {
            const { filter_by_workspace_name, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_tests_by_status && (
        <FilterChip
          size={'small'}
          label={`Test Status = ${filters.filter_tests_by_status}`}
          disabled={Boolean(readOnlyFilters.filter_tests_by_status)}
          onDelete={() => {
            const { filter_tests_by_status, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_user_id && (
        <FilterChip
          size={'small'}
          label={`User ID = ${filters.filter_by_user_id.join(', ')}`}
          disabled={Boolean(readOnlyFilters.filter_by_user_id)}
          onDelete={() => {
            const { filter_by_user_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_hacker_toolkit && filters.filter_by_hacker_toolkit[0] && (
        <FilterChip
          size={'small'}
          label={`Hacker Toolkit =
            "${filters.filter_by_hacker_toolkit[0].split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_hacker_toolkit?.length) > 1 &&
                '+ ' + (Number(filters.filter_by_hacker_toolkit?.length) - 1) + ' more') ||
              ''
            }
          `}
          disabled={Boolean(readOnlyFilters.filter_by_hacker_toolkit)}
          onDelete={() => {
            const { filter_by_hacker_toolkit, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_countries && filters.filter_by_countries[0] && (
        <FilterChip
          size={'small'}
          label={`Countries =
                    "${filters.filter_by_countries[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_countries?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_countries?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_countries)}
          onDelete={() => {
            const { filter_by_countries, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_fraud_indicators && filters.filter_by_fraud_indicators[0] && (
        <FilterChip
          size={'small'}
          label={`Fraud Indicators =
                    "${filters.filter_by_fraud_indicators[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_fraud_indicators?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_fraud_indicators?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_fraud_indicators)}
          onDelete={() => {
            const { filter_by_fraud_indicators, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_app_versions && filters.filter_by_app_versions[0] && (
        <FilterChip
          size={'small'}
          label={`App Versions =
                    "${filters.filter_by_app_versions[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_app_versions?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_app_versions?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_app_versions)}
          onDelete={() => {
            const { filter_by_app_versions, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_fraud_score && filters.filter_by_fraud_score[0] && (
        <FilterChip
          size={'small'}
          label={`Fraud Score =
                    "${filters.filter_by_fraud_score[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_fraud_score?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_fraud_score?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_fraud_score)}
          onDelete={() => {
            const { filter_by_fraud_score, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}

      {filters.filter_by_total_events && filters.filter_by_total_events[0] && (
        <FilterChip
          size={'small'}
          label={`Total Events =
                    "${filters.filter_by_total_events[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_total_events?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_total_events?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_total_events)}
          onDelete={() => {
            const { filter_by_total_events, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_fraud_activity && filters.filter_by_fraud_activity[0] && (
        <FilterChip
          size={'small'}
          label={`Fraud Activity =
                    "${filters.filter_by_fraud_activity[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_fraud_activity?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_fraud_activity?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_fraud_activity)}
          onDelete={() => {
            const { filter_by_fraud_activity, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
      {filters.filter_by_connections && filters.filter_by_connections[0] && (
        <FilterChip
          size={'small'}
          label={`Connections =
                    "${filters.filter_by_connections[0]?.split('_').join(' ').toLocaleLowerCase() || ''}"
                    ${
                      (Number(filters.filter_by_connections?.length) > 1 &&
                        '+ ' + (Number(filters.filter_by_connections?.length) - 1) + ' more') ||
                      ''
                    }
                  `}
          disabled={Boolean(readOnlyFilters.filter_by_connections)}
          onDelete={() => {
            const { filter_by_connections, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
        />
      )}
    </>
  );
};

export default memo(AssetFilterChips);
