//      

             
                                   
                                            
                                   
                                            
                              
                                       
                                   

import alerts_integrations from '@dt/graphql-support/public/integrations/alerts_integrations';
import { useMutation } from '@apollo/client';
                                                                    

               
                                                    
                                                       
  

const useAwsSecurityHub = ({ setSnackbarStatus, queryVariables }        ) => {
  const deleteMutation = useMutation                                                                             (
    alerts_integrations.delete,
    {
      update: (cache, result) => {
        if (result?.errors) {
          return;
        }
        setSnackbarStatus('success');
        const q = cache.readQuery                                                                   ({
          query: alerts_integrations.list,
          variables: queryVariables,
        });
        cache.writeQuery({
          query: alerts_integrations.list,
          variables: queryVariables,
          data: {
            alerts_integrations_list: {
              ...q?.alerts_integrations_list,
              alerts_integrations: [],
            },
          },
        });
      },
    },
  );

  const createMutation = useMutation                                                                             (
    alerts_integrations.create,
    {
      update: (cache, result) => {
        if (result?.errors) {
          return;
        }
        setSnackbarStatus('success');
        const q = cache.readQuery                                                                   ({
          query: alerts_integrations.list,
          variables: queryVariables,
        });

        const newItem = result.data.alerts_integrations_create;
        cache.writeQuery({
          query: alerts_integrations.list,
          variables: queryVariables,
          data: {
            alerts_integrations_list: {
              ...q?.alerts_integrations_list,
              alerts_integrations: [newItem],
            },
          },
        });
      },
    },
  );

  return {
    deleteMutation,
    createMutation,
  };
};

export default useAwsSecurityHub;
