var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Box, Typography } from '@mui/material';
// @ts-ignore js files
import { palette } from '@dt/theme';
var variantStyle = {
    info: { bgcolor: palette.blue50, color: palette.blue10 },
    warning: { bgcolor: palette.yellow50, color: palette.yellow10 },
    error: { bgcolor: palette.red50, color: palette.red10 },
    success: { bgcolor: palette.green50, color: palette.green10 },
    disabled: { bgcolor: palette.gray50, color: palette.gray10 },
    default: { bgcolor: palette.white, color: palette.gray10 },
};
export var Message = function (_a) {
    var _b, _c;
    var style = _a.style, m = _a.m, mb = _a.mb, mt = _a.mt, p = _a.p, c = _a.c, bg = _a.bg, variant = _a.variant, icon = _a.icon, message = _a.message, messageHeader = _a.messageHeader;
    var padding = p !== null && p !== void 0 ? p : 1;
    var margin = m !== null && m !== void 0 ? m : 2;
    var marginBottom = (_b = mb !== null && mb !== void 0 ? mb : m) !== null && _b !== void 0 ? _b : 2;
    var marginTop = (_c = mt !== null && mt !== void 0 ? mt : m) !== null && _c !== void 0 ? _c : 2;
    var color = c !== null && c !== void 0 ? c : 'inherit';
    var bgColor = bg !== null && bg !== void 0 ? bg : variantStyle[variant].bgcolor;
    return (React.createElement(Box, { bgcolor: bgColor, color: variantStyle[variant].color, p: padding, m: margin, mb: marginBottom, mt: marginTop, borderRadius: 2, whiteSpace: "pre-line" // Used to allow messages to be multiline strings.
        , sx: __assign(__assign({}, style), { display: 'flex', alignItems: 'center' }) },
        icon && (React.createElement(Box, { component: "div", width: "auto", m: 0, pr: 1 }, icon)),
        React.createElement(Box, { component: "div", width: "auto", m: 0 },
            messageHeader && (React.createElement(Typography, { variant: 'subtitle2', style: { color: color, marginBottom: '10px' } }, messageHeader)),
            React.createElement(Typography, { variant: "body1" }, message))));
};
