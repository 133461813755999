//     
import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import theme from '@dt/theme';

const useStyles = makeStyles({
  ErrorMessageBlock: {
    color: 'white',
    backgroundColor: theme().dataTheorem.palette.attention,
    padding: 10,
    borderRadius: 3,
    margin: 20,
  },
});

               
                 
   

export default memo       (function ErrorMessageBlock(props) {
  const { children } = props;
  const classes = useStyles();
  return (
    <div className={classes.ErrorMessageBlock}>
      <pre>{children}</pre>
    </div>
  );
});
