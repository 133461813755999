var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { animated, to, useTransition } from '@react-spring/web';
var fromLeaveTransition = function (_a) {
    var endAngle = _a.endAngle;
    return ({
        // enter from 360° if end angle is > 180°
        startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
        endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
        opacity: 0,
    });
};
var enterUpdateTransition = function (_a) {
    var startAngle = _a.startAngle, endAngle = _a.endAngle;
    return ({
        startAngle: startAngle,
        endAngle: endAngle,
        opacity: 1,
    });
};
var transitionDuration = 300;
function AnimatedPie(_a) {
    var arcs = _a.arcs, path = _a.path, getKey = _a.getKey, getColor = _a.getColor, onClickDatum = _a.onClickDatum, onTouchStart = _a.onTouchStart, onTouchMove = _a.onTouchMove, onMouseMove = _a.onMouseMove, onMouseLeave = _a.onMouseLeave, setActiveArc = _a.setActiveArc;
    var _b = useState(false), shouldHaveHoverTransition = _b[0], setShouldHaveHoverTransition = _b[1];
    var transitions = useTransition(arcs, {
        from: fromLeaveTransition,
        enter: enterUpdateTransition,
        update: enterUpdateTransition,
        leave: fromLeaveTransition,
        keys: getKey,
        config: {
            duration: transitionDuration,
        },
    });
    return transitions(function (props, arc, _a) {
        var key = _a.key;
        setTimeout(function () {
            setShouldHaveHoverTransition(true);
        }, transitionDuration + 200);
        return (React.createElement("g", { key: key },
            React.createElement(animated.path
            // compute interpolated path d attribute from intermediate angle values
            , { 
                // compute interpolated path d attribute from intermediate angle values
                d: to([props.startAngle, props.endAngle], function (startAngle, endAngle) {
                    return path(__assign(__assign({}, arc), { startAngle: startAngle, endAngle: endAngle }));
                }), style: {
                    transition: shouldHaveHoverTransition ? 'all 0.1s ease-in-out' : '',
                }, fill: getColor(arc), onClick: function () { return onClickDatum(arc); }, onTouchStart: function (event) {
                    setActiveArc(arc.data);
                    return onTouchStart(arc.data)(event);
                }, onTouchMove: function (event) {
                    setActiveArc(arc.data);
                    return onTouchMove(arc.data)(event);
                }, onMouseMove: function (event) {
                    setActiveArc(arc.data);
                    return onMouseMove(arc.data)(event);
                }, onMouseLeave: function (event) {
                    setActiveArc(null);
                    return onMouseLeave(arc.data)(event);
                } })));
    });
}
export default AnimatedPie;
