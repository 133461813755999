//      
import { createReducerCreator, updateInList } from 'rezz';
                                                         

import { eventsReceived } from './actions';

export default createReducerCreator                      ({
  initialState: [],
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList([eventsReceived.toString()]));
