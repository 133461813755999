//      
import { createAction } from 'redux-actions';

export const webApplicationsReceived = createAction 
                              
                       
   
                                         
       
    
 ('web_applications/received', web_applications => ({
  web_applications,
}));

export const webApplicationDetailsMounted = createAction 
                                  
           
   
                             
                                 
       
    
 ('mounted/webApplicationDetails', webApplicationId => ({
  webApplicationId,
  key: 'webApplicationDetails',
}));

export const inventorySearchWebApplicationsReceived = createAction 
                                     
                               
   
                      
                                         
       
    
 ('web_applications/search/received', (search_id, web_applications) => ({
  search_id,
  web_applications,
}));

export const reachedEndOfWebApplicationsList = createAction                                               (
  'web_applications/list/reached_end',
  () => undefined,
);

export const reachedEndOfWebApplicationsListForAssetSearchId = createAction 
                                             
           
         
 ('web_applications/list/search/reached_end', search_id => search_id);

export const webApplicationsReceivedIdsForAssetSearch = createAction 
                                         
                          
    
                     
                       
     
 ('web_applications/search/ids/received', (searchId, ids) => ({
  searchId,
  ids,
}));
