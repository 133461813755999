import React, { useContext, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore
import { isoStringToDate } from '@dt/date';
import { shadow_assets_stats } from '@dt/graphql-support/horizon/ts/analytics';
import useRefWidth from '../../../hooks/use_ref_width';
import { ShadowAssetsContext } from './ApiSecureShadowAssetsPage';
import ApiShadowAssetsPageChartDonut from './ApiShadowAssetsPageChartDonut';
var ApiShadowAssetsPageChartDonutContainer = function () {
    var divRef = useRef(null);
    var width = useRefWidth({ ref: divRef }).width;
    var _a = useContext(ShadowAssetsContext), isPolling = _a.isPolling, latestRefetchDate = _a.latestRefetchDate, filter_by_named_filter = _a.filter_by_named_filter;
    var _b = useQuery(shadow_assets_stats, {
        variables: {
            filter_by_named_filter: filter_by_named_filter || null,
        },
    }), data = _b.data, loadingQuery = _b.loading, error = _b.error, refetch = _b.refetch;
    var loading = loadingQuery || isPolling;
    useEffect(function () {
        refetch();
    }, [latestRefetchDate, refetch]);
    return (React.createElement("div", null,
        React.createElement(Text, { variant: "titleXS", component: "div", style: { marginBottom: 8 } }, "Total shadow apps/APIs have been found in a variety of places:"),
        React.createElement(Text, { variant: "helperText", component: "div", style: { marginBottom: 24 } }, "as of today import ".concat(isoStringToDate(new Date()))),
        React.createElement(ApiShadowAssetsPageChartDonut, { width: width, data: data, loading: loading, error: error })));
};
export default ApiShadowAssetsPageChartDonutContainer;
