//     
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { createAction } from 'redux-actions';

// $FlowFixMe FlowUpgrade
export const updateFindings = createAction                           (
  'SDK_ISSUES_UPDATE',
  (finding                            )                             => finding,
);

// $FlowFixMe FlowUpgrade
export default createReducerCreator({
  initialState: ([]                  ),
  equalityCheck: (a, b) => a.id === b.id,
})(updateInList(updateFindings.toString()));
