//      
import { createAction } from 'redux-actions';
import {
                  
                      
                              
                             
} from '@dt/horizon-api';

export const policyRulesReceived = createAction                                                           (
  'policy_rules/received',
  taskTypes => taskTypes,
);

export const policyRuleEdit = createAction 
                      
                                          
   
                      
                           
                                  
    
 ('policy_rules/edit', (policy_id, policy_rule_id, params) => ({
  policy_id,
  policy_rule_id,
  params,
}));

export const policyRulesConfigMounted = createAction                                                                (
  'mounted/policy_rules/config',
  () => ({ key: 'policyRules' }),
);

export const policyRuleDeleteFlowStart = createAction 
                            
               
                                   
 ('policy_rules/flow/start', policy_rule => ({
  policy_rule,
}));

export const policyRuleDeleteFlowConfirm = createAction 
                              
                   
   
                           
                        
       
    
 ('policy_rules/flow/confirm', (policy_rule_id, explanation) => ({
  policy_rule_id,
  explanation,
}));

export const policyRuleDeleteFlowStop = createAction 
                           
           
                                  
 ('policy_rules/flow/stop', policy_rule_id => ({
  policy_rule_id,
}));

export const policyRuleDeleteFlowStep = createAction 
                           
                   
   
                           
                 
       
    
 ('policy_rules/flow/step', (policy_rule_id, step) => ({
  policy_rule_id,
  step,
}));

export const policyRuleDeleteFlowComplete = createAction 
                               
           
                                  
 ('policy_rules/flow/complete', policy_rule_id => ({
  policy_rule_id,
}));

export const policyRuleViewViolationsClicked = createAction 
                                              
           
                             
 ('policy_rules/view_violations/open/clicked', policy_rule_id => ({
  policy_rule_id,
}));

export const policyRuleViewViolationsCloseClicked = createAction 
                                               
     
       
 ('policy_rules/view_violations/close/clicked', () => {});

export const policyRuleViewViolationsDrawerMounted = createAction 
                                                
           
                                                             
 ('policy_rules/view_violations/drawer/mounted', policy_rule_id => ({
  key: 'view_policy_rule_issues',
  policy_rule_id,
}));

export const policyRuleCreate = createAction 
                        
                                   
   
                      
                                   
    
 ('policy_rules/create', (policy_id, params) => ({
  policy_id,
  params,
}));

export const policyRuleCreateError = createAction 
                              
                           
   
                      
                                
                  
    
 ('policy_rules/create/error', (policy_id, policy_rule_type_id, error) => ({
  policy_id,
  policy_rule_type_id,
  error,
}));

export const policyRuleCreateSuccess = createAction 
                                
                   
   
                      
                                
    
 ('policy_rules/create/success', (policy_id, policy_rule_type_id) => ({
  policy_id,
  policy_rule_type_id,
}));
