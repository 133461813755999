import { useEffect, useState } from 'react';
var useRefWidth = function (_a) {
    var ref = _a.ref;
    var _b = useState(0), width = _b[0], setWidth = _b[1];
    useEffect(function () {
        var observerRefValue = null;
        var resizeObserver = new ResizeObserver(function (entries) {
            for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                var entry = entries_1[_i];
                if (entry.contentRect) {
                    var newWidth = entry.contentRect.width;
                    // for whatever reason, on prod this observer can 'flutter' between
                    // values. these values are always less than one pixel apart. ensuring
                    // that any new width set is > 1px different in either direction
                    // before kicking off a re-render improves performance and prevents
                    // this from affecting any DOM/D3 events
                    if (newWidth > width + 0.5 || newWidth < width - 0.5) {
                        setWidth(newWidth);
                    }
                }
            }
        });
        if (ref.current) {
            observerRefValue = ref.current;
            resizeObserver.observe(ref.current);
        }
        return function () {
            if (observerRefValue) {
                resizeObserver.unobserve(observerRefValue);
            }
        };
    }, [ref, width, setWidth]);
    return { width: width };
};
export default useRefWidth;
