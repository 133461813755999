//      
import config from '@dt/config';
import qs from 'query-string';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

                                    
                       
                               
                                        
  

export async function list(params   
      
 )                                                         {
  return fetch(`${api}/${version}/policies?${qs.stringify(params)}`).then(
    parse,
  );
}

                                        
                        
                                
                                         
                                
   

export async function details(
  policy_id        ,
)                                         {
  return fetch(`${api}/${version}/policies/${policy_id}`).then(parse);
}

                            
               
                      
   

export async function create(
  params                    ,
)                                         {
  return fetch(`${api}/${version}/policies`, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(parse);
}

export async function remove(policy_id        )                   {
  return fetch(`${api}/${version}/policies/${policy_id}`, {
    method: 'DELETE',
  }).then(r => r.ok);
}

                                      
                              
                                          
                                                       
  

                                     
                                          
                                          
  

export const policy_rules = {
  create: (
    policy_id        ,
    policy_rule                        ,
  )                            => {
    return fetch(`${api}/${version}/policies/${policy_id}/policy_rules`, {
      method: 'POST',
      body: JSON.stringify(policy_rule),
    }).then(parse);
  },
  patch: (
    policy_id        ,
    policy_rule_id        ,
    params                       ,
  ) => {
    return fetch(
      `${api}/${version}/policies/${policy_id}/policy_rules/${policy_rule_id}`,
      {
        body: JSON.stringify(params),
        method: 'PATCH',
      },
    ).then(parse);
  },
  delete: (
    policy_id        ,
    policy_rule_id        ,
    params   
                                    
         
     ,
  ) => {
    return fetch(
      `${api}/${version}/policies/${policy_id}/policy_rules/${policy_rule_id}${
        Object.keys(params).length ? '' : `?${qs.stringify(params)}`
      }`,
      {
        method: 'DELETE',
      },
    ).catch(response => response.json());
  },
};
